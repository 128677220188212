import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

/**
 * Styles
 */
import { Container } from './styles';
import Input from '../input';
import Flex from '../Flex';
import { Collapse } from 'react-collapse';
import {getCountries} from '../helpers/getCountries';


function Alertphone({ indicativoValue, phoneValue, disabled, onchangeIndicativo, onChangePhone, phonePlaceholder, style, options = [], error = {}, className }) {

  let [state,setState] = useState({
    gettingCountries:false,
    indicativos:[]
  });

  useEffect(()=>{
    setState({
      ...state,
      gettingCountries:true,
    })
     getCountries(x=> {
       setState({
         ...state,
         gettingCountries:false,
         indicativos: x.indicativos
       })
     });

  },[])


  return (
    <Container className={`AlertPhone ${className}`} style={style}>
      <Flex className={'pr-2'} flex={'1 0 100%'} jc={'flex-start'}>
        <Flex flex={'0 0 35px'} className={'px-3'}>
          {/*<img src="/img/info.png" alt="" className={"imgr"}/>*/}
          <i className={'material-icons'} style={{ color: '#29b6f6' }}>error_outline</i>
        </Flex>
        <Flex flex={'1 0 200px'} className={'longSide'}>
          <small className={'description'}>
            Para poder suscribir sus pagos enviaremos un código de confirmación a su número celular</small>
          <Flex flex={'1 0 100%'} className={'cont-phone pb-2'}>
            <Flex flex={'0 0 110px'} className={'brd-l'}>
              <Input
                tipo={'select'}
                value={indicativoValue}
                onChange={onchangeIndicativo}
                icon={'smartphone'}
                options={state.indicativos}
                labeloption={'label'}
                valueOption={'value'}
                customLabel={'value'}
                name={'indicative'}
                customColor={'#727272'}
                disabled={disabled}
                clearable={false}
              />
            </Flex>
            <Flex
              flex={'1 0 115px'}
              className={'brd-r'}
              style={{ borderLeft: '1px solid #e4e4e4' }}
            >
              <Input
                type={'number'}
                value={phoneValue}
                name={'phoneNumber'}
                onChange={onChangePhone}
                flex={'1 0 100%'}
                disabled={disabled}

              />

            </Flex>
          </Flex>
          <div className="wc">

            <Collapse isOpened={error.indicative || error.number}>
              <small className={'error'}>{error.indicative}</small>
              <small className={'error'}>{error.number}</small>
            </Collapse>
          </div>
        </Flex>
      </Flex>
    </Container>
  );
}


export const SimpleAlert = function({ children,
                                      className,
                                      style }) {
  return (
    <Container className={`AlertPhone ${className}`} style={style}>
      <Flex className={'pr-2'} flex={'1 0 100%'} jc={'flex-start'}>
        <Flex flex={'0 0 35px'} className={'px-3'}>
          {/*<img src="/img/info.png" alt="" className={"imgr"}/>*/}
          <i className={'material-icons'} style={{ color: '#29b6f6' }}>error_outline</i>
        </Flex>
        <Flex flex={'1 0 200px'} className={'longSide'}>
          {children}
        </Flex>
      </Flex>
    </Container>
  );
}

Alertphone.propTypes = {
  prop1: PropTypes.string.isRequired,
  prop2: PropTypes.number.isRequired,
  size: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

Alertphone.defaultProps = {
  prop1: 'value',
  prop2: 2000,
  size: 'big',
  options: [],
};

export default Alertphone;

