import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import is from 'is_js';
import Titlesection from '../../../TitleSection';
import Dragitems from '../../../DragItems';
import Btnt from '../../../BtnT';
import { Tooltip } from 'react-tippy';
import { FIND_TRANSACTION } from '../../../../data/mutations/transactions';


/**
 * Global state
 */
import useGlobal from '../../../../config/global';
import { STEP } from '../../constants';
import SimpleContainer from '../../../UI/simpleContainer';
import Notfound from '../../../notFound';
import Modalcontent from '../../../modalcontent/modalcontent'

function Payment({ returnAction }) {
  const [payment, setPayment] = useState([]);

  const [medios, SetMedios] = useState({
    collapse: false,
    medios: [],
  });

  const [globalState, globalActions] = useGlobal();

  let Actualiza = (x) => SetMedios(Object.assign({}, x));

  const [error, setError] = useState();
  const [cardsSubscription] = useGlobal(
    (state) => state.cardsSubscription
  );
  
  const [checkStatus, setCheckStatus] = useState(false);
  
  let cardsMemo = useMemo(() => {
    return globalState.cardsSubscription;
  }, [globalState.cardsSubscription]);

  const [transState, setTransState] = useState(false);

  const [counter, setCounter]= useState(0); // para indicar si ya preciono el boton y bloquearlo si no cumple las condiciones

  const [findTransactionStatus] = useMutation(
    FIND_TRANSACTION,
    {
      onError: (error) => {
        setError(error);
      },
      onCompleted: (response) => {
        if (response.findTransaction.transaction.status
          .filter((status)=> status.toLowerCase() === 'pendiente').length > 0
          ){
          setTransState(true)
        }
      },
    },
  )

  useEffect(() => {
    if (is.empty(cardsSubscription)) {
      setError(`El usuario no posee medios de pago`);
    } else {
      setPayment(cardsSubscription);
      findTransactionStatus({
        variables: {
          idTransaction: globalState.idTransaction
        }
      })
    }
  }, [payment]);

  useEffect(()=> {
    const status = !!cardsMemo.find(item => item.status === 'pending');
    setCheckStatus(status);
  }, [cardsMemo])

  // const checkStatus = () => !!cardsMemo.find(item => item.status === 'pending')

  const DisableActions = (set, type) => {
    const text = type ?
      'No es posible agregar medios de pago, ya que hay un metodo de pago en proceso de validación o se está procesando el pago' :
      'No es posible hacer modificaciones, ya que hay un metodo de pago en proceso de validación o se está procesando el pago'
    globalActions.setErrorAlert({
      message: [
        <span>
          {text}
        </span>,
      ],
      //deberias ser azul
      icon: 'warning',
      top: 25,
    })
    set(+1);
  }

  if (error) return (
    <SimpleContainer>
      <Titlesection text={"Medios de pago suscritos"} backtext={"Volver"} />
      <div className={"py-5"}>
        <Notfound text={error} />
      </div>
      <Btnt
        type={'b'}
        disabled={transState || checkStatus}
        text={
          <span>
            <i className={'material-icons'}>add</i> Agregar medio de
            pago
          </span>
        }
        onClick={() => {
          globalActions.setStep(STEP.methods);
          globalActions.setMainButton({ show: false, text: '' });
        }}
        btnClassname={'py-3'}
      />
    </SimpleContainer>
  );

  return (
    <div className={'px-3 px-sm-4 pb-3'}>
      <Titlesection
        text={'Medios de pago suscritos'}
        back={() => returnAction()}
        backtext={'Volver'}
      />
      
      <Dragitems
        collapse={true}
        setCollapse={() => Actualiza({ collapse: !medios.collapse })}
        hideToggle
        customTitle={
          globalState.cardsSubscription.length > 1
            ? 'El cobro de la suscripción se realizará en el siguiente orden'
            : ' '
        }
        DisableReorderCards={(transState || checkStatus) ? DisableActions: false}
        transState={transState || checkStatus}
        items={cardsMemo}
      />
      <Tooltip
        title={
          (transState || checkStatus) ? 
          'No es posible agregar medios de pago, ya que hay un metodo de pago en proceso de validación o se está procesando el pago.'
          : ''
        }
        position={'bottom'}
        trigger={'mouseenter'}
        arrow={true}
        disabled={transState || checkStatus}
      >
        <Btnt
          type={'b'}
          // disabled={counter !== 0}
          text={
            <span>
              <i className={'material-icons'}>add</i> Agregar medio de
              pago
            </span>
          }
          onClick={() => {
            if (transState || checkStatus)
              DisableActions(setCounter, true)
            else {
              globalActions.setStep(STEP.methods);
              globalActions.setMainButton({ show: false, text: '' });
            }
          }}
          btnClassname={'py-3'}
        />
      </Tooltip>
    </div>
  );
}

Payment.propTypes = {
  returnAction: PropTypes.func.isRequired,
};

Payment.defaultProps = {
  returnAction: () => console.log('return action'),
};

export default React.memo(Payment);
