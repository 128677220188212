import React from 'react';
import PropTypes from 'prop-types';
import { default as AlertBase } from 'react-bootstrap/Alert';

function Alert({ message, variant }) {
  return <AlertBase variant={variant}>{message}</AlertBase>;
}

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
};

Alert.defaultProps = {
  message: 'Default message alert',
  variant: 'primary',
};

export default React.memo(Alert);
