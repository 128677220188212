import styled from '@emotion/styled';

export const ModalS = styled.div `
  width:100%;
  font-size:15px;
  padding:10px;

  .HeaderM {
    width:100%;
    font-size:14px;
    font-weight: 600;
    color:#58595A;
    text-align:center;
    padding: 30px 0 10px 0;
  }

  .RowM {
    width:100%;
    font-size:14px;
    font-weight: 600;
    color:#58595A;
    text-align:center;
    padding: 0px 10px 10px 10px;
  }

  .label{
    color:#58595A;
    font-size:14px;
    display:block;
    width:100%;
    line-height:14px;
    text-align:left;
    padding:0 0 5px;
  }
  
  .title-parent {
    display: flex;
	}

	.title-child {
		flex: 1;
		padding: 10px;
	}
  
  .total{
    color:#58595A;
    font-size:16px;
	  font-weight: 400;
    display:block;
    width:100%;
    line-height:14px;
    text-align:right;
	  text-transform: uppercase;
    padding:10px;
  }

  .relleno{
    background-color: #e4e4e4;
    padding:.2em;
	  border:1px solid #e4e4e4;
	  border-radius: 10px;
    .value{
      color:#777777;
    }
  }
  
  .rellenoR{
    background-color: #fff;
    padding:.2em;
    .value{
      color:#777777;
    }
  }

  .value{
    font-size:14px;
    display:block;
    width:100%;
	  font-weight:400;
    line-height:14px;
    text-align:right;
    padding:0 0 5px;
  }

  .close {
    width:100%;
    font-size:14px;
    font-weight: 600;
    color:#58595A;
    text-align:right;
    padding: 20px 0 20px 0;
  }
`;