import React, { useEffect, useRef, useState } from 'react';
import TitleSection from '../../../TitleSection/TitleSection';
import { TitleSmall } from '../../../UI';
import useGlobal from '../../../../config/global';
import SimpleContainer from '../../../UI/simpleContainer';
import { useParams } from 'react-router';
import CardSetDue from '../../../CardSetDues';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/react-hooks';
import { FACTURATION } from '../../../../data/mutations/payment';
import Loading from '../../../loading';
import Finalizar from './finalizar';
import { STEP } from '../../constants';

const DefineCardsDues = (props) => {

  let FormCardsDues = useRef();
  const [state,setState] = useState({
    processing:false,
    finalizar:false
  })
  const [globalState, globalActions] = useGlobal();


  const { id } = useParams();
  let epaycoSubscription = globalState.isMultipleSubscriptions ? globalState.transaction.epaycoMultipleSubscriptions[0]: globalState.transaction.epaycoSubscription;
  const { email, nameUser, suscripcionId, accion } = epaycoSubscription;

  let form = {
    ...globalState.dataCustomerActive,
    email: email ? email : globalState.isSession.email,
    suscriptionProjectId : globalState.suscriptionProjectId,
    parameterSearch      : globalState.parameterSearch,
    nameUser             : nameUser ? nameUser : null,
    subscriptionId       : suscripcionId ? suscripcionId : null,
    action               : accion
  };


  let getCardsWithDues = (values)=>{
      let keys = Object.keys(values);
      let tarjetas = [...globalState.cardsSelectedForsuscribe]
        .map(item => {
          var card = keys.filter(key => key === item.token)[0]
          if(card){
            item.dues  = parseInt(values[item.token]);
          }
          return item;
        })
      globalActions.updateState({
        cardsSelectedForsuscribe:tarjetas
      })
      tarjetas = tarjetas.map(ele => ({token:ele.token,dues:ele.dues, isDebit: ele.isDebit}))
      return tarjetas;
  }

  var arrTokn = [...globalState.cardsSelectedForsuscribe]
  var initialVals =  [...arrTokn].reduce((item,index)=>{
    if(index.isDebit) return ({...item,[index.token] : 1})
    else return ({...item,[index.token] : ''})

  },{})

  let SignupSchema =  Yup.object().shape(
    arrTokn.reduce((item,index)=> ({
      ...item,
      [index.token] : Yup.string().required('Número de cuotas no válido.')
    }),{})
  )

  let SubmitFormCardDues = ( ) => {
    FormCardsDues.current.dispatchEvent(new Event('submit',{cancelable:true}))
  }

  const [createFacturation] = useMutation(FACTURATION, {
    onError: (error) => {
        setState({
          ...state,
          processing: false,
        })
        globalActions.setMainButton({
          onClick:()=> SubmitFormCardDues(),
          text:"Guardar",
          show:true
        });
        globalActions.setErrorAlert({
          message:["Ocurrió un error al intentar guardar las tarjetas selecccionas"],
          icon:"error",
          title:"¡Ups, hubo un error!"
        })
    },
    onCompleted: (data) => {
      let { facturation } = data;
      const { error, payment, responseInfo } = facturation;

      setState({processing: false})
      globalActions.setMainButton({
        onClick:()=> SubmitFormCardDues(),
        text:"Guardar",
        show:true
      });

      if (error) {
        globalActions.setErrorAlert({
          message:["Ocurrió un error al intentar guardar las tarjetas selecccionas"],
          icon:"error",
          title:"¡Ups, hubo un error!"
        })
      }
      else {
        setState({
          ...state,
          finalizar:true,
          processing: false,
        })
        const comercio = {
          name: responseInfo.comercio,
          nit: responseInfo.comercioNit
        }
        globalState.isMultipleSubscriptions ? globalActions.setResponseInfo({ data: payment.data, comercio }) : globalActions.setResponseInfo({ ...payment.data.data, comercio });
        globalState.isMultipleSubscriptions ? globalActions.setDataResponse({ data: payment.data, comercio }) : globalActions.setDataResponse({ ...payment.data.data, comercio });
        globalActions.setStep(STEP.responseDomiciliation);
      }

    },
  });

  useEffect(()=>{
    globalActions.setMainButton({
      onClick:()=> SubmitFormCardDues(),
      text:"Guardar",
      show:true
    })
  },[])



  return (
    <>
      {state.processing ?
        <div className={"px-3 px-lg-4 py-5"}>
          <Loading texto={"Procesando espere..."}  show={true}/>
        </div> :

        <>
          {state.finalizar ?
            <Finalizar/>:
            <Formik
              enableReinitialize={true}
              initialValues={initialVals}
              validationSchema={SignupSchema}
              onSubmit={values => {
                // same shape as initial values
                setState({...state,processing:true});
                globalActions.setMainButton({show:false});
                // form.cardsDomiciliation = getCardsWithDues(values);
                form.cardsDomiciliation = [];

                const cards = getCardsWithDues(values);
                // cards.sort((a, b) => a.isDebit - b.isDebit);
                let cardsDebit = cards.filter(card=>card.isDebit);
                let cardsCredit = cards.filter(card=>!card.isDebit);

                cardsDebit.forEach((card)=>{
                  form.isDebit = true;
                  form.action  = 'suscribir';
                  form.cardsDomiciliation = [({token : card.token, dues: 1})];
                    createFacturation({
                      variables:{
                        form,
                        idTransaction: id,
                        type: globalState.type,
                      }
                    });
                });
                if(!cardsDebit || cardsDebit.length === 0)
                cardsCredit.forEach((card)=>{
                  form.indicative= globalState.dataCustomerActive.indicative?globalState.dataCustomerActive.indicative:"";
                  form.isDebit = false;
                  form.action  = accion;
                  form.cardsDomiciliation = [({token : card.token, dues: parseInt(card.dues)})];
                  createFacturation({
                    variables:{
                      form,
                      idTransaction: id,
                      type: globalState.type,
                    }
                  });
                });

              }}
            >
              {({ values,handleChange,errors,touched,handleSubmit }) => (
                <SimpleContainer>
                  <TitleSection text={'Medios de pagos seleccionados'} backtext={'Volver'}/>
                  <TitleSmall text={'Confirme su selección'}/>

                  <form onSubmit={e=>{
                    e.preventDefault()
                    handleSubmit();
                  }} ref={FormCardsDues}>
                    { globalState.cardsSelectedForsuscribe.map((item,key) =>
                      <CardSetDue
                        isDebit={item.isDebit}
                        key={key}
                        franchise={item.franchise}
                        value={values[item.token]}
                        onChange={handleChange}
                        disabled={false}
                        name={item.token}
                        mask={item.mask}
                        error={(errors[item.token] && touched[item.token]) && errors[item.token] }
                      />
                    )}
                  </form>
                </SimpleContainer>
              )}
            </Formik>
          }
        </>
      }
    </>
  )
};

export default React.memo(DefineCardsDues);
