import axios from "axios";


const consultaBin =  async (bin,setVal) =>{
  if(!bin || bin.length < 6  ) return setVal('tdc');

  let url = `${process.env.REACT_APP_URL_PANEL_APP_REST}/binlist/json/${bin}`
    try{
       await  axios.get(url)
        .then( data => {
          if(data.status){
            let icon = data.data.brand.toLowerCase().split(' ')[0]
            setVal(icon)
          }else{
            console.log("No se obtuvo bin.")
            setVal('tdc')
          }
        })
    }catch (e) {
      console.log(e,"Error obteniendo bin.")
      setVal('tdc')
    }
}

export default consultaBin;
