import styled from '@emotion/styled';
import Flex from '../../Flex';
export const Container = styled(Flex)`
  background:white;
  width: 100%;
  height: auto;
  overflow:hidden;
  overflow-y:auto;
  z-index:0;
  transform:translateZ(0);

 @media all and (max-width:768px){
    min-height:calc(100vh - 115px);
  }

  @media all and (max-width:419px){
    min-height:calc(100vh - 230px);
  }
`;


export let directionStyles = {
  right: {
    from: {
      opacity: 0,
      transform: 'translate3d(100%,0,0)',
      width: '100%',
      position: 'relative',
    },
    leave: {
      opacity: 0,
      transform: 'translate3d(-50%,0,0)',
      width: '100%',
      position: 'absolute',
      top: '0',
      left: '0',
      zIndex: 0,
    },
  },
  left: {
    from: {
      opacity: 0,
      transform: 'translate3d(-100%,0,0)',
      width: '100%',
      position: 'relative',
    },
    leave: {
      opacity: 0,
      transform: 'translate3d(50%,0,0)',
      width: '100%',
      position: 'absolute',
      top: '0',
      left: '0',
      zIndex: 0,
    },
  },
};
