import React, { Fragment, useEffect, useState } from 'react';
import useGlobal from '../../config/global';
import Flex from '../Flex';
import { Tooltip } from 'react-tippy';
import { BtnIcon } from '../BtnT/BtnT';
import { Capitalize } from '../step/steps/response/helper';

const FrontDrag = React.memo(
  ({
    token,
    number,
    franchise,
    accountType, 
    dues,
    confirm,
    saveDues,
  }) => {
    franchise = franchise === null ? 'TDC' : franchise;

    const [globalState] = useGlobal();

    const [selet, setselect] = useState({
      showDues: false,
      dues: 1,
    });

    let actualizaSelect = (x) =>
      setselect(Object.assign({}, selet, x));

    useEffect(() => {
      if (dues) {
        actualizaSelect({ dues });
      }
    }, [dues]);

    return (
      <Flex className={'wc forma position-relative'} alg={'center'}>
        <Flex flex={'0 0 10%'}>
          <Tooltip
            title={ globalState.cardsSubscription.length === 1
               && 'Debe existir un medio de pago asociado, si desea eliminar este medio de pago, debe agregar otro.'
            }
            position={'bottom'}
            trigger={'mouseenter'}
            arrow={true}
            disabled={globalState.cardsSubscription.length > 1}
          >
            <button
              className={'wc delete text-center'}
              onClick={() => {
                confirm();
              }}
              disabled={
                globalState.lockDrag ||
                globalState.cardsSubscription.length === 1
              }
            >
              <span>
                <i className="material-icons">delete_outline</i>
              </span>
            </button>
          </Tooltip>
        </Flex>
        <Flex
          flex={'2 0 15%'}
          jc={'flex-start'}
          className={'franchise'}
        >
          <Flex flex={'0 0 30px'}>
            <img src={`/img/cards/${franchise}.png`} alt="" />
          </Flex>
          <p className={'mb-0 text-right'}>{`****${number.slice(-4)}`}</p>
        </Flex>
        <Flex flex={'0 0 80px'} className={'cuotas'}>
          {selet.showDues ? (
            <select
              value={selet.dues}
              onChange={(e) =>
                actualizaSelect({ dues: e.target.value })
              }
              id=""
            >
              <option value="0" disabled>
                Cuotas
              </option>
              <option value="1">1 cuota</option>
              <option value="2">2 cuotas</option>
              <option value="3">3 cuotas</option>
              <option value="4">4 cuotas</option>
              <option value="5">5 cuotas</option>
              <option value="6">6 cuotas</option>
              <option value="7">7 cuotas</option>
              <option value="8">8 cuotas</option>
              <option value="9">9 cuotas</option>
              <option value="10">10 cuotas</option>
              <option value="11">11 cuotas</option>
              <option value="12">12 cuotas</option>
              <option value="13">13 cuotas</option>
              <option value="14">14 cuotas</option>
              <option value="15">15 cuotas</option>
              <option value="16">16 cuotas</option>
              <option value="17">17 cuotas</option>
              <option value="18">18 cuotas</option>
              <option value="19">19 cuotas</option>
              <option value="20">20 cuotas</option>
              <option value="21">21 cuotas</option>
              <option value="22">22 cuotas</option>
              <option value="23">23 cuotas</option>
              <option value="24">24 cuotas</option>
              <option value="25">25 cuotas</option>
              <option value="26">26 cuotas</option>
              <option value="27">27 cuotas</option>
              <option value="28">28 cuotas</option>
              <option value="29">29 cuotas</option>
              <option value="30">30 cuotas</option>
              <option value="31">31 cuotas</option>
              <option value="32">32 cuotas</option>
            </select>
          ) : accountType ? (
            <span className={'duesDisabled'}> {Capitalize(accountType)}</span>
          ) :
          (
            <span className={'duesDisabled'}>{dues} cuotas</span>
          ) 
        }
        </Flex>
        <Flex
          flex={`1 0 ${selet.showDues ? '15%' : '10%'}`}
          className={' pl-2'}
          jc={'flex-end'}
        >
          {selet.showDues ? (
            <Fragment>
              <BtnIcon
                icon={'close'}
                noBg
                onClick={() =>
                  actualizaSelect({ showDues: false, dues: dues })
                }
              />
              <BtnIcon
                btnType={'a'}
                icon={'check'}
                disabled={dues === selet.dues}
                className={'ml-2'}
                onClick={() => {
                  saveDues(selet.dues, token);
                  actualizaSelect({ showDues: false });
                }}
              />
            </Fragment>
          ) : (
            <BtnIcon
              icon={'edit'}
              noBg
              onClick={() => actualizaSelect({ showDues: true })}
            />
          )}
        </Flex>
      </Flex>
    );
  }
);

export default React.memo(FrontDrag);
