/** @jsx jsx */
import { jsx, css, keyframes } from '@emotion/core';

import PropTypes from 'prop-types';

/**
 * Styles
 */
import { Container } from './styles';
import { config } from 'react-spring';
import {Spring} from 'react-spring/renderprops'


const bounce = keyframes`
  from{transform: translateX(0);}
  to{transform: translateX(900%);}
`

function Loading({show,texto}) {
  return (
    <div className={"wc"}>
      {texto &&
      <p
        style={{
          color:"#999999",
          fontWeight:"bold",
          fontSize:"17px",
          width:"90%",
          margin:"0 auto 1em",
          textAlign:"center"
        }}
      >
        {texto}
      </p>}
      <Spring
        from ={{ height:0 ,opacity:0}}
        to={{ height:show ?10 : 0,opacity:show ? 1 : 0}}
        config={config.slow}
      >
        {props =>
          <Container className="loading" style={props}>
            <div className="line"
                 css={css`animation: ${bounce} .5s ease infinite alternate;`}
            >
            </div>
          </Container>
        }
      </Spring>
    </div>
  );
}

Loading.propTypes = {
  show: PropTypes.bool.isRequired,
};

Loading.defaultProps = {
  show:false,
};

export default Loading;
