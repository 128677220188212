import styled from '@emotion/styled';
import { animated } from 'react-spring';

export const Container = styled(animated.div)`
  width: 100%;
  height: ${props => (props.active)};
  z-index: 0;
  transform-style: preserve3d;
  perspective: 600px;
  background: white;
  flex: 0 0 ${props => (props.active)};

  .cont-btn {
    height: 100%;
    transform-origin: center top;
    width: 100%;
  }

  button {
    height: 100%;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: ${props => props.colortext};
    background: ${props => props.color};
    cursor: pointer;
    position: relative;

    &:after {
      content: '';
      height: 5px;
      width: 100%;
      position: absolute;
      top: 100%;
      left: 0;
      background: ${props => props.color};
    }

    &:focus {
      outline: none;
    }
  }
`;


