import React, { Fragment, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import {
  sortableContainer,
  sortableElement,
} from 'react-sortable-hoc';
import Cardsaved from '../CardSaved';
import Flex from '../Flex';
import { Spring } from 'react-spring/renderprops';
import useGlobal from '../../config/global';
import { FormatFranchise } from '../../util/format';
import ConfirmCardDelete from './confirmCardDelete';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { SET_DEFAULT_CARD } from '../../data/mutations/credit-card';
import { REORDENAR_METODOS_DOMICILIACION } from '../../data/mutations/customer';
import { SEND_EMAIL_NOTIFICATION } from '../../data/mutations/email';
import { STEP } from '../step';
import { useSpring, useSprings, animated } from 'react-spring';
import { getRefSubscribed } from '../../util/helpers/getRefSubscribed';
import {
  ResponseOrdenDues,
  array_move,
  SortableItem,
} from './helpers';
import { Dragcont } from './styles';
import { stat } from 'fs';

const SortableContainer = sortableContainer(
  ({ children, height, Ref, setP, collapse, sumHeight }) => {
    const props = useSpring({
      from: { height: '80px' },
      to: {
        height: collapse ? sumHeight + 'px' : '80px',
      },
    });

    return (
      <animated.div style={{ width: '100%', ...props }}>
        <ul className={'mb-0 position-relative pb-3'}>{children}</ul>
      </animated.div>
    );
  }
);

const Dragitems = ({
  items = [],
  collapse,
  setCollapse,
  hideToggle,
  customTitle,
  transState,
  DisableReorderCards
}) => {
  const [globalState, globalActions] = useGlobal();

  const [state, setstate] = useState({
    orden: [],
    original: [],
    position: true,
    customerMongoId: null,
    toggleDisabled: false,
    idTransaction: globalState.idTransaction
  });
  const [orderDuesResponse, setResponse] = useState({
    response: '',
    content: '',
  });

  const setEstado = (x) => setstate(Object.assign({}, state, x));

  const [counter, setCounter]= useState(0);

  const ActualizaResponse = (x) =>
    setResponse(Object.assign({}, orderDuesResponse, x));


  let ordenOriginal = JSON.stringify(
    state.original.map((item) => item.mask)
  );

  const [reordenarMedios] = useMutation(
    REORDENAR_METODOS_DOMICILIACION,
    {
      onError: (error) => {
        console.log('error', error);
      },
      onCompleted: (response) => {
        if (response.reorderCardsDomiciliation.status) {
          setEstado({ original: [...state.orden] });
          globalActions.setIsUpdate(true);
          globalActions.setMainButton({ show: false });
          globalActions.setErrorAlert({
            message: [''],
            content: () => (
              <ResponseOrdenDues
                type={orderDuesResponse.response}
                content={orderDuesResponse.content}
              />
            ),
          });
        }
      },
    }
  );

  const [sendEmailInfo] = useMutation(SEND_EMAIL_NOTIFICATION, {
    onError: (error) => console.log('error', error),
    onCompleted: (data) =>
      console.log('Se envío el email de forma correcta', data),
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let orden = array_move(state.orden, oldIndex, newIndex);
    let newPriority = orden.map((item, index) => {
      item.priority = index + 1;
      return item;
    });
    let ordenNuevo = JSON.stringify(
      newPriority.map((item) => item.mask)
    );



    setEstado({ orden: newPriority });
    if(counter == 0 ){
      AcceptReorderCards()
    }
    if (ordenOriginal !== ordenNuevo ) {
      globalActions.setMainButton({
        show: true,
        text: 'Guardar cambios',
        onClick: () => {
          ActualizaResponse({
            response: 'orden',
          });
          reordenarMedios({
            variables: {
              customerId:
                state.customerMongoId ||
                globalState.dataSubscription.clienteInfo
                  .customer_mongo_id,
              data: newPriority,
            },
          });

          try {

            const dataRequestRefSubscribed = {
              public_key: globalState.commerce.publicKey,
              subscription_id: globalState.subscription.suscripcionInfo.id,
            };

            const refs = getRefSubscribed(dataRequestRefSubscribed);

            const dataEmail = {
              email: globalState.email,
              methods: newPriority,
              nameComerce: globalState.commerce.comercio,
              idSuscription: globalState.subscription.suscripcionInfo.id,
              clientId: globalState.commerce.clienteId,
              nombreUsuario: globalState.dataSubscription.clienteInfo.usuario,
              refSubscribed: (typeof(refs.refSubscribed) !== 'undefined') ? refs.refSubscribed : "",
              payReference: (typeof(refs.refPayment) !== 'undefined') ? refs.refPayment : ""
            };
  
            sendEmailInfo({
              variables: {
                template: '/info/cambio/orden/metodo',
                data: dataEmail,
              },
            });
          } catch (error) {
            console.log('error al enviar notificacion email');
          }
        },
      });
    } else {
      globalActions.setMainButton({
        show: false,
        text: false,
      });
    }
  };

  let eliminaTarjeta = (token) => {
    let nuevoOrden = state.orden.filter(
      (item) => item.token !== token
    );
    globalActions.setCardsSubscription(nuevoOrden);

    setEstado({
      orden: nuevoOrden,
      original: [...nuevoOrden],
    });
  };

  let confirmDeleteCard = (correo, tarjeta, franchise) => {
    globalActions.setErrorAlert({
      message: ['.'],
      content: () => ConfirmCardDelete(correo, tarjeta, franchise),
    });
  };

  let colorT = '#' + globalState.commerce.colorLogo;

  //mutation
  const [setDefaultCard] = useMutation(SET_DEFAULT_CARD, {
    onError: (error) =>
      console.log('ERROR ONECLIK TO DOMICILIATION::::', error),
    onCompleted: (data) => {},
  });

  let cardSelected = (id, dues) => {

    let cantidaddues = dues !== '0' ? dues : (dues = '12');
    setDefaultCard({
      variables: {
        email: globalState.email,
        defaultCard: {
          token: id,
          dues: cantidaddues,
        },
      },
    });

    let arr = state.orden.map((item) => {
      if (item.token === id) {
        item.guardada = true;
      } else {
        item.guardada = false;
      }
      return item;
    });

    setEstado({ orden: arr });
  };

  let EditCard = (dues, token, card, franchise) => {
    franchise = franchise === null ? 'TDC' : franchise;

    let arr = [...state.orden];

    let nuevo = arr.map((ele) => {
      if (ele.token === token) {
        ele.dues = dues;
      }
      return ele;
    });

    ActualizaResponse({
      response: 'dues',
      content: () => (
        <Flex className={'wc'} direction={'column'}>
          <p
            style={{ fontSize: '18px' }}
            className={'mb-0 text-center'}
          >
            El número de cuotas de la tarjeta
            <br />
            <span>
              <img
                src={`/img/cards/${franchise}.png`}
                alt=""
                height={'28px'}
                width={'auto'}
                className={'mr-2'}
              />
              <b>****{card.slice(-4)} </b>
            </span>
            fue actualizado con éxito.
          </p>
        </Flex>
      ),
    });
    reordenarMedios({
      variables: {
        customerId:
          state.customerMongoId ||
          globalState.dataSubscription.clienteInfo.customer_mongo_id,
        data: nuevo,
      },
    });
    setEstado({ orden: nuevo });
  };

  useEffect(() => {
    setEstado({
      orden: items,
      original: [...items],
    });
  }, []);

  useEffect(()=>{
    setEstado({
      orden: items,
      original: [...items],
    });

  },[items])

  const AcceptReorderCards = ()=>{
     globalActions.setErrorAlert({
       message: [
         <span>
          Si realiza esta acción puede que el próximo cobro no se ejecute, ya que depende de la aceptación de la entidad financiera.
         </span>,
       ],
       title: ' ¿Desea cambiar el orden de sus metodos de pago?',
       icon: 'warning',
       btns:[{text:"Si, continuar", onClick:ConfirmReorderCards}],
       hideButtons:false,
       btnCallback: cancelReorderCards,
       top: 25,
     })
   }

   const ConfirmReorderCards = ()=>{
     globalActions.setErrorAlert({
       message: [
         <span>
         Su próximo cobro puede que no se ejecute, ya que depende de la aceptación de la entidad financiera.
         </span>,
       ],
       //deberias ser azul
       icon: 'warning',
       top: 25,
     })
     setCounter(+1);
   }

   const cancelReorderCards=()=>{
   let finalOrder = [...state.original];
    setEstado({orden: finalOrder});
   }

  const checkState = ()=>{
    if(transState == true) {
      DisableReorderCards(setCounter, false)
      return true
    }else{
      let stop = state.original? state.original.some(item=> item.status === 'pending'):null
      stop && DisableReorderCards(setCounter, false)
      return stop
    }
  }

  return (
    <Dragcont className={'wc'}>
      <Flex
        className="wc pb-2"
        jc={'space-between'}
        style={{ overflow: 'hidden' }}
      >
        <small>
          {customTitle ? (
            typeof customTitle === 'string' ? (
              customTitle
            ) : (
              customTitle()
            )
          ) : (
            <Fragment>
              <i
                className={'material-icons mr-1'}
                style={{
                  height: '16px',
                  fontSize: '14px',
                  verticalAlign: 'middle',
                }}
              >
                info
              </i>
              Medios de pago guardados
            </Fragment>
          )}
        </small>
        {!hideToggle && (
          <button
            className={'toggle'}
            style={{ color: colorT }}
            onClick={setCollapse}
            disabled={state.toggleDisabled}
          >
            {collapse ? (
              <span>Ocultar medios de pago</span>
            ) : (
              <span>Seleccionar medio de pago</span>
            )}
          </button>
        )}
      </Flex>
      <SortableContainer
        onSortEnd={onSortEnd}
        lockAxis={'y'}
        lockToContainerEdges={true}
        lockOffset={'0%'}
        height={collapse}
        setP={setEstado}
        collapse={collapse}
        sumHeight={state.orden.length * '65'}
      >
        {state.orden.length > 0 &&
          state.orden.map((value, index) => (
            <SortableItem
              isDebit={value.isDebit}
              token={value.token}
              id={value._id}
              key={index}
              collapse={collapse}
              position={!collapse}
              index={index}
              number={value.mask}
              franchise={value.franchise}
              dues={value.dues}
              orden={index}
              status={value.status}
              accountType={value.accountType}
              selected={value.selected}
              lokCollapseToggle={(bool) =>
                setEstado({ ...state, toggleDisabled: bool })
              }
              // onChange={e =>
              //   EditCard(value.mask, 'dues', e.target.value)
              // }
              eliminaCard={() => {
                eliminaTarjeta(value.token);
                confirmDeleteCard(
                  globalState.email,
                  value.mask,
                  value.franchise
                );
              }}
              // setSelect={() => seleccionaCard(value.mask)}
              continuar={(dues) => cardSelected(value.token, dues)}
              // guardada={value.guardada}
              saveDues={(dues, token) => {
                EditCard(dues, token, value.mask, value.franchise);

                const data_toEmail = {
                  email: globalState.commerce.email,
                  comercio: 'si',
                  toComercio: 'si',
                  number: value.mask,
                  nameComerce: globalState.commerce.comercio,
                  fecha: moment().format('DD-MM-YYYY'),
                  cuotes: dues,
                };

                sendEmailInfo({
                  variables: {
                    template: '/info/cambio/cuotas',
                    data: data_toEmail,
                  },
                });
                const data_toEmailClient = {
                  email: globalState.email,
                  comercio: 'si',
                  toComercio: 'no',
                  number: value.mask,
                  medio: 'tarjeta de crédito',
                  franchise: value.franchise,
                  nameComerce: globalState.commerce.comercio,
                  idSuscripcion:
                    globalState.subscription.suscripcionInfo.id,
                  fecha: moment().format('DD-MM-YYYY'),
                  cuotes: dues,
                };
                sendEmailInfo({
                  variables: {
                    template: '/info/cambio/cuotas',
                    data: data_toEmailClient,
                  },
                });
              }}
              disabled={
                globalState.lockDrag ||
                globalState.step !== STEP.adminPayment ||
                globalState.cardsSubscription.length === 1
              }
            />
          ))}
      </SortableContainer>
    </Dragcont>
  );
};
export default React.memo(Dragitems);
