import { gql } from 'apollo-boost';

export const AUTH_EMAIL = gql`
  mutation AUTH_EMAIL(
    $email: EmailAddress!
    $type: String!
    $idTransaction: MongoObjectId!
    $idCustom: String
    $idPlan: String
  ) {
    authEmail(
      email: $email
      type: $type
      idTransaction: $idTransaction
      idCustom: $idCustom
      idPlan: $idPlan
    ) {
      exist
      paymentMethods {
        ranking
        methods
      }
      subscription
      cards {
        subscription {
          token
          mask
          franchise
          created
          dues
          priority
          domiciliacionId
          tipo
          status
          accountType
          debito_automatico_id
        }
        oneClick {
          _id
          metadata {
            mask
            franchise
          }
          position
        }
      }
    }
  }
`;

export const SEND_EMAIL = gql`
  mutation SEND_EMAIL(
    $email: EmailAddress!
    $idTransaction: MongoObjectId!
  ) {
    emailCodeAuth(email: $email, idTransaction: $idTransaction) {
      status
      message
    }
  }
`;

export const EMAIL_VERIFICATION = gql`
  mutation EMAIL_VERIFICATION(
    $code: String!
    $idTransaction: MongoObjectId!
  ) {
    emailVerification(code: $code, idTransaction: $idTransaction) {
      status
      message
    }
  }
`;

export const SEND_EMAIL_NOTIFICATION = gql`
  mutation SEND_EMAIL_NOTIFICATION(
    $template: String!
    $data: JSON!
  ) {
    sendEmail(template: $template, data: $data) {
      status
      message
    }
  }
`;


export const VALIDATE_UNIQUE_PROJECT_EMAIL = gql`
  mutation VALIDATE_UNIQUE_PROJECT_EMAIL(
      $email:String!
      $suscriptionProjectId:Int!
      $idTransaction:MongoObjectId!
  ){
    validateUniqueProjectEmail(
      email:$email,
      suscriptionProjectId:$suscriptionProjectId,
      idTransaction:$idTransaction,
    ){
      status,
      msg,
      customer
    }
  }
`;

export const VALIDATE_UNIQUE_PROJECT_REFERENCE = gql`
  mutation VALIDATE_UNIQUE_PROJECT_REFERENCE(
      $key:String!
      $subscriptionProjectId:Int!
      $idTransaction:MongoObjectId!
  ){
    validateUniqueProjectReference(
      key:$key,
      subscriptionProjectId:$subscriptionProjectId,
      idTransaction:$idTransaction,
    ){
      status,
      msg,
      customer
    }
  }
`;

