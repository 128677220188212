import styled from '@emotion/styled';
import Flex from '../../Flex';

export const Container = styled(Flex)`
  font-size:16px;
  font-weight:bold;
  color: rgba(79, 78, 86, 0.7);
  position:relative;
  z-index:0;
  transform:translateZ(0);
  padding:20px 0;
  
  .back{
    color:${props => props.color};
    font-weight:300;
    font-size:14px;
    cursor:pointer;
    
    &:hover{
      text-decoration:underline;
    }
  }
  
  
  @media all and (max-width:419px){
    font-size:14px;  
  }
`;
