import Flex from '../../../Flex';
import Input from '../../../input';
import React from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';


const newphone = Yup.object().shape({
  number: Yup.string()
    .min(10, 'Número incompleto.')
    .max(10, 'Número no válido.')
    .required('Debe ingresar un número de celular.'),
});

const ChangePhone = ({paises,send,Reference}) =>{

  return(
    <Formik
      initialValues={{
        indicative:{label:"colombia",value:"+57"},
        number:""
      }}
      validationSchema={newphone}
      onSubmit={ values =>{
        let updatePhone = {
          phone:values.number,
          indicative: values.indicative.value
        };
        send(updatePhone);
      }}
    >
      {({
        errors,
        touched,
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) =>
        <form
          ref={Reference}
          className={"wc"}
          onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}>
          <Flex className={"wc"}>
            <b>Cambiar número de teléfono</b>
            <br/>
            <p>Ingrese su número de celular </p>
            <Flex className="wc" alg={"stretch"}>
              <Input
                tipo={"select"}
                className={"col-5 p-0 "}
                onChange={(e) => setFieldValue("indicative",e.option)}
                value={values.indicative}
                name={"indicative"}
                options={paises}
                labelOption={'label'}
                valueOption={'value'}
                customLabel={'value'}
                icon={"stay_current_portrait"}
              />
              <Input
                className={"col-7 pr-0 pl-2"}
                onChange={handleChange}
                name={"number"}
                type={"tel"}
                value={values.number}
                error={(errors.number && touched.number) && errors.number }
              />
            </Flex>
          </Flex>
        </form>
      }
    </Formik>
  )
};

export default React.memo(ChangePhone);
