import React, { useState, useLayoutEffect, useEffect } from 'react';
import PropTypes from 'prop-types';
import is from 'is_js';
import { DataItem, MiniButton } from './style';
import Flex from '../../../Flex';
import { useMutation } from '@apollo/react-hooks';

/**
 * Helpers
 */
import { FormatDate } from '../../../../util/format';
import TitleSection from '../../../TitleSection/TitleSection';
/**
 * Global state
 */
import useGlobal from '../../../../config/global';
import { CHANGE_STATUS_SUSCRIPTION } from '../../../../data/mutations/subscriptions';
import Loading from '../../../loading';
import Notfound from '../../../notFound';
import Fade from '../../../Fade';

const td = {
  border: '1px solid #dddddd',
  'text-align': 'left',
  padding: '8px',
};

function Subscriptions({ returnAction, closeCheckout }) {
  const [subscriptions, setSubscriptions] = useState([]);
  const [cards, setCards] = useState([]);
  const [error, setError] = useState();
  const [globalState, globalActions] = useGlobal();
  const [subscriptionUpdate, setSubscriptionUpdate] = useState({
    suscripcionId: null,
    posArray: null,
    estado: null,
  });

  const actualizaSuscripcion = x =>
    setSubscriptionUpdate(Object.assign({}, subscriptionUpdate, x));

  const [changeStatusSubscription] = useMutation(
    CHANGE_STATUS_SUSCRIPTION,
    {
      onError: Err => console.log('error', Err),
      onCompleted: data => {
        if (data.statusSubscription.status) {
          //Actualizar el estado de la suscripcion
          globalActions.setIsUpdate(true);
          console.log(
            'SUSCRIPCION UPDATE:::::::',
            subscriptionUpdate
          );
          const newSubs = subscriptions.map((item, key) => {
            if (key === subscriptionUpdate.posArray) {
              item.suscripcionInfo.estado = subscriptionUpdate.estado;
            }
            return item;
          });
          setSubscriptions(newSubs);
          globalActions.setErrorAlert({
            message: [''],
            content: () => (
             <Fade show={true}>
               <Flex className={'py-4 wc'} direction={'column'}>
                 <div className="wc">
                   <img
                     src="https://multimedia-epayco.s3.amazonaws.com/dashboard/modal_icons/check.png"
                     alt=""
                     width={'70px'}
                     height={'auto'}
                     className={'mb-3'}
                   />
                 </div>
                 <p style={{ fontSize: '18px' }} className={'mb-2'}>
                   <b>
                    ¡Cambio de estado realizado <br /> con éxito!
                  </b>{' '}
                  <br />
                 </p>
                 <small>
                  Su suscripción se encuentra en estado{' '}
                  <b>{subscriptionUpdate.estado}</b>
                </small>
               </Flex>
             </Fade>
            ),
            btnCallback: () => closeCheckout()
          });
        }
      },
    }
  );

  let GlobalColor = '#' + globalState.commerce.colorLogo;

  function changeStatusSuscription(
    suscripcionId,
    status,
    posArray,
    deleteAll = ''
  ) {
    actualizaSuscripcion({
      suscripcionId,
      posArray,
      estado:
        status == 'active'
          ? 'activo'
          : status == 'inactive'
          ? 'inactivo'
          : 'eliminado',
    });

    changeStatusSubscription({
      variables: {
        hasLastPayment: deleteAll,
        idSubscription: suscripcionId.toString(),
        idTransaction: globalState.idTransaction,
        status: status,
      },
    });

    // globalActions.setErrorAlert({ message: [] });
    // const data_toEmail = {
    //   comercio: 'si',
    //   toComercio: 'si',
    //   email: 'carlos.duque@payco.co',
    //   emailCliente: 'carlos.duque@payco.co',
    //   id: '123',
    //  }
    //  sendEmailInfo( {
    //    variables : {
    //      template: '/info/eliminar/suscripcion',
    //      data : data_toEmail
    //    }
    //  })
  }
  useLayoutEffect(() => {
    const { dataSubscription, cardsSubscription } = globalState;
    if (is.empty(dataSubscription)) {
      setError(() => (
        <div className={'p-4 pb-3 text-center'}>
          <Notfound text={<span>El usuario no está  <br/>viunculado a una suscripción.</span>}/>
        </div>
      ));
    } else {
      setSubscriptions(
        is.object(dataSubscription)
          ? [dataSubscription]
          : dataSubscription
      );
      setCards(cardsSubscription);
    }
  }, [globalState.dataSubscription, globalState.cardsSubscription]);

  useEffect(() => {
    console.log(globalState.errorAlert);
  }, [globalState.errorAlert]);

  if (error) return <h3>{error}</h3>;

  return (
    <div className={'px-3 px-sm-4 pb-3'}>
      <TitleSection
        text={'Suscripciones'}
        back={() => returnAction()}
        backtext={'Volver'}
      />
      {subscriptions
        ? subscriptions.map((item, key) => {
            const {
              suscripcionInfo: {
                id,
                estado,
                fechaCreacion,
                valor_proximo_cobro,
                fecha_proximo_cobro,
              },
            } = item;
            return (
              <DataItem
                key={id}
                ocultos={{
                  'Medio de pago': cards.map((Itm, index) => {
                    return (
                      <Flex
                        key={index}
                        jc={'space-between'}
                        className={'wc py-1'}
                      >
                        <img
                          src={`/img/cards/${Itm.franchise === null ? "TDC" : Itm.franchise}.png`}
                          alt=""
                          style={{
                            maxHeight: '20px',
                            width: 'auto',
                            height: 'auto',
                            maxWidth: '40px',
                            marginRight: '8px',
                          }}
                        />
                        <small>
                          <strong>****{Itm.mask.slice(-4)}</strong>
                        </small>
                      </Flex>
                    );
                  }),
                  'Fecha creación': FormatDate(fechaCreacion, 'DD/MM/yyyy'),
                  'Valor|amount': is.null(valor_proximo_cobro, 'L')
                    ? 'No registra'
                    : valor_proximo_cobro,
                }}
                visibles={{
                  'Suscripción número': id,
                  'Estado|estado': estado,
                  'Próximo cobro': is.null(valor_proximo_cobro)
                    ? 'No registra'
                    : FormatDate(fecha_proximo_cobro, 'DD/MM/yyyy'),
                }}
                acciones={() => (
                  <Flex jc={'flex-end'}>
                    <Flex>
                      <MiniButton
                        onClick={() => {
                          globalActions.setErrorAlert({
                            message: [''],
                            btns:
                              estado == 'activo'
                                ? [
                                    {
                                      text:
                                        'Inactivar suscripción y cobro pendiente',
                                      onClick: () => {
                                        globalActions.setErrorAlert({
                                          message: [''],
                                          content: () => (
                                            <Loading
                                              show={true}
                                              texto={'Procesando...'}
                                            />
                                          ),
                                          hideButtons: true,
                                        });
                                        changeStatusSuscription(
                                          item.suscripcionInfo.id,
                                          'inactive',
                                          key,
                                          '1'
                                        );
                                      },
                                    },
                                    {
                                      text:
                                        'Inactivar solo suscripción',
                                      onClick: () => {
                                        globalActions.setErrorAlert({
                                          message: [''],
                                          content: () => (
                                            <Loading
                                              show={true}
                                              texto={'Procesando...'}
                                            />
                                          ),
                                          hideButtons: true,
                                        });
                                        changeStatusSuscription(
                                          item.suscripcionInfo.id,
                                          'inactive',
                                          key
                                        );
                                      },
                                    },
                                  ]
                                : [
                                    {
                                      text: 'Activar suscripción',
                                      onClick: () => {
                                        globalActions.setErrorAlert({
                                          message: [''],
                                          content: () => (
                                            <Loading
                                              show={true}
                                              texto={'Procesando...'}
                                            />
                                          ),
                                          hideButtons: true,
                                        });
                                        changeStatusSuscription(
                                          item.suscripcionInfo.id,
                                          'active',
                                          key
                                        );
                                      },
                                    },
                                  ],
                            content: () => (
                              <Flex direction={'column'}>
                                <Flex
                                  flex={'1 0 80%'}
                                  className={'py-3'}
                                >
                                 <div className={"wc"}>
                                   <img
                                     src="https://multimedia-epayco.s3.amazonaws.com/dashboard/modal_icons/warning.png"
                                     alt=""
                                     height={'70px'}
                                     width={'auto'}
                                     className={'mb-3'}
                                   />
                                 </div>
                                  <b>
                                    ¿Desea cambiar el estado de esta
                                    suscripción?
                                  </b>
                                  <br />
                                  <p className={'mb-0'}>
                                    {item.suscripcionInfo.estado ===
                                    'activo'
                                      ? `Es posible que hayan cobros
                                    pendientes por realizar de esta
                                    suscripción, tenga esto en cuenta
                                    al momento de hacer clic en las
                                    siguientes acciones.`
                                      : 'Se realizarán los respectivos cobros de las facturas vinculadas con esta suscripción.'}
                                  </p>
                                </Flex>
                              </Flex>
                            ),
                          });
                        }}
                        color={
                          item.suscripcionInfo.estado === 'activo'
                            ? '#f57c00'
                            : GlobalColor
                        }
                        text={ item.suscripcionInfo.estado === 'activo' ? 'Inactivar' : 'Activar'}
                        icon={ item.suscripcionInfo.estado === 'activo' ? 'not_interested' : 'check'}
                      />
                    </Flex>
                    <Flex className={'ml-2'}>
                      <MiniButton
                        onClick={() => {
                          globalActions.setErrorAlert({
                            message: [''],
                            btns: [
                              {
                                text:
                                  'Eliminar suscripción y cobro pendiente',
                                onClick: () =>
                                  changeStatusSuscription(
                                    item.suscripcionInfo.id,
                                    'deleted',
                                    key,
                                    "0"
                                  )
                              },
                              {
                                text: 'Eliminar solo suscripción',
                                onClick: () =>
                                  changeStatusSuscription(
                                    item.suscripcionInfo.id,
                                    'deleted',
                                    key,
                                    "1"
                                  ),
                              },
                            ],
                            content: () => (
                              <Flex direction={'column'}>
                                <Flex
                                  flex={'1 0 80%'}
                                  className={'py-3'}
                                >
                                  <img
                                    src="https://multimedia-epayco.s3.amazonaws.com/dashboard/modal_icons/warning.png"
                                    alt=""
                                    height={'70px'}
                                    width={'auto'}
                                    className={'mb-3'}
                                  />
                                  <b>
                                    ¿Desea eliminar esta suscripción?
                                  </b>
                                  <br />
                                  <p className={'mb-0'}>
                                    Es posible que hayan cobros
                                    pendientes por realizar de esta
                                    suscripción, tenga esto en cuenta
                                    al momento de hacer clic en las
                                    siguientes acciones.
                                  </p>
                                </Flex>
                              </Flex>
                            ),
                          });
                        }}
                        text={'Eliminar'}
                        icon={'delete'}
                      />
                    </Flex>
                  </Flex>
                )}
              />
            );
          })
        : null}
    </div>
  );
}

Subscriptions.propTypes = {
  returnAction: PropTypes.func.isRequired,
};

Subscriptions.defaultProps = {
  returnAction: () => console.log('return action'),
};

export default React.memo(Subscriptions);
