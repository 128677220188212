import styled from '@emotion/styled';

export const Container = styled.div`
  height: 10px;
  border-radius:10px;
  background:rgba(0,0,0,.1);
  box-shadow:inset 0 3px 5px rgba(0,0,0,.1);
  width:100%;
  position:relative;
  padding:2px;
  overflow:hidden;
  z-index:0;
  transform:translateZ(0);
 
  
  .line{
    height:100%;
    background:white;
    border-radius:10px;
    width:10%;
  }
 
`;
