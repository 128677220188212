import React from 'react';
import {Container} from './styles';
import Flex from '../Flex';
import { ShowError } from '../input/helpers';

const CardSetDue = ({value,onChange,error,mask="0000",franchise,name,isDebit}) =>{

  return(
    <Container borde={error ? "#ef9a9a" : "transparent" } >
      <Flex className={"content"}>
        <Flex flex={"1 0 auto"} jc={"flex-start"}>
          <img src={`/img/cards/${franchise}.png`} alt=""/>
          <strong>{'****'+ mask.slice(-4)}</strong>
        </Flex>
        <Flex flex={"1 0 auto"}>
          {
            !isDebit &&
          
          <select
            name={name}
            value={value}
            onChange={onChange}
            id=""
          >
            <option value="" disabled> Cuotas </option>
            <option value="1">1 cuota</option>
            <option value="2">2 cuotas</option>
            <option value="3">3 cuotas</option>
            <option value="4">4 cuotas</option>
            <option value="5">5 cuotas</option>
            <option value="6">6 cuotas</option>
            <option value="7">7 cuotas</option>
            <option value="8">8 cuotas</option>
            <option value="9">9 cuotas</option>
            <option value="10">10 cuotas</option>
            <option value="11">11 cuotas</option>
            <option value="12">12 cuotas</option>
            <option value="13">13 cuotas</option>
            <option value="14">14 cuotas</option>
            <option value="15">15 cuotas</option>
            <option value="16">16 cuotas</option>
            <option value="17">17 cuotas</option>
            <option value="18">18 cuotas</option>
            <option value="19">19 cuotas</option>
            <option value="20">20 cuotas</option>
            <option value="21">21 cuotas</option>
            <option value="22">22 cuotas</option>
            <option value="23">23 cuotas</option>
            <option value="24">24 cuotas</option>
            <option value="25">25 cuotas</option>
            <option value="26">26 cuotas</option>
            <option value="27">27 cuotas</option>
            <option value="28">28 cuotas</option>
            <option value="29">29 cuotas</option>
            <option value="30">30 cuotas</option>
            <option value="31">31 cuotas</option>
            <option value="32">32 cuotas</option>
          </select>
          }
        </Flex>
        <Flex flex={"0 0 40px"} className={"text-center check"}>
          {value && <i className={"material-icons"}>done</i>}
        </Flex>
      </Flex>
      <ShowError error={error}/>
    </Container>
  )
}

export default React.memo(CardSetDue);
