import styled from '@emotion/styled';
import Flex from '../../components/Flex';

export const DeletePage = styled(Flex)`
  width:100%;
  height:100vh;
  position:relative;
  overflow:hidden auto;

  header{
     background-color: #ff5700;

      .logo{

      }
  }

  .texto{
    max-width:700px;
    background:rgba(0,0,0,.05);
    border-radius:5px;
    padding:15px;
    margin:0;
    color:#999999;
    text-transform:uppercase;
    font-weight:bold;

    .icono{
      flex:0 1 20%;

      img{
        width:100%;
        max-width:80px;
        height:autio;
        display:block;
        margin:auto;
      }

    }
  }

  .footer{
    border-top: 4px solid #ff5700;
    background-color: #373737;
    color:white;
    font-size:14px;

    a{
      display:inline-block;
      color:white;
      text-decoration:none;
      cursor:pointer;

      &:hover{
      text-decoration:underline;
      }
    }
   }


   @media all and (min-width:768px){
    .texto{
      h2{
        font-size:40px;
        word-break:break-all;
      }
    }
   }
`
