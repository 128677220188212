import styled from '@emotion/styled';
import Flex from '../../Flex';
import { animated } from 'react-spring';

export const Container = styled(animated.div)`
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  flex: 0 0 140px;
  background: ${props => props.background ? props.background : props.theme.background.box};
  width: 100%;
  height: auto;
  transform: translate3d(0, 0, 0);
  will-change: transform;
  z-index: 2;

  &:before {
    content: '';
    bottom: 100%;
    left: 0;
    height: 15px;
    width: 100%;
    position: absolute;
    background: ${props =>
      props.background
        ? props.background
        : props.theme.background.box};
  }

  .logo-texto {
    transform: translateZ(0);
  }

  .texto {
    width: 100%;
    color: ${props => props.colortexto};
    font-family: 'Roboto', sans-serif;
    z-index: 0;
    transform: translateZ(0);

    .comercio {
      font-weight: bold;
      width: 100%;
      font-size: 14px;
    }
  }

  .closeC,
  .translate {
    position: relative;
    background: transparent;
    cursor: pointer;
    width: 25px;
    height: 25px;
    border-radius: 15px;
    border: none;
    text-align: center;
    color: ${props => props.colortexto};

    img {
      max-width: 80%;
      margin: auto;
      position: relative;
    }

    &:before {
      content: '';
      height: 100%;
      width: 100%;
      border-radius: 15px;
      position: absolute;
      top: 0;
      left: 0;
      transform: scale(0.5) translateZ(0);
      opacity: 0;
      background: rgba(0, 0, 0, 0.2);
      z-index: -1;
      transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
    }

    &:hover {
      &:before {
        transform: scale(1.1);
        opacity: 1;
      }
    }

    &:focus {
      outline: none;
    }
  }

  .close-sesion {
    color: ${props => props.colortexto};
    background: transparent;
    &:focus {
      outline: none;
    }
  }

  .user,
  .closeS {
    color: ${props => props.colortexto};
    background: rgba(0, 0, 0, 0.05);
    line-height: 25px;
    font-size: 13px;
  }
  .closeS {
    text-decoration: underline;

    &:hover {
      background: rgba(0, 0, 0, 0.15);
    }
  }

  .translate {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    border: 1px solid ${props => props.colortexto};
    color: ${props => props.colortexto};
    font-size: 15px;
    margin-top: 2em;
    position: relative;
    z-index: 2;
  }
`;

export const LogoComercio = styled(Flex)`
  width:80px;
  height:80px;
  border-radius:40px;
  background-color:white;
  border:8px solid white;
  flex:0 0 80px;
  margin:0 15px;
  overflow:hidden;
  transform:translateZ(0);

  img{
    max-width:100%;
    max-height:100%;
    height:auto;
    width:auto;
    displayBlock;
    z-index:0;
  }

  @media all and (max-width:419px){
      width:60px;
      height:60px;
      flex:0 0 60px;
  }
`;
