import React from 'react';
import styled from '@emotion/styled';

export const Container = styled.div`
 width: 100%;
.toggle{
  cursor:pointer;
  &:hover{
     text-decoration:underline;
   }
  &:disabled{
     color:#cbcbcb!important;
   }
}
`;

const SimpleContainer = ({children})=>{
  return (
    <Container className={"px-3 px-sm-4"}>
      {children}
    </Container>
  )
}

export default React.memo(SimpleContainer);
