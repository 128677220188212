import React from 'react';
import { animated, useTransition } from 'react-spring';

function Fade({show,children}) {

  let Fade= useTransition(show,k=>k ,{
    from  : { opacity:0, },
    enter : {opacity:1},
    leave : {opacity:0},
  });

  return Fade.map(({item,props,key}) =>
    item &&
    <animated.div key={key} style={{...props,width:"100%",position:"relative"}}>
      {children}
    </animated.div>
  )
};

export default Fade;
