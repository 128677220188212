import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { GET_COLOR } from '../../data/mutations/payment';
import {getCorrectTextColor} from '../getContrastColor';
import {Container} from './styles';
import {lighten,darken} from './controlColors';


const BgGost = (props) => {
  const [color, setColor] = useState({
    base:"",
    contrast:"",
    t01:"",
    t02:"",
    gradientA:"",
    gradientB:"",
    gradientC:"",
  });
  const [getColor] = useMutation(GET_COLOR, {
    onError: error => {},
    onCompleted: response => {
      props.setColorLogo(getCorrectTextColor(response.getColor.color));
      setColor({
        ...color,
        base:response.getColor.color,
        gradientA:lighten(response.getColor.color,30),
        gradientB:darken(response.getColor.color,40)
      });

    },
  });
  useEffect(() => {
    let idTransaction = window.location.href.split('/');
    idTransaction =
      idTransaction[window.location.href.split('/').length - 1];
    getColor({
      variables: {
        idTransaction,
      },
    });
  }, []);


  return (
    <Container
      className="formas"
      gradientA={color.gradientA}
      gradientB={color.gradientB}
      gradientC={color.gradientC}
    >
      {/*<div className="curvas"></div>*/}
      <div className="fr fr1">
        <div className="light"></div>
      </div>
      <div className="fr fr2">
        <div className="light"></div>
      </div>
      <div className="fr fr3">
        <div className="light"></div>
      </div>
      <div className="fr fr4">
        <div className="light"></div>
      </div>
      <div className="fr fr5">
        <div className="light"></div>
      </div>
    </Container>
  );
};

export default React.memo(BgGost);
