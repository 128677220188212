import React from 'react';
import Flex from '../../../Flex';
import { Check } from './iconResponse';
import { Container } from './styles';
import Mainbutton from '../../../MainButton';

/**
 * Global state
 */
import useGlobal from '../../../../config/global';
import { STEP } from '../../constants';

function Response(props) {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal();

  const { estado } = globalState.dataResponse;
  const { dues } = globalState.dataForm;
  const { payments } = globalState.dataSubscription;
  const idSubscription = payments[0]['id_subscription'];
  const { epaycoSubscription, epaycoName } = globalState.transaction;

  // Quitar mihas de response
  let quitarM = [...globalState.migas].filter(
    (item) => item === STEP.creditCard
  );
  globalActions.setMigas(quitarM);

  const closeCheckout = () => {
    if (window === window.top) {
      alert('Close only in iframe embedding');
    } else {
      window.parent.postMessage('closeActionCheckout', '*');
      // window.parent.postMessage(
      //   { event: 'onFinishResponse', data: { a: 'b' } },
      //   '*'
      // );
    }
  };

  const relative = (count = 1, period = 'month') => {
    const rtf1 = new Intl.RelativeTimeFormat('es', {
      numeric: 'auto',
    });
    return rtf1.format(count, period);
  };

  return (
    <Container
      className={'wc'}
      flex={'1 1 100%'}
      jc={'flex-start'}
      direction={'column'}
      ref={props.respRef}
    >
      <div className={'wc py-3'}>
        <Check text={`Suscripción ${estado}`} />
      </div>
      <div className="wc px-4">
        <div className="resumen">
          <div className="head">
            <small>Número de suscripción</small>
            <p>{idSubscription}</p>
          </div>

          <Flex className={'wc'} alg={'flex-start'}>
            <Flex flex={'1 0 49%'} jc={'flex-start'}>
              <div className="wc celda">
                <div className="name">Medio de pago</div>
                <div className="value">Visa ***0326</div>
              </div>
            </Flex>
            <Flex flex={'1 0 49%'} jc={'flex-start'}>
              <div className="wc celda">
                <div className="name">
                  Número de cuotas seleccionadas
                </div>
                <div className="value">{dues} cuotas</div>
              </div>
            </Flex>
          </Flex>

          <Flex className={'wc'} alg={'flex-start'}>
            <Flex flex={'1 0 49%'} jc={'flex-start'}>
              <div className="wc celda">
                <div className="name">Fecha de cobro</div>
                <div className="value">
                  {relative(
                    epaycoSubscription.interval_count,
                    epaycoSubscription.interval
                  )}
                </div>
              </div>
            </Flex>
            <Flex flex={'1 0 49%'} jc={'flex-start'}>
              <div className="wc celda">
                <div className="name">Plan</div>
                <div className="value">
                  {epaycoSubscription.id_plan}
                </div>
              </div>
            </Flex>
          </Flex>
        </div>
        <div className="description pb-5">
          Ha quedado suscrito a {epaycoName}, para débito automático
          de sus pagos, que se realizarán{' '}
          {relative(
            epaycoSubscription.interval_count,
            epaycoSubscription.interval
          )}
          .
        </div>
      </div>
      <Mainbutton
        showBtn={props.showBtn}
        text={'Finalizar'}
        onClick={() => closeCheckout()}
      />
    </Container>
  );
}

Response.propTypes = {};

Response.defaultProps = {};

export default Response;
