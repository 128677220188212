import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useMutation } from '@apollo/react-hooks';

/**
 * Mutation
 */
import { REMOVE_MP } from '../../data/mutations/payment';
import {DeletePage} from "./style";
import Flex from '../../components/Flex';

function MeansOfPayment() {
  const { action, token } = useParams();
  const [error, setError] = useState(false);
  const [confirm, setConfirm] = useState(false);

  //Mutation hook
  const [removeToken] = useMutation(REMOVE_MP, {
    onError: error =>
      setError(error.message.replace('GraphQL error:', '')),
    onCompleted: ({ confirmRemoveWithLinkOneClick }) =>
      setConfirm(confirmRemoveWithLinkOneClick.response),
  });

  useEffect(() => {
    if (action === 'remove') {
      removeToken({
        variables: {
          token,
        },
      });
    }
  }, [removeToken]);

  return (
    <DeletePage
      direction={"column"}
    >
      <Flex className={"wc"} flex={"0 0 auto"}>
        <header className={"wc p-3"}>
          <div className="container" style={{maxWidth:"800px"}}>
            <div className="logo">
              <img src="https://multimedia-epayco.s3.amazonaws.com/dashboard/login/img/logoepayco.svg" alt=""/>
            </div>
          </div>
        </header>
      </Flex>
      <Flex className={"wc"} flex={"1 0 70%"} style={{maxWidth:"800px"}}>
        <div className={"container"}>
          <div className="texto">
            <Flex className={"wc"} jc={"flex-start"}>
              <div className="icono">
                <img src="https://multimedia-epayco.s3.amazonaws.com/dashboard/modal_icons/check.png" alt=""/>
              </div>
              <Flex flex={"1 0 60%"} className={"pl-3"}>
                {error ? <h2 className={"mb-0"}>{error}</h2> :
                  (confirm && (
                    <h2 style={{maxWdith:"700px"}}>
                      Medio de pago <strong>{confirm.card.franchise}</strong> - <strong>{confirm.card.mask}</strong> fue
                      emilinado con éxito.
                    </h2>
                  ))
                }
              </Flex>
            </Flex>
          </div>
        </div>
      </Flex>
      <Flex className={"wc p-3 footer"} flex={"0 0 70px"}>
          <div className="container px-lg-0" style={{maxWidth:"800px"}}>
            <Flex className=" wc" jc={"space-between"}>
              <div className={"col-12 col-sm-4 px-0 col-md-6 mb-3 mb-sm-0"}>Copyright 2011-2019 ePayco.co</div>
              <div className={"col-12 col-sm-4 px-0 col-md-6 text-sm-right"}>
                <a className={"pl-sm-3"}  href="https://epayco.com/tratamiento-de-datos">Políticas de privacidad</a>
                <a className={"pl-sm-3"} href="https://epayco.com/terminos-condiciones-suscripciones">Términos y condiciones</a>
              </div>
            </Flex>
          </div>
      </Flex>
    </DeletePage>
  );
}

export default MeansOfPayment;