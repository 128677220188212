import React, { useState } from 'react';
import PropTypes from 'prop-types';

/**
 * Styles
 */
import { Container } from './styles';
import { useSpring } from 'react-spring';

function Checkoutcontainer({children,Myref,show,onRest}) {

  const [entra,setEntra] = useState(true);

   let props = useSpring({
     native:true,
     config: show ? {mass:1,tension:150,friction:10}: {mass:1,tension:250,friction:26},
     from: {
       opacity: 0,
        clipPath :"polygon(0% 80%, 100% 80%, 100% 80%, 0% 80%)",
       transform:"translateY(200px) scale(1)",

     },
     to: {
       opacity: show ? 1 : 0,
        clipPath :"polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%,)",
       transform: show  ? "translateY(0) scale(1)" :  "translateY(0) scale(0.9)",

     },
     onRest: (show && entra) ?
       () => {
       onRest(); setEntra(false)
     }
       :
       (!show && !entra) ? () => {onRest(); setEntra(true) }
       :
       null
   });

  return (
    <Container
      style={props}
      ref={Myref}
    >
      {children}
    </Container>
  );
}

Checkoutcontainer.propTypes = {
  children: PropTypes.array.isRequired,
};

Checkoutcontainer.defaultProps = {
  children: ["",],
};

export default React.memo(Checkoutcontainer);
