import styled from '@emotion/styled';

export const Container = styled('div')`
  width:100%;
  margin-bottom:10px;

  .content{
    background:#f1f1f2;
    border-radius:5px;
    box-shadow:0 3px 5px rgba(0,0,0,.1);
    padding:5px;
    margin-bottom:2px;
    border:2px solid ${props => props.borde};

    img{
     max-height:40px;
     max-width: 60px;
     margin-right:10px;
    }

    .check{
      color:#4caf50;
    }

    select{
      background-color:transparent;
    }
  }
`;
