import React from "react";
import {Tooltip} from 'react-tippy';
import {Container} from './styles';

const RounCheck =({activo,onClick,tooltip = '',disabled})=>{


  return (
    <Container color={activo ? "#67C940" : "#999"}>
      <Tooltip
        trigger={"mouseenter"}
        title={tooltip}
        disabled={!tooltip || disabled}
        position={'bottom'}
      >
        <button disabled={disabled} onClick={onClick} >
          <i className={"material-icons"}>{activo ? "check_circle" : "radio_button_unchecked" }</i>
        </button>
      </Tooltip>
    </Container>
  )
}

export default React.memo(RounCheck);
