import styled from '@emotion/styled';

export const Container = styled.div`
  width:100%;
  position:relative;
  z-index:0;
  overflow:hidden;
  transform:translateZ(0);
  
  .correoActivo{
    border-radius:5px;
    padding:.5em .75em;
    background:rgba(241, 241, 241, 0.9);
    line-height:30px;
    position:relative;
    z-index:0;
    
    
    font-size:15px;
    color:#999999;
    
    button{
      background:transparent;
      color:${props => props.color}!important;
      border-radius:4px;
      padding: 0 .5em;
      
      &:focus{
        outline:none;
      }
      
      &:hover{
         background:rgba(0,0,0,.03); 
      }
    }
  }
`;
