// Create styles
import React from 'react';
import {
  Document,
  Page,
  Text,
} from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import {
  Contenedor,
  Contenido,
  Separation,
  Footer,
  FooterText,
  FooterLogo,
  styles
} from "./styles";
import {
  Upsection,
  Row,
  BarraG
} from "./helper"


// Create Document Component
export const PdfHistory = React.memo(props => {

  const {
    paycoData: {
      x_transaction_id,
      x_transaction_date,
      x_ref_payco,
      x_id_factura,
      x_cardnumber,
      x_amount,
      x_currency_code
    },
    globalStatus,
    facturaId,
    invoiceData,
  } = props.data;

  const EpaycoLogo = styled.Image`
      max-width: 45pt;
      display:block;
  `;

  let empresa = {
    email    :"Sin información",
    nombre   :"Sin información",
    contacto :"Sin información",
  };

  if(invoiceData && invoiceData.empresa){
    empresa = invoiceData.empresa;
  }
  let {email,nombre,contacto} = empresa;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Contenedor>
          <Contenido>
            <Upsection
              nombre   = {nombre  }
              correo   = {email   }
              telefono = {contacto}
              monto={x_amount}
              fechaPago={x_transaction_date}
              numeroPago={x_transaction_id}
              estado={globalStatus}
              logo={props.image.bufferLogo}
              currency={x_currency_code}
            />
            {invoiceData.factura &&
            <BarraG name={'Detalle del formulario'} /> }
            {invoiceData.factura && invoiceData.factura.map((item, index) => {
              return (
                <Row
                  key={index}
                  value={item.value}
                  name={item.value}
                  type={item.format}
                />
              );
            })}
            <BarraG name={'Detalle de la transacción'} />
            <Row value={x_id_factura} name={'Referencia:'} />
            <Row value={x_ref_payco}  name={'Referencia ePayco:'} />
            <Row value={x_cardnumber} name={'Método de pago:'} />
            <Row value={facturaId}    name={'Número de recibo:'} />
          </Contenido>
          <Separation />
          <Footer>
            <FooterText>
              <Text>Powered by</Text>
            </FooterText>
            <FooterLogo>
              <EpaycoLogo
                src={{
                  data: props.image.bufferEpayco,
                  format: 'png',
                }}
              />
            </FooterLogo>
          </Footer>
        </Contenedor>
      </Page>
    </Document>
  );
});
