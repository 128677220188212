import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
//import { ThemeProvider } from 'emotion-theming';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import {
  lightThemePrimitives,
  BaseProvider,
  createTheme,
} from 'baseui';
import { IntlProvider } from 'react-intl';

/**
 * Lang config
 */
import messages from './config/translations';

/**
 * Pages
 */
import NotFound from './pages/notFound';
import OnePage from './pages/onepage';
import CreditCard from './pages/creditCard';
import MeansOfPayment from './pages/MeansOfPayment';

import Landing from './pages/landing';

/**
 * Theme
 */
import './base.scss';
import 'react-tippy/dist/tippy.css';

const Theme = createTheme(
  {
    ...lightThemePrimitives,
  },
  {
    animation: {
      timing100: '0.50s',
    },
    colorComerce: '#323232',
  }
);

function App() {

  const lang = 'es';
  return (
    <IntlProvider locale={lang} messages={messages[lang]}>
      <StyletronProvider value={new Styletron()}>
        <BaseProvider theme={Theme}>
          <BrowserRouter>
            <Switch>

              <Route
                path="/onepage/:id"
                component={OnePage}
              />
              <Route
                path="/credit-card/:action/:token"
                component={CreditCard}
              />
              <Route
                path="/means-of-payment/:action/:token"
                component={MeansOfPayment}
              />
              <Route
                path="/landing/:id"
                component={Landing}
              />


              <Route component={NotFound} />

            </Switch>
          </BrowserRouter>
        </BaseProvider>
      </StyletronProvider>
    </IntlProvider>
  );
}

export default App;
