import React from 'react';
import PropTypes from 'prop-types';
import Flex from '../Flex';

/**
 * Styles
 */
import { Container } from './styles';
import useGlobal from '../../config/global';

function Metodopagoitem({ img, name, onClick, disabled, material }) {

  const [globalState] = useGlobal();

  let GlobalColor = '#' + (globalState.commerce.colorLogo || '3a3a3a');

  return (
    <Container
      className="MetodoPagoItem"
      onClick={() => onClick()}
      disabled={disabled}
      color={GlobalColor}
    >
      <Flex alg={'stretch'}>
        <Flex flex={'0 0 25%'} className={'px-2'} direction={'column'}>
          {material ?
            <span className="material-icons">
              {material}
            </span> :
            <img src={`${img}`} alt="" className={'franchise'}/>
          }
        </Flex>
        <Flex className={'name'} flex={'1 0 150px'} jc={'flex-start'}>
          <p className={' mb-0'}>{name}</p>
        </Flex>
        <Flex flex={'0 0 30px'} direction={'column'}>
          {/*<img src="/img/angleRight.png" alt=""/>*/}
          <i className="material-icons"> chevron_right</i>
        </Flex>
      </Flex>
    </Container>
  );
}

Metodopagoitem.propTypes = {
  img: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Metodopagoitem.defaultProps = {
  img: '',
  name: '',
  onClick: () => null,
};

export default Metodopagoitem;
