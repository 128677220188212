import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  
  .toggle{
    cursor:pointer;
    
    &:hover{
      text-decoration:underline;
    }
    
    &:disabled{
      color:#cbcbcb!important;
    }
  }
`;
