import React, { useState } from 'react';
import Flex from '../../../Flex';
import styled from '@emotion/styled';
import Collapse from "react-collapse";
import useGlobal from '../../../../config/global';
import {BtnIcon} from '../../../BtnT/BtnT';
import {FormatMoney} from '../../../../util/format';


export const Container = styled.div`
  width:100%;
  height:auto;

  .itemAction{
      border:1px solid #e4e4e4;
      height:50px;
      width:100%;
      color:#999999;
      margin-bottom:-1px;
      cursor:pointer;
      transition: all 500ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
      background:transparent;

      &:focus{
        outline:none;
      }

      &:hover{
        border-left-width:6px;
        border-left-color:${props=> props.color};
        color:${props=> props.color};
      }
  }
`;

export function ItemAction(props){
  return(
    <button className={"itemAction wc"} onClick={()=> props.onClick()}>
      <Flex  jc={"flex-start"} >

        <Flex flex={"0 0 67px"}>
          <i className={`material-icons`}>{props.icon}</i>
        </Flex>
        <Flex flex={"1 0 67px"} jc={"flex-start"}>{props.text}</Flex>
      </Flex>
    </button>
  )
}


const SuscriptionItemS = styled(Flex)`
  border-radius:5px;
  background:#F3F3F3;
  padding:8px;

  small{
    line-height:1.3em;
    font-size:12px;
  }

  b{
    color:#999999;
    display:block;
  };

  .showMore{
    color:${props =>props.color};
    cursor:pointer;
    &:hover{
      text-decoration:underline;
    }
  }

  .acciones{
    border-top:2px dotted rgba(0,0,0,.2);
    padding-top:5px;
    font-size:14px;

    span{
      line-height:16px;
    }

    .material-icons{
      height:18px;
      font-size:18px;
      line-height:16px;
      display:inline-block;
      vertical-align:middle;
    }
  }


  .dato{
    margin:0 0 8px;
    padding-right:8px;

    .name,.value{
      font-size:14px;
      line-height:1.2em;

      .estado{
      font-weight:bold;
      }
    }

  }

  .ReactCollapse--collapse,
  .ReactCollapse--content{
   width:100%;
  }
`;

const MiniBtn = styled.button`
  background:transparent;
  color: ${props => props.color}!important;
  cursor:pointer;
  width:100%;

  .material-icons{
    font-size: 16px;
    height:16px;
    vertical-align:middle;
  }

  &:hover{
    text-decoration:underline;
  }

  &:disabled{
    color:#999999;
  }


`;

export const MiniButton = ({color,type,text,onClick,disabled,icon})=>{
  return(
    <MiniBtn
      onClick={onClick}
      disabled={disabled}
      type={type}
      color={color}
    >
      <i className="material-icons">{icon}</i> {text}
    </MiniBtn>
  )
};


export function DataItem(props){

  const [detail,setDeail]  = useState(false);
  const [globalState, globalActions] = useGlobal();

  let GlobalColor = "#" + (globalState.commerce.colorLogo || "3a3a3a");

  let ocultosCrudos   = Object.entries(props.ocultos);
  let visiblesCrudos  = Object.entries(props.visibles);

  let visibles = visiblesCrudos.map(item=>{
    let obj = {};
    let cadenas = item[0].split("|");
    obj.tipo  = cadenas[1] || "none";
    obj.name  = cadenas[0];
    obj.value = item[1];
    return obj;
  });

  let ocultos = ocultosCrudos.map(item=>{
    let obj = {};
    let cadenas = item[0].split("|");
    obj.tipo  = cadenas[1] ? cadenas[1].toString() : "none";
    obj.name  = cadenas[0];
    obj.value = item[1];
    return obj;
  });





  let Dato = ({name,value= null,tipo })=> {

    let Colors = {
      inactivo:"#b71c1c",
      rechazada:"#b71c1c",
      warning:"#fb8c00",
      pendiente:"#fb8c00",
      activo:"#00aa00",
      aceptada:"#00aa00",
    };

    let Value = value !== null ? value : "Sin información.";

    let TIPOS = {
      amount  : ()=>  <span>{FormatMoney(Value)}</span>,
      estado  : ()=>  <span> <b style={{color:Colors[Value.toLowerCase()],textTransform:"uppercase"}}>{Value}</b></span>,
      none    : ()=>  <span style={{wordBreak: "break-all"}}>{Value}</span>,
    };

    let Valor = TIPOS[tipo];

   // console.log(value,tipo,"::::");
    // console.log(Valor,":::: valor");

    return (
    <Flex flex={"1 0 auto"} jc={"flex-start"}  className={"dato"} style={{width:"30%"}}>
      <div className="wc name ">
        <small className={"d-block"}><b>{name}</b></small>
      </div>
      <div className="wc value" >
        {/*{tipo ?*/}
        {/*  <span className={"estado"} style={{color:Colors[value]}}>{ value || "Sin información."}</span>:*/}
        {/*  <span>{value || "Sin información."}</span>*/}
        {/*}*/}
        <Valor/>
      </div>
    </Flex>
  )};

  return(
    <SuscriptionItemS className={'wc suscriptionItem mb-3'} alg={'flex-start'} color={GlobalColor}>
      { props.visibles && visibles.map((item, index) =>
        <Dato
          key={index}
          tipo={item.tipo}
          name={item.name}
          value={item.value}
        />,
      )}
      <Collapse isOpened={detail} className={"wc"}>
        <Flex jc={'flex-start'} className={'wc'} alg={'flex-start'}>
          { props.ocultos && ocultos.map((item, index) =>
            <Dato
              key={index}
              name={item.name}
              tipo={item.tipo}
              value={item.value}
            />,
          )}
        </Flex>
      </Collapse>
      <Flex className="wc acciones">
        <Flex jc={'flex-start'} flex={'1 0 45%'} className={'showMore'}>
          <span onClick={() => setDeail(!detail)}> <i
            className={'material-icons'}>{!detail ? 'expand_more' : 'expand_less'}</i> {!detail ? 'Más detalles' : 'Ocultar detalles'}</span>
        </Flex>
        <Flex jc={'flex-end'} flex={'1 0 45%'}>
          {props.acciones ?
            (props.acciones()):
            <button onClick={() => props.eliminar()}><i
              className="material-icons">delete_outline</i> Eliminar
            </button>
          }
        </Flex>
        {props.editar ?
          <Flex jc={'flex-end'} flex={'1 0 45%'}>
              <button onClick={() => props.editar()}><i className="material-icons">not_interested</i>{props.visibles.Estado == 'activo'?'inactivar':'activar'}
              </button>
          </Flex>
          : ''
        }

      </Flex>
    </SuscriptionItemS>
  )
}
