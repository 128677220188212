import React from 'react';
import { TablaS } from './styles';
import Flex from '../../../Flex';

function TablaResponse({ header = {}, content = [], footer = {} }) {
  return (
    <TablaS>
      <div className="relleno wc">
        <span className={'label'}>{header.name}</span>
        <span className={"value"}>{header.value || "--------" }</span>
      </div>
      <Flex className={'wc'} alg={"flex-start"}>
        {content.map((item, index) => (
          <Flex
            jc={'flex-start'}
            key={index}
            flex={`1 0 ${item.ancho ? item.ancho : "49%"}`}
            className={'p-2 keyValue'}
          >
            <span className={'label'}>{item.name}</span>
            <span className={"value col-12 px-0"}>{item.value || "--------"}</span>
          </Flex>
        ))}
      </Flex>
      {footer.name && (
        <div className="relleno">
          <span className="label">{footer.name}</span>
          <span>${footer.value}</span>
        </div>
      )}
    </TablaS>
  );
}

export default React.memo(TablaResponse);
