import React from 'react';
import Flex from '../../components/Flex';
import styled from '@emotion/styled';
import Lockprocesing from '../../components/lockProcesing';
import { animated } from 'react-spring';
import Fade from '../../components/Fade';
import Btnt from '../../components/BtnT';
import {FormatMoney} from '../../util/format';
import { useMutation } from '@apollo/react-hooks';
import { GET_COLOR } from '../../data/mutations/payment';
import { darken, lighten } from '../../components/bgGost/controlColors';

const Mycard = styled(Flex)`
  background: white;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);

  .dataItem {
    width: 100%;
    border-bottom: 2px dotted #cbcbcb;

    &:last-of-type {
      border: none;
    }
  }
`;

const Content = styled.div`
  max-height:calc(100vh - 200px);
  width:100%;
  padding: 20px 10px;
  overflow:hidden auto;
`;

const DataIten = ({ label, value,franchise }) => {
  return (
    <Flex className={'dataItem px-2'}>
      <Flex
        jc={'flex-start'}
        flex={'1 0 80px'}
        className={'text-left py-2'}
      >
        <small>
          <b>{label}</b>
        </small>
      </Flex>
      <Flex alg={'flex-start'} flex={'0 0 auto'}>
        <span>
          {franchise &&
          <img
            src={`/img/cards/${franchise}.png`}
            alt=""
            height={"25px"}
            width={"auto"}
            className={"mr-2"}
          />
          }
          {value}
        </span>
      </Flex>
    </Flex>
  );
};

const LogoS = styled(Flex)`
    padding: 15px 10px;
    width:100%;
    background-color:rgba(0,0,0,.05);
    margin-bottom:20px;
   
    .logo{
       width:70px;
       height:70px;
       border-radius:50%;
       border:4px solid white;
       background:${props => props.fondoLogo};
       overflow:hidden;
       margin-right:10px;
       
       img{
        max-width:100%;
        max-height:100%;
        height:auto;
        width:auto;
        display:block;
       }
    }
    .text{
      color:#3a3a3a;
    }
    .name{
      font-size:18px;
      font-weight:bold;
      text-transform:uppercase;
    }
`;

const Logo = ({img,comercio,monto,suscripcion,fondo})=>{
  return(
    <LogoS jc={"flex-start"} fondoLogo={fondo}>
      <Flex direction={"column"} className="logo mx-3">
        <img src={img} alt=""/>
      </Flex>
      <Flex className="text" flex={"1 0 70%"} jc={"flex-start"}>
        <div className="name">
          {comercio}
        </div>
        <div className="wc">
          <b>Suscripción:</b> {suscripcion} <br/>
          <b>Monto:</b>  {FormatMoney(monto)}
        </div>
      </Flex>
    </LogoS>
  )
};

function Response({
    fondoLogo      = "white",
    logoComercio   = "Sin información.",
    comercio       = "Sin información.",
    suscripcion    = "Sin información.",
    monto          = "Sin información.",

    tarjeta        = "Sin información.",
    franchise      = "Sin información.",
    cuotas         = "Sin información.",

    nombreUsuario  = "Sin información.",
    correo         = "Sin información.",
    contacto       = "Sin información."
}) {



  const cerrar = ()=> {
    window.open('','_parent','');
    window.close();
  };



  return (

      <Flex
        style={{
          zIndex: '800',
          height: '100%',
        }}
        direction={'column '}
      >
        <Fade show={true}>
          <Flex className={"wc px-2"} direction={"column"}>
            <Mycard className=" col-md-8 col-lg-6 wc mb-3 pb-4 px-0">
              <Logo
                fondo={fondoLogo}
                img={logoComercio}
                comercio={comercio}
                suscripcion={suscripcion}
                monto={monto}
              />
              <Content>
                <div className="wc mb-3 text-center">
                  <b>Método agregado</b>
                  <DataIten
                    label="Tarjeta de crédito"
                    value={tarjeta}
                    franchise={franchise}
                  />
                  <DataIten
                    label="Número de cuotas"
                    value={cuotas}
                  />
                </div>
                <div className="wc text-center">
                  <b>Datos del usuario</b>
                  <DataIten
                    label="Nombre"
                    value={nombreUsuario}
                  />
                  <DataIten
                    label="Correo"
                    value={correo}
                  />
                  <DataIten
                    label="Número de contacto"
                    value={contacto}
                  />
                </div>
              </Content>
            </Mycard>
            <Flex flex={'0 0 40px'}>
              <span style={{
                color: 'white',
                fontSize: '17px',
              }}>Powered by</span>
              <img
                src="/img/epayco_b.svg"
                alt=""
                height={'22px'}
                width={'auto'}
                className={'ml-2 mt-1'}
              />
            </Flex>
          </Flex>
        </Fade>
      </Flex>

  );
}

export default Response;
