import React from 'react';
import PropTypes from 'prop-types';

/**
 * Styles
 */
import { Container,IconBtn,Spanlink } from './styles';
import useGlobal from '../../config/global';
import {getCorrectTextColor} from '../getContrastColor';

function Btnt({text,onClick,br,disabled, type = "a",btnClassname,typeButton}) {

  const [globalState, ] = useGlobal();

  let colorT = globalState.commerce.colorLink !== undefined ? "#" + globalState.commerce.colorLink : "#323232";

  return (
    <Container
      className="BtnT"
      br={br}
      type={type}
      color={colorT}
      colorText={getCorrectTextColor(globalState.commerce.colorLink !== undefined ? "#" +globalState.commerce.colorLink : "#323232")}
    >
      <button
        onClick={()=> onClick()}
        disabled={disabled}
        className={btnClassname}
        type={typeButton}
      >
        {text}

      </button>
    </Container>
  );
}

Btnt.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  onClick: PropTypes.func,
};

Btnt.defaultProps = {
  text:"",
  onClick:()=> null
};

export default Btnt;


export const BtnIcon = ({icon,onClick= () => null ,disabled,className,noBg,btnType,type="button",noBorder})=>{

  const [globalState, ] = useGlobal();
  let GlobalColor = "#" + (globalState.commerce.colorLogo || "3a3a3a");
  // let GlobalColor = "#f13ab4"


  return(
    <IconBtn
      className={className}
      color={GlobalColor}
      noBg={noBg}
      type={btnType}
      colorText={getCorrectTextColor(GlobalColor)}
      borderColor={ noBorder ? "transparent" : GlobalColor}
    >
      <button
        type={type}
        onClick={()=>onClick()}
        disabled={disabled}
      >
        <i className="material-icons">{icon}</i>
      </button>
    </IconBtn>
  )
};

export const SpanLink = ({icon,onClick,disabled,className,text})=>{

  const [globalState, ] = useGlobal();
  let GlobalColor = "#" + (globalState.commerce.colorLogo || "3a3a3a");

  return(
    <Spanlink
      className={className}
      color={GlobalColor}
      disabled={disabled}
      onClick={onClick}
    >
      {icon && <i className="material-icons mr-1">{icon}</i>} {text}
    </Spanlink>
  )
};
