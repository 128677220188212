import styled from '@emotion/styled';

export const Container = styled.div`
  width:100%;
  position:${props=> props.position ? "absolute": "relative"};
  z-index:0;
  margin-bottom:${props=> !props.opened ? "0":"12px"};
  transform-style: preserve-3d;
  perspective:600px;
  overflow:hidden;
  transition: all 500ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
  z-index:${props=> props.index === 0 ? 3 : props.index === 1 ? 2 : 0}; 
  transform:
  ${props=> !props.opened ? 
  (`translate3d(0, ${props.index < 3 ?  props.index * 15 : 2 * 15}%,0) scale(${props.index > 1 ? "0.9" : props.index === 1 ? "0.95" : '1' })`) 
  :`translate3d(0,${ props.position ? props.index  * 59  : 0}%,0) scale(1)`} ;
  box-shadow :${props => props.shadow};
  border-radius:5px;
  cursor:${props=> props.desabilitado  ? "not-allowed" : (props.onclick ? "initial" : "grab") };
  top:0;
  
  &:last-typeof{
    margin:0;
    overflow:hidden;
  }
    
  .delete{
    background:transparent;
    color:#777799;
    padding-top:7px;
    position:relative;
    transform-style: preserve-3d;
    transform:translateZ(0);
    
   & > * {
      pointer-events:none;
   }
    
    &:focus{
      outline:none;
    }
  }
  
  .forma{
    position:relative;
    padding:5px;
    border-radius:5px;
    border: 1px dashed ${props => props.desabilitado ? "#cbcbcb" : "transparent"};
    background:  ${props=> !props.opened ? (props.index === 0 ? "#F3F3F4" : props.index === 1 ? "#cbcbcb" : "#999999")  : (props.desabilitado ? "white" : "#F3F3F4") };
    z-index:1;
    width:100%;
    opacity:${props=> props.desabilitado ? "0.5" : 1};
    min-height:49px;
    
   
   .duesDisabled{
    color:#999999;
   }
   
  .cuotas{
    width:100%;
    position:relative;
    z-index:0;
    transform:translateZ(0);
    overflow:hidden;
    padding:2px;
    
    select{
      width:100%;
      height:30px;
      background:transparent;
      -moz-appearance:none; /* Firefox */
      -webkit-appearance:none; /* Safari and Chrome */
      appearance:none;
      
      background: transparent url(/img/caretDues.png) center right no-repeat;
      background-size:12px auto;
      
      font-size:14px;
      
      &:focus{
      outline:none;
      }
     } 
   }

  }
  
  .franchise{
    font-size:17px;
    color:#777799;
    transform-style: preserve-3d;

    img{
      max-height:25px;
      max-width:60px;
      display:block;
      width:auto;
      height:auto;
      margin-right:5px;
      filter: grayscale(${props => props.desabilitado ? "100%" : 0});
    }
    
    
    p{
   
     flex:1 0 50%;
     display:block;
      overflow:hidden;
      text-overflow:ellipsis;
      word-wrap:normal;
    }
  }
  
  .seleccionada{
    min-height:35px;
    color:#999999;
    padding-top:6px;
    
    .material-icons{
      height:16px;
      font-size:16px;
      vertical-align:middle;
    }
  }
  
  .selected{
    
    .material-icons{
      color:green;
    }
    
    
    .continuar,.accion{
      color:${props =>  props.color};
      cursor:pointer;
      position:relative;
      z-index:3;
      background:transparent;
      


     & > * {
        pointer-events:none;
     }
     
     &:focus{
      outline:none;
     }
      
      &:hover{
        text-decoration:underline;
      }
   
  }
  
  .continuar{
       background:${props => props.color};
       border-radius:5px;
       padding:5px;
       color:white;
       font-size:13px;
    }
  }

    .confirm{
        background:#F1F1F9;
        border-radius:5px;
        position:relative;
        color:#777799;
        width:100%; 
        transform:translateZ(0);
        z-index:0;
        height:49px;
        font-size:14px;
        
        transform-style: preserve-3d;  
        
      button{
        height:30px;
        font-size:13px!important;
        border-radius:4px;
        padding:0 6px;
        width:100%;
        position:relative;
        transform:translateZ(0);  
        z-index:1!important;
        
       
        
        &:focus{
          outline:none;
        }
      }
      
   
      
      .cancel{
        color:${props=> props.color ? props.color : "#444444"};
        background:transparent;
        z-index:0;
      }
      .elimina{
        background:${props=> props.color ? props.color : "#444444"};
        color:white;
      }
    }
    
    
    .accion{
     color:${props =>  props.color};
     background:transparent;
     
     &:hover{
      text-decoration:underline;
     }
    }
    
    @media all and (max-width:320px){
        .confirm{
          .texto{
            font-size:11px;
          }
        }
      
        .franchise{

          img{
            max-height:20px;
            max-width:40px;
          }
        }
        
        
        
    } style=
    
   
    
    
 
    
`;
