import React from 'react';
import PropTypes from 'prop-types';

/**
 * Styles
 */
import { Container } from './styles';

function Wrappercheckout({ children}) {

  return (
    <Container>
      {children}
    </Container>
  );
}

Wrappercheckout.propTypes = {
  children: PropTypes.node.isRequired,
};

Wrappercheckout.defaultProps = {
  children: {},
};

export default React.memo(Wrappercheckout);
