import styled from '@emotion/styled';

export const Container = styled.div`
    text-align:center;
    font-size:14px;
    width:100%;
    padding:15px;
    
    @media all and (max-width: 399px){
      p{
      font-size:12px;
      }
    }
`;
