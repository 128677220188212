import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import * as Yup from 'yup';
import Cardsaved from '../CardSaved';

/**
 * Mutation
 */
import { SEND_EMAIL } from '../../data/mutations/email';

/**
 * Global state
 */
import useGlobal from '../../config/global';

/**
 * Components
 */
import Flex from '../Flex';
import Titlesection from '../TitleSection';
import Loading from '../loading';
import Input from '../input';
import { getCorrectTextColor } from '../getContrastColor';
import { STEP } from '../step';
import Btnt from '../BtnT';

/**
 * Styles
 */
import { Container } from './styles';

const SignupSchema = Yup.object()
  .shape({
    email: Yup.string()
      .email('Correo no válido.')
      .required('Debe ingresar un correo.'),
  });

function Loginform({ value, idTransaction }) {

  const [globalState, globalActions] = useGlobal();
  const [email, setEmail] = useState(value);
  // eslint-disable-next-line
  // const [data, setData] = useState(null);

  let loginRef = useRef(null);

  const [authEmail, { loading }] = useMutation(SEND_EMAIL, {
    onError: error => console.log(error.message),
    onCompleted: data => {
      if (process.env.NODE_ENV === 'development') {
        console.log('Auth email:', data);
      }
      globalActions.setEmail(email);
      globalActions.setIdTransaction(idTransaction);
      globalActions.setStep(STEP.validateCode);
    },
  });

  // // eslint-disable-next-line
  // function Before() {
  //   // eslint-disable-next-line
  //   const [css] = useStyletron();
  //   return (
  //     <div
  //       className={css({
  //         display: 'flex',
  //         alignItems: 'center',
  //         width: '30px',
  //         paddingLeft: '8px',
  //       })}
  //     >
  //       <img src="/img/letter.svg" alt="" className={'imgr'} />
  //     </div>
  //   );
  // }

  //close session empty data
  // useEffect(() => {
  //   if (globalState.isSession === false) setData(null);
  // }, [globalState.isSession]);

  // useEffect(()=>{
  //   setTimeout(()=>{
  //     loginRef.current.focus();
  //   },600)
  // },[]);
  useEffect(()=>{
    if(!globalState.isAnimating){
        loginRef.current.focus()
    }
  },[globalState.isAnimating]);

  let globalColor = "#" + globalState.commerce.colorLogo;


  useEffect(()=>{
    if(globalState.transaction.epaycoCustomer){
      // console.log("si cambiia");
      globalActions.setEmail(globalState.transaction.epaycoCustomer);
      globalActions.setIdTransaction(idTransaction);
      globalActions.setStep(STEP.validateCode);
      globalActions.setMainButton({show: false});
    }
  })

  return (
    <Container
      color={ globalColor}
      colorText={getCorrectTextColor(globalColor)}
    >

      <Titlesection
        text={'Ingrese su correo electrónico para iniciar'}
      />

      <div className={'wc'}>
        <div className={'wc pb-3'}>
          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={SignupSchema}
            onSubmit={values => {
              // same shape as initial values
              setEmail(values.email);
              loginRef.current.blur()
              authEmail({
                variables: {
                  email: values.email,
                  idTransaction,
                },
              });
            }}
          >
            {({
              errors,
              touched,
              handleChange,
              handleSubmit,
              values,
            }) => (
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  handleSubmit();
                }}
                className={'wc'}
              >
                <Flex className={'wc'} alg={'stretch'}>
                  <Input
                    disabled={loading || globalState.isAnimating}
                    tipo={'text'}
                    type={'text'}
                    id="emailLogin"
                    value={values.email}
                    onChange={handleChange}
                    label={'Correo electrónico'}
                    clearable
                    name={'email'}
                    icon={'mail_outline'}
                    flex={'1 0 150px'}
                    error={
                      errors.email && touched.email && errors.email
                    }
                    REF={loginRef}
                  />
                  <Flex
                    flex={'0 0 40px'}
                    direction={'column'}
                    jc={'flex-start'}
                    style={{
                      paddingTop: '22px',
                    }}
                  >
                    <Btnt
                      disabled={loading}
                      typeButton={"submit"}
                      text={
                        <i
                          className="material-icons"
                          style={{ lineHeight: '36px' }}
                        >
                          arrow_forward_ios
                        </i>
                      }
                      br={'20px'}
                    />
                  </Flex>
                </Flex>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <Loading show={loading}/>
    </Container>
  );
}

Loginform.propTypes = {
  value: PropTypes.string.isRequired,
  idTransaction: PropTypes.string.isRequired,
};

Loginform.defaultProps = {
  value: '',
};

export default React.memo(Loginform);
