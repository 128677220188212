import React from 'react';
import styled from '@emotion/styled';


const Container = styled.div`
  width:100%;
  padding: 15px 0;
  font-size:14px;
  color:#999999;
  text-align:center;
  
  img{
    height:70px;
    width:auto;
    margin: 0 auto 15px;
  } 
`

function Notfound({text=""}) {
  return (
    <Container>
      <img src="https://multimedia-epayco.s3.amazonaws.com/dashboard/not-found.png" alt=""/>
      <p>{text!== "" ? text : "Sin información."}</p>
    </Container>
  );
};

export default Notfound;
