import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import {
  RowS,
  Upv,
  BarraGris,
  LogoC,
  styles,
} from "./styles";

export const Row = ({ name, value }) => {
  return (
    <RowS>
      <View style={styles.name}>
        <Text>{name}</Text>
      </View>
      <View style={styles.line}></View>
      <View style={styles.value}>
        <Text>{value}</Text>
      </View>
    </RowS>
  );
};

export const SimpleDato = ({ name, value, direction = 'left' }) => {
  const Style = StyleSheet.create({
    name: {
      color: '#1F2229',
      fontSize: '8pt',
      letterSpacing: '0.5pt',
      marginBottom: '2pt',
      textAlign: direction,
    },
    value: {
      color: '#828691',
      fontSize: '9pt',
      letterSpacing: '0.5pt',
      textAlign: direction,
    },
  });

  return (
    <View
      style={{
        width: '100%',
        color: 'black',
        textAlign: direction,
        paddingBottom: '15pt',
      }}
    >
      <View style={Style.name}>
        <Text>{name.toUpperCase()}</Text>
      </View>
      <View style={Style.value}>
        <Text>{value}</Text>
      </View>
    </View>
  );
};

export const Total = ({
  estado   = 'pendiente',
  total    = "0",
  currency = "cop"
}) => {

  let estados = {
    aceptada  : '#67C940',
    rechazada : '#E1251B',
    pendiente : '#FFD100',
  };

  let colorEstado = estados[estado.toLowerCase()];

  let ss = StyleSheet.create({
    barra: {
      height: '27pt',
      color: 'white',
      backgroundColor: colorEstado,
      textAlign: 'center',
      fontSize: '12pt',
      paddingTop: '6pt',
      width: '100%',
    },
    number: {
      width: 'auto',
      display: 'block',
      fontSize: '20pt',
      flex: '0 0 80%',
      color: 'black',
    },
    curr: {
      width: 'auto',
      display: 'block',
      fontSize: '12pt',
      flex: '0 0 30pt',
      color: '#8C8C91',
      padding: '0 0 2pt 5pt',
    },
    amount: {
      display: 'flex',
      flexFlow: 'row wrap',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'flex-end',
      width: '100%',
      padding: '5pt',
    },
  });

  return (
    <View
      style={{
        flex: '1 0 100%',
        width: '100%',
        border: '1pt solid #e4e4e4',
        marginBottom: '15pt',
      }}
    >
      <View style={ss.barra}>
        <Text>
          {estado.toUpperCase()}
        </Text>
      </View>
      <View style={ss.amount}>
        <View style={ss.number}>
          <Text>${total}</Text>
        </View>
        <View style={ss.curr}>
          <Text>{currency.toUpperCase()}</Text>
        </View>
      </View>
    </View>
  );
};

export const BarraG = ({ name }) => {
  return (
    <View style={{ padding: '10pt 20pt' }}>
      <BarraGris>
        <Text>{name}</Text>
      </BarraGris>
    </View>
  );
};

export const Upsection = ({
  estado=      "Pendiente.",
  monto=       "0.",
  currency=    "sin info.",
  nombre=      "Sin información.",
  correo=      "Sin información.",
  telefono=    "Sin información.",
  numeroPago=  "Sin información.",
  fechaPago=   "00/00/00.",
  logo = null
}) => {

  return (
    <Upv>
      <View style={{ flex: '1 0 45%', width: '49%' }}>
        {logo !== null &&
        <LogoC
          src={{
            data: logo,
            format: 'png',
          }}
        />
        }
        <SimpleDato name={'Nombre'}             value={nombre} />
        <SimpleDato name={'Correo electrónico'} value={correo} />
        <SimpleDato name={'Número de teléfono'} value={telefono} />
      </View>
      <View style={{ flex: '1 0 45%', width: '49%' }}>
        <Total
          estado={estado}
          total={monto}
          currency={currency}
        />
        <SimpleDato
          name={'Número de pago'}
          direction={'right'}
          value={numeroPago}
        />
        <SimpleDato
          name={'Fecha de pago'}
          direction={'right'}
          value={fechaPago}
        />
      </View>
    </Upv>
  );
};
