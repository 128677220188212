import styled from '@emotion/styled';
import { animated } from 'react-spring';

export const Container = styled(animated.div)`
  font-family: Roboto Open Sans, sans-serif;
  color: #383d43;
  height: 100%;
  width: 100%;
  position: absolute;
  top:0;
  left:0;
  z-index:0; 
  transform:translateZ(0);
`;
