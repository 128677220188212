import { gql } from 'apollo-boost';

export const HISTORY_DOMICILIATION = gql`
  query historyDomiciliation(
    $idDomiciliation: String!
    $idTransaction: String!
  ) {
    historyDomiciliation(
      idDomiciliation: $idDomiciliation
      idTransaction: $idTransaction
    ) {
      status
      payments {
        refPayco
        facturaId
        globalStatus
        amount
        card {
          metadata {
            name
            mask
          }
        }
        paycoData
        invoiceData
      }
    }
  }
`;

