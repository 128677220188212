import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';

/**
 * Styles
 */
import { Container, LogoComercio } from './styles';
import 'bootstrap/dist/css/bootstrap.min.css';

/**
 * Util
 */
import { FormatMoney, Upercase } from '../../util/format';
import { getCorrectTextColor } from '../getContrastColor';
import ModalMultipleSuscripciones from '../ModalMultipleSuscripciones';

/**
 * Global state
 */
import useGlobal from '../../config/global';

/**
 * Components
 */
import Flex from '../Flex';
import { STEP } from '../step/constants';

function Header({ commerce, transaction, close, style }) {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal();
  const { logo, comercio } = commerce;
  const { epaycoName, epaycoAmount, epaycoCurrency } = transaction;

  useEffect(() => {
    globalActions.setCloseCheckout(() => close());
  }, []);

  let Globalcolor = '#' + globalState.commerce.colorLogo;

  const showUser =
    globalState.isSession &&
    globalState.step !== STEP.responseDomiciliation &&
    globalState.step !== STEP.response;

  const headerProps = useSpring({
    from: {
      opacity: 0,
      transform: 'translateY(100%)',
    },
    to: {
      opacity: showUser ? 1 : 0,
      transform: showUser ? 'translateY(0)' : 'translateY(100%)',
    },
  });

  return (
    <Container
      background={Globalcolor}
      colortexto={getCorrectTextColor(Globalcolor)}
      style={style}
    >
      <Flex
        className={'py-3 wc logo-texto'}
        flex={'1 0 95px'}
      >
        <LogoComercio>
          <img src={logo ? logo : '/img/shop.png'} alt="" />
        </LogoComercio>

        <Flex flex={'1 0 50%'}>
          <div className="texto">
            <p className={'comercio mb-0'}>{comercio}</p>
            {!globalState.isAdmin && (
              <p className={'servicio mb-0'}>{epaycoName}</p>
            )}
            {!globalState.isAdmin && (
              <p className={'total mb-0'}>
                {FormatMoney(epaycoAmount) !== '$0.00' &&
                  FormatMoney(epaycoAmount)}{' '}
                {FormatMoney(epaycoAmount) !== '$0.00' &&
                  Upercase(epaycoCurrency)}
              </p>
            )}
            {globalState.isMultipleSubscriptions && (
              <p className={'comercio mb-0'} 
                 style={{cursor: 'pointer'}}
                 onClick={() => {
                  globalActions.setErrorAlert({
                    message:["."],
                    icon:"info",
                    title:"Usuario ya registrado",
                    content: ()=> <ModalMultipleSuscripciones/>,
                    hideButtons:true,
                  })
                 }}    
              >Ver Detalle</p>
            )}
            {globalState.isAdmin && (
              <p className={'comercio mb-0'}>Suscripciones</p>
            )}
            {globalState.isAdmin && (
              <p className={'total mb-0'}>Panel administrativo</p>
            )}
          </div>
        </Flex>
        <Flex
          flex={'0 0 30px'}
          direction={'column'}
          jc={'flex-start'}
          className={'pr-3 wc align-self-start'}
        >
          <button className={'closeC '} onClick={() => close()}>
            <i className={'material-icons'}>close</i>
          </button>
          {/*<button className={'translate'}>ES</button>*/}
        </Flex>
      </Flex>
      <div className={'wc'} style={{ overflow: 'hidden' }}>
        <animated.div style={{ height: '25px', ...headerProps }}>
          <Flex className={'wc o'} flex={'0 0 25px'} alg={'stretch'}>
            <Flex
              flex={'1 0 150px'}
              jc={'flex-start'}
              className={'text-left px-3 user'}
            >
              <i className={"material-icons mr-2"} color={getCorrectTextColor(Globalcolor)}>person</i>
              {globalState.isSession && globalState.isSession.email}
            </Flex>
            {
            !globalState.transaction.epaycoCustomer &&
            <Flex flex={'0 0 100px'} className={'closeS'}>
              <button
                disabled={globalState.isAnimating}
                style={{ cursor: 'pointer' }}
                onClick={() =>{
                  globalActions.setDirection(true);
                  globalActions.closeSession(false)
                }}
                className={' wc text-center close-sesion'}
              >
                Cerrar sesión
              </button>
            </Flex>
            }
          </Flex>
        </animated.div>
      </div>
    </Container>
  );
}

Header.propTypes = {
  commerce: PropTypes.shape({
    comercio: PropTypes.string.isRequired,
  }).isRequired,
  transaction: PropTypes.shape({
    epaycoName: PropTypes.string.isRequired,
    epaycoAmount: PropTypes.string.isRequired,
    epaycoCurrency: PropTypes.string.isRequired,
  }).isRequired,
  close: PropTypes.func.isRequired,
};

Header.defaultProps = {
  commerce: {
    logo: 'https://api.adorable.io/avatars/285/abott@adorable.png',
  },
  transaction: {
    epaycoCurrency: 'COP',
  },
  close: () => null,
};

export default React.memo(Header);
