import styled from '@emotion/styled';

export const Container =  styled('div')`
  width:100%;
  border-radius:5px;
  margin-bottom:10px;
  box-shadow: 0 2px 5px rgba(0,0,0,.15);
  overflow:hidden;
  background:#f1f1f2;

  button{
    background:transparent;
  }

  .ver-detalle{
    cursor:pointer;

    &:hover{
      text-decoration:underline;
    }
  }
`;
