import styled from '@emotion/styled';
import { animated } from 'react-spring';
import Flex from '../../Flex';

export const Container = styled(animated.div)`
  position:absolute;
  z-index:9999;
  top:0;
  left:0;
  width:100%;
  height:100%;

  .sombra{
    z-index:0;
    position:absolute;
    height:100%;
    width:100%;
    background: rgb(0,0,0);
    background: linear-gradient(176deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.1) 100%);
  }

  .cont-ventana{
    height:100%;
    z-index:3;
    position:absolute;
    top:0;
    left:0;
    width:100%;
  }

  .ventana{
    position:absolute;
    z-index:2;
    width:86%;
    left:7%;
    top : ${props => props.top ? props.top : 10}%;
    height:auto;
    background:white;
    min-height:150px;
    box-shadow:0 3px 10px rgba(0,0,0,.1);
    border-radius:4px;

    .close{
      position:absolute;
      top:5px;
      right:5px;
    }
  }

  .icon-alert{
    color:red;
  }
`;


export const DefaultCont = styled(Flex)`
  width:100%;
  transform:translateZ(0);
  color:#999999;

  .title{
    font-weight:bold;
    font-size:18px;
    text-align:center;
    padding-bottom:10px;
    color:#3a3a3a;
  }

  .icon{
    width:100%;
    padding:10px 0;


    img{
      width:70px;
      height:auto;
      display:block;
      margin:auto;
    }
  }

  .description{
     font-size:15px;
  }

`;
