import React, { Fragment, useEffect, useState } from 'react';
import { Check } from './iconResponse';
import { Container } from './styles';
import TableCollapse from './collapse';
import TablaResponse from './tabla';
import { ChargeToClose, Toast,CardVinculated } from './helper';
import { closeCheckout } from '../../../../pages/onepage';

/**
 * Global state
 */
import useGlobal from '../../../../config/global';
import { getResponseFinish } from '../../../../util/helpers/response';

function ResponseDomiciliation() {
  const [charge, setCharge] = useState(0);
  const [globalState,globalActions] = useGlobal();
  const [response, setResp] = useState({});

  const paymentErrorResponse = ()=>{
    if (globalState.dataResponse.payment && (globalState.dataResponse.payment.estado === "Rechazada" || (globalState.dataResponse.payment.estado === "Fallida"))) {
      return " " + globalState.dataResponse.payment.respuesta;
    }
    else {
      return ""
    }
  }

  useEffect(() => {

    if (
      globalState.dataResponse !== null &&
      globalState.dataResponse.payment &&
      Object.entries(globalState.dataResponse.payment)[0]
    ) {
      const {
        ref_payco,
        estado,
        autorizacion,
        recibo,
        factura,
        franquicia,
        valor,
        moneda,
        mask,
      } = globalState.dataResponse.payment;
      let { message } = globalState.dataResponse;
      let { subscriptionDomiciliacionId } = globalState.dataResponse;
      setResp({
        ...response,
        ref_payco,
        estado,
        autorizacion,
        recibo,
        factura,
        franquicia,
        valor,
        moneda,
        message,
        subscriptionDomiciliacionId,
        mask,
      });
    }
  }, [globalState.responseInfo, globalState.dataResponse]);

  useEffect(() => {
    let contador = 3.3;
    globalActions.updateState({
      migasUp:{
        show:false
      }
    });

    const interval = setInterval(() => {
      setCharge(contador);
      if (contador > 100) {
        if (globalState) {
          executeCloseCheckout()
          clearInterval(interval);
        }
      }
      contador = contador + 3.3;
    }, 1100);

    return () => clearInterval(interval);
  }, []);

  const executeCloseCheckout = () => {
    let responseOnFinish = getResponseFinish(globalState);
    closeCheckout(responseOnFinish);
  }

  const renderResponseContent = (subscriptionDomiciliacionId, formaCobro, nombreSuscripcion) => {
    return <Fragment>
      {!globalState.isMultipleSubscriptions ? 
      <TablaResponse
        header={{
          name: 'Número de suscripción',
          value: subscriptionDomiciliacionId,
        }}
        content={[
          {
            name: 'Formato de cobro',
            value: formaCobro,
          },
          {
            name: 'Medios de pago',
            ancho:"100%",
            value: <>
              {globalState.cardsSelectedForsuscribe.map((item,key)=>
                <CardVinculated
                  key       = { key                 }
                  mask      = { item.mask.slice(-4) }
                  franchise = { item.franchise      }
                  dues      = { item.dues           }
                  type      = { item.accountType ? item.accountType :  '' }
                />
              )}
            </>
            // globalState.dataResponse.franchise &&
            // globalState.dataResponse.medioPago &&
            // globalState.dataResponse.franchise + ' ' +
            // globalState.dataResponse.medioPago.slice(-8),
          },
          {
            name: 'Nombre de suscripción',
            value: nombreSuscripcion,
          },

        ]}
      /> : (subscriptionDomiciliacionId) ? 
      <TableCollapse
        header={{
          name: 'Número de suscripción',
          value: subscriptionDomiciliacionId,
        }}
        content={[
          {
            name: 'Formato de cobro',
            value: formaCobro,
          },
          {
            name: 'Medios de pago',
            ancho:"100%",
            value: <>
              {globalState.cardsSelectedForsuscribe.map((item,key)=>
                <CardVinculated
                  key       = { key                 }
                  mask      = { item.mask.slice(-4) }
                  franchise = { item.franchise      }
                  dues      = { item.dues           }
                  type      = { item.accountType ? item.accountType :  '' }
                />
              )}
            </>
            // globalState.dataResponse.franchise &&
            // globalState.dataResponse.medioPago &&
            // globalState.dataResponse.franchise + ' ' +
            // globalState.dataResponse.medioPago.slice(-8),
          },
          {
            name: 'Nombre de suscripción',
            value: nombreSuscripcion,
          },

        ]}
      /> :''
      } 
      <br/>
    </Fragment>
  }

  let responseContent = null;

  if (globalState.isMultipleSubscriptions) {
    responseContent = globalState.dataResponse.data.map((item, key) => renderResponseContent(item.subscriptionDomiciliacionId, item.formaCobro, item.nombreSuscripcion));
  } else {
    responseContent = renderResponseContent(globalState.dataResponse.subscriptionDomiciliacionId, globalState.dataResponse.formaCobro, globalState.dataResponse.nombreSuscripcion);
  }

  return (
    <Container
      className={'wc'}
      flex={'1 1 100%'}
      jc={'center'}
      direction={'column'}
    >
      <div className={'wc'}>
        {/*//  BARRA DE CARGA PARA CERRAR CHECKOUT  ///*/}
        <div className="wc">
          <ChargeToClose
            text={'Esta ventana se cerrará'}
            width={charge + '%'}
            time={'1.5'}
          />
        </div>
        <div className={'wc px-3 py-2'}>
          {!globalState.isMultipleSubscriptions ? <Check
            estado={globalState.dataResponse.icon || 'rechazada'}
            text={ globalState.dataResponse.message+ paymentErrorResponse()}
          /> :
            <Check
              estado={'info'}
              text={'El proceso de suscripción al pago automático se ha finalizado. Por favor, verifique las suscripciones aceptadas o rechazadas. Para las suscripciones aceptadas, el pago automático se aplicará en su próxima factura.'}
              subtext={'Si tiene un saldo pendiente, le recomendamos hacer un pago manual para mantener su cuenta al día.'}
            />
          }

          <Toast
            show={globalState.dataResponse.icon === 'pendiente'}
            type={'info'}
            text={
              <p>Le informaremos por medio de un correo electrónico en un <b>lapso de 5 días hábiles</b> cuando su cuenta sea validada y activada.</p>
            }
          />

          <div className="wc py-2">
            {responseContent}
          </div>
          {globalState.dataResponse.footerMessage && (
            <div className="wc text-center">
              <small className={'cHeight'}>
                {globalState.dataResponse.footerMessage}
              </small>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
}

ResponseDomiciliation.propTypes = {};

ResponseDomiciliation.defaultProps = {};

export default React.memo(ResponseDomiciliation);
