import styled from '@emotion/styled';

export const ContInput = styled.div`
  width: 100%;
  position:relative;
  z-index:1;
  transform:translateZ(0);
  flex: ${props=> props.flex ? props.flex: "0 0 auto"};

  .cont-ip{
    padding-top:${props=> props.haslabel ? "15px" : ""};
  }
  div{
    padding-right: ${props=> props.isPassword? '0': ''} !important;
  }
  label{
    position:relative;
    top:0px;
    left:0;
    z-index:0;
    height:15px;
    transition: all 500ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    margin:0;
    color:rgba(0, 0, 0, 0.54);
    font-size:${props=> props.labelUp ? "12px" : "16px"};
    font-weight:400;
    text-overflow: ellipsis;
    white-space: nowrap;
    width:${props => !props.labelUp ? "calc(100% - 40px)" :"100%" };
    overflow: hidden;
    line-height:15px;
    transform:translate( ${props=> props.labelUp ? "0, 0":  props.leftSpace + " ,32px"});
  }

  .formatNumber{
    background:transparent;
    padding-left:45px;
    border-bottom:2px solid ${props => props.color};
    height:40px;
    transition: all 500ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    font-size:16px;

    &:focus{
      border-bottom:2px solid ${props => props.colorFocus};
    }

    &:disabled{
      color:#e4e4e4
    }
  }

  .icon-tdc{
    position:absolute;
    left:0;
    height:100%;
    top:0;
    z-index:2;
  }

  .icon{
    position:absolute;
    top:${props=> props.haslabel ? "18px":"0"};
    left:0;
    width:30px;
    height:40px;
  }


  .error{
    color:red;
    font-size:12px;
    line-height:15px;
    display:block;
    padding-top:7px;
    text-align:left;
  }

  button{
    padding: 0;
    color:#049bf3;
  }
`;
