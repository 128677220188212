import styled from '@emotion/styled';


export const Dragcont = styled.div`
  .toogle{
    
    &:disabled{
      color: #cbcbcb;
    }
  }
`;

export const Container = styled.div`
  background: ${props => props.theme.background.box};
  width: 350px;
  height: 200px;
`;

export const BtnAdd = styled.div`
    width:100%;
    padding:.5em .75em;
    
    button{
      border-radius:10px;
      width:100%;
      text-align:center;
      background:#F3F3F4;
      padding:.5em;
      color:${props=> props.color};
      font-weight:bold;
      
      font-size:16px;
      
      &:focus{
      outline:none;
      }
      
      .material-icons{
        line-height:1.3em;
        vertical-align:middle;
      }
    }
    
`
