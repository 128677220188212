import React, { lazy, Suspense } from 'react';
import Loading from '../../../loading';

const SimpleContainer = lazy(()=> import('../../../UI/simpleContainer') );
const TitleSection = lazy(()=> import('../../../TitleSection/TitleSection'))
const DebitAcountForm = lazy(()=> import('./forms/DebitAcountForm'))

const CuentaDebito = (props) => {
  return (
    <Suspense fallback={
      <Loading show={true} texto={"Cargando Formulario..."}/>
    }>
      <SimpleContainer>
        <TitleSection text={"Ingrese los datos de su cuenta"}/>
        <DebitAcountForm/>
      </SimpleContainer>
    </Suspense>
  );
};

export default React.memo(CuentaDebito);
