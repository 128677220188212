export default [
  {
    "slug": "VS",
    "active": true,
    "name": "VISA",
    "images": {
      "small": "small.png",
      "standard": "standard.png",
      "big": "big.png"
    }
  },
  {
    "slug": "MC",
    "active": true,
    "name": "Master card",
    "images": {
      "small": "small.png",
      "standard": "standard.png",
      "big": "big.png"
    }
  },
  {
    "slug": "DC",
    "active": true,
    "name": "Dinners club",
    "images": {
      "small": "small.png",
      "standard": "standard.png",
      "big": "big.png"
    }
  },
  {
    "slug": "AM",
    "active": true,
    "name": "American Express",
    "images": {
      "small": "small.png",
      "standard": "standard.png",
      "big": "big.png"
    }
  },
  {
    "slug": "CR",
    "active": true,
    "name": "Crédito Credencia",
    "images": {
      "small": "small.png",
      "standard": "standard.png",
      "big": "big.png"
    }
  },
  {
    "slug": "PSE",
    "active": true,
    "name": "PSE",
    "images": {
      "small": "small.png",
      "standard": "standard.png",
      "big": "big.png"
    }
  },
  {
    "slug": "DV",
    "active": true,
    "name": "Débito Visa",
    "images": {
      "small": "small.png",
      "standard": "standard.png",
      "big": "big.png"
    }
  },
  {
    "slug": "DM",
    "active": true,
    "name": "Débito Master Card",
    "images": {
      "small": "small.png",
      "standard": "standard.png",
      "big": "big.png"
    }
  },
  {
    "slug": "QR",
    "active": true,
    "name": "name",
    "images": {
      "small": "small.png",
      "standard": "standard.png",
      "big": "big.png"
    }
  },
  {
    "slug": "GA",
    "active": true,
    "name": "Gana",
    "images": {
      "small": "small.png",
      "standard": "standard.png",
      "big": "big.png"
    }
  },
  {
    "slug": "EF",
    "active": true,
    "name": "Effecty",
    "images": {
      "small": "small.png",
      "standard": "standard.png",
      "big": "big.png"
    }
  },
  {
    "slug": "SP",
    "active": true,
    "name": "Safety Pay",
    "images": {
      "small": "small.png",
      "standard": "standard.png",
      "big": "big.png"
    }
  },
  {
    "slug": "SPF",
    "active": true,
    "name": "Dispersión",
    "images": {
      "small": "small.png",
      "standard": "standard.png",
      "big": "big.png"
    }
  },
  {
    "slug": "BA",
    "active": true,
    "name": "Baloto",
    "images": {
      "small": "small.png",
      "standard": "standard.png",
      "big": "big.png"
    }
  },
  {
    "slug": "SRF",
    "active": true,
    "name": "name",
    "images": {
      "small": "small.png",
      "standard": "standard.png",
      "big": "big.png"
    }
  },
  {
    "slug": "PR",
    "active": true,
    "name": "Punto Red",
    "images": {
      "small": "small.png",
      "standard": "standard.png",
      "big": "big.png"
    }
  },
  {
    "slug": "RS",
    "active": true,
    "name": "Red Servi",
    "images": {
      "small": "small.png",
      "standard": "standard.png",
      "big": "big.png"
    }
  },
  {
    "slug": "DP",
    "active": true,
    "name": "DaviPLata",
    "images": {
      "small": "small.png",
      "standard": "standard.png",
      "big": "big.png"
    }
  },
  {
    "slug": "MPD",
    "active": true,
    "name": "name",
    "images": {
      "small": "small.png",
      "standard": "standard.png",
      "big": "big.png"
    }
  },
  {
    "slug": "PP",
    "active": true,
    "name": "PayPal",
    "images": {
      "small": "small.png",
      "standard": "standard.png",
      "big": "big.png"
    }
  },
  {
    "slug": "TDC",
    "active": true,
    "name": "Tarjeta de Crédito/Débito",
    "type":"TDC",
    "material":"credit_card",
    "images": {
      "small": "small.png",
      "standard": "standard.png",
      "big": "big.png"
    },
    "step":"creditCard",
    "isPrimary": true
  },
  {
    "slug": "DEBIT",
    "active": String(process.env.ACTIVE_DEBIT_SUBSCRIPTION).toLowerCase() == "true",
    "name": "Cuenta de Ahorros/Corriente",
    "material":"account_balance",
    "images": {
      "small": "small.png",
      "standard": "standard.png",
      "big": "big.png"
    },
    "step": "debito",
    "isPrimary": true
  },
  {
    "slug": "CASH",
    "active": true,
    "name": "Efectivo",
    "images": {
      "small": "small.png",
      "standard": "standard.png",
      "big": "big.png"
    }
  }
]
