import styled from '@react-pdf/styled-components';
import { StyleSheet } from '@react-pdf/renderer';


export const RowS = styled.View`
  width: 100%;
  margin: auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-end;
  padding: 8pt 20pt;
`;

export const Contenedor = styled.View`
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  align-content: flex-start;
`;

export const Contenido = styled.View`
  width: 100%;
  display: block;
  justify-content: flex-start;
  align-content: flex-start;
`;

export const Separation = styled.View`
  flex: 1 0 auto;
  width: 100%;
`;

export const Footer = styled.View`
  display:flex;
  flex-flow:row wrap;
  justify-content:flex-end;
  align-items:center;
  height: 40pt;
  flex: 0 0 auto;
  width: 100%;
  margin: auto;
  font-size: 12pt;
  color: #3a3a3a;
  padding:0 20pt;
`;
export const FooterText = styled.View`
  flex:0 0 auto;
  color:black;
  font-size: 9pt;
  padding: 0 5pt 0 0;
`;
export const FooterLogo = styled.View`
  flex:0 0 auto;
`;


export const Subtitle = styled.Text`
  font-weight: bold;
  font-size: 16pt;
  width: 100%;
`;

export const Title = styled.Text`
  font-weight: bold;
  font-size: 22pt;
  width: 100%;
`;

export const Upv = styled.View`
  width: 100%;
  padding: 20pt 20pt 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
`;

export const BarraGris = styled.View`
  width: 100%;
  font-size: 14pt;
  text-align: center;
  color: #868585;
  backgroundColor: #e4e4e4;
  padding: 8pt;
`;

export let LogoC = styled.Image`
      max-width: 120pt;
      margin-bottom:15pt;
      display:block;
    `;

export const EpaycoLogo = styled.Image`
      max-width: 45pt;
      display:block;
  `;

export const styles = StyleSheet.create({
  body: {
    border: 1,
    height: '100%',
    width: '100%',
  },
  row: {
    display: 'flex',
    width: '100%',
    margin: 'auto',
  },
  name: {
    width: 'auto',
    fontSize: 8,
    display: 'block',
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 'auto',
    color: '#1F2229',
  },
  line: {
    width: 'auto',
    fontSize: 9,
    display: 'block',
    border: 1,
    borderColor: '#cbcbcb',
    borderStyle: 'dotted',
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 'auto',
  },
  value: {
    width: 'auto',
    fontSize: 9,
    display: 'block',
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 'auto',
    color: '#1F2229',
  },
  page: {
    height: '100%',
    width: '100%',
  },
  image: {
    width: 60,
  },
  footer: {
    width: '80%',
    margin: 'auto',
  },
});
