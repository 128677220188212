const ParseUrl = ({ url, _id }) => {
  let ifBegin = url.split('?');
  ifBegin = ifBegin[1] && ifBegin[1].includes('=');
  return _id ? `${url}${ifBegin ? '&' : '?'}ref_payco=${_id}` : url;
};

export const RedirectResponse = ({ transaction, IdProcess }) => {
  const { epaycoResponse = 'https://epayco.co' } = transaction;
  return ParseUrl({ url: epaycoResponse, _id: IdProcess });
};

export function getResponseFinish(globalState) {
  let responseOnFinish = null;

  if (globalState && globalState.dataResponse) {
    if (globalState.responseInfo && !globalState.responseInfo.hasOwnProperty("length"))
      responseOnFinish = globalState.responseInfo

    if (globalState.isMultipleSubscriptions && globalState.dataResponse.data) {
      responseOnFinish.data = globalState.dataResponse.data.map(item => item.landingResponse);
    } else if (globalState.dataResponse.landingResponse && !globalState.transaction.epaycoSubscription.isLink) {
      responseOnFinish = globalState.dataResponse.landingResponse;
    }
  }
  return responseOnFinish;
}
