import React, { PureComponent, useEffect, useState } from 'react';
import consultaBin from "./consultaBin";
import Flex from '../../Flex';

class BinIconCard extends PureComponent {

  constructor(props) {
    super(props);

    this.state={
      icon:'tdc'
    }
  }

  componentDidMount() {
    let number = this.props.number;
    if(number.toString().length > 5){
      (async ()=>{
        consultaBin(number.toString().substring(0,6),x=> this.setState({ icon:x }))
      })()
    }
  }

  render(){
  let  {number,w="auto",h="30px",flex="0 0 30px" } = this.props

  let ruta  = `/img/cards/${this.state.icon}.png`;

  return (
    <Flex direction={"column"} flex={flex}>
      <img src={ruta} alt="" width={w} height={h} className={"mx-auto"}/>
    </Flex>
  );
}
};

export default React.memo(BinIconCard);
