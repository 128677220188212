import styled from '@emotion/styled';
import Flex from '../../Flex';

export const Container = styled(Flex)` 
  width: 30px!important;
  height: 30px;
  border-radius: 50%;
  
  .circle{
    width:100%;
    height:100%;
    border-radius:50%;
   }
`;
