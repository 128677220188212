import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';

/**
 * App
 */
import App from './app';

/**
 * apollo config
 */
import client from './config/apollo';

const root = document.getElementById('root');
if (root !== null) {
  ReactDOM.render(
    <ApolloHooksProvider client={client}>
      <App />
    </ApolloHooksProvider>,
    root
  );
}
