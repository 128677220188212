import { gql } from 'apollo-boost';

export const TOKEN_SUBSCRIPTION = gql`
  mutation TOKEN_SUB($data: JSON) {
    tokenSubscription(data: $data) {
      token
    }
  }
`;

export const CHANGE_STATUS_SUSCRIPTION = gql`
  mutation CHANGE_STATUS_SUSCRIPTION(
    $hasLastPayment: String
    $idSubscription: String!
    $idTransaction: String!
    $status: String!
  ) {
    statusSubscription(
      idSubscription: $idSubscription
      status: $status
      hasLastPayment: $hasLastPayment
      idTransaction: $idTransaction
    ) {
      status
      message
    }
  }
`;

