import React from 'react';
import { withRouter } from 'react-router';
import Script from 'react-load-script';
import is from 'is_js';
import BgGost from '../../components/bgGost';
import Response from './response';

class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showResponse: false,
      dataResponse: [],
      fondoLogo: '',
    };
  }
  handleScriptLoad() {
    if (
      is.not.empty(this.props.match.params.id) &&
      is.string(this.props.match.params.id)
    )
      window.ePayco2.checkout({
        idTransaction: this.props.match.params.id,
        onCloseModal: () => {
          // window.alert('close modal');
        },
        onFinish: ({ data }) => {
          this.setState({ dataResponse: data, showResponse: true });
        },
      });
  }

  render() {
    return (
      <div className={'patron '}>
        <BgGost
          setColorLogo={(color) =>
            this.setState({ fondoLogo: color })
          }
        />

        {this.state.showResponse && (
          <Response
            fondoLogo={this.state.fondoLogo}
            logoComercio={this.state.dataResponse.logoComercio}
            suscripcion={this.state.dataResponse.suscripcion}
            comercio={this.state.dataResponse.comercio}
            monto={'----'}
            tarjeta={this.state.dataResponse.tarjeta}
            franchise={this.state.dataResponse.franchise}
            cuotas={this.state.dataResponse.cuotas}
            nombreUsuario={this.state.dataResponse.nombreUsuario}
            correo={this.state.dataResponse.email}
            contacto={this.state.dataResponse.contacto}
          />
        )}

        <Script
          url={process.env.REACT_APP_SCRIPT_CHECKOUT}
          onLoad={this.handleScriptLoad.bind(this)}
        />
      </div>
    );
  }
}

export default React.memo(withRouter(Landing));
