import React, {useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

/**
 * Styles
 */
import { Container } from './styles';
import Titlesection from '../TitleSection';
import Dragitems from '../DragItems';
import Listmethods from '../listMethods';
import useGlobal from '../../config/global';
import { STEP } from '../step';
import Metodos from '../../config/global/metodosPago/methods';
import ModalPoseeSuscripcion from "./ModalPoseeSuscipcion";
import {GET_SUSCRIBLE_CARDS} from "./../../data/mutations/customer";
import { useMutation } from '@apollo/react-hooks';
import CardWithSuscription from '../CardWithSuscription';
import { TitleSmall } from '../UI';
import Fade from '../Fade';



function Mediospago() {
  const [estado, SetE] = useState({
    collapse: false,
    compLoaded:false,
    loading:false,
    tarjetasSeleccionadas:[]
  });

  const ActualizaEstado = x => SetE(Object.assign({}, estado, x));

  const [globalState, globalActions] = useGlobal();
  const [metodos, setMetodos] = useState([]);
  const [onCliickCards, setOneClickCards] = useState([]);

  // SETEA EN EL ESTADO LA TARJETA SLECCIONADA
  let SeteaCard = (token, elimina) => {
    let copia = [...estado.tarjetasSeleccionadas];
    let copiaEstdoG = [...globalState.cardsWithsuscriptions];
    let seleccionadasEnG = [...globalState.cardsSelectedForsuscribe];

    if(elimina){
      copia = copia.filter( crd => crd !== token );
      seleccionadasEnG = seleccionadasEnG.filter(item => item.token !== token);
      ActualizaEstado({tarjetasSeleccionadas:copia});
      globalActions.updateState({ cardsSelectedForsuscribe :seleccionadasEnG });
    }else{
      seleccionadasEnG.push(copiaEstdoG.filter(e => e.token === token)[0]);
      globalActions.updateState({ cardsSelectedForsuscribe :seleccionadasEnG });
      ActualizaEstado({tarjetasSeleccionadas : [...estado.tarjetasSeleccionadas,token]});
      globalActions.setStep('defineDuesCards');
      globalActions.setMainButton({
        show:false,
        text:"",
        onClick:""
      })
    }
  }

  // OBTENER LAS TARJETAS CON SUSCRIPCIONES
  const [ObtenCardsuscritas] = useMutation(GET_SUSCRIBLE_CARDS,{
    onError: error => {
      console.log(error,"·error con guardadad:::::.");
    },
    onCompleted: data => {
        let {cards} = data.getSuscribableCards;
        cards= cards.filter((card)=>{
          if(card.tipo.includes('debito') || card.tipo.includes('débito')){
            if(card.status === "active") return true
            else return false
          }
          else return true
        })
        cards = cards.map(item => {
          let obj = {
            token:item.token,
            mask:item.mask,
            franchise:item.franchise,
            domiciliations: item.domiciliations,
            isDebit: item.tipo.includes('debito') || item.tipo.includes('débito') ? true: false,
            selected: item.domiciliations.includes(globalState.dataSubscription?globalState.dataSubscription.suscripcionInfo.id:"")
          }
          return obj;
        })
        globalActions.setCardsWithsuscriptions(cards);
        if(!(globalState.transaction.epaycoCustomer || globalState.isAdmin))
        globalActions.setErrorAlert({
          message:["."],
          icon:"info",
          title:"Usuario ya registrado",
          content: ()=> <ModalPoseeSuscripcion/>,
          hideButtons:true,
        })
    }
  })


  useEffect(() => {

    // ARMA LOS MEDIOS DE PAGOS ACTIVOS
    let activos=[];

    globalState.methods.forEach(item => {
      let obj = Metodos.find(n =>  n.slug === item);
      if(obj && obj.step) {
        if (STEP[obj.step])
          obj.step = STEP[obj.step]
        activos.push(obj);
      }
    });

    // SETEA LOS MEDIOS DE PAGO
    setMetodos(activos);


    let cardsS = [...globalState.cardsSubscription];
    let newArr = globalState.cards.map((ele)=>{
      let obj = {};
      obj.mask = ele.metadata.mask;
      obj.franchise = ele.metadata.franchise;
      obj.dues = 1;
      obj._id = ele._id;
      obj.selected = false;

      cardsS.map(n => {
        if(n.mask === obj.mask){
          obj.selected = true;
          return obj
        }else return null;
      });

      return obj;
    });
    setOneClickCards(newArr);
    ActualizaEstado({compLoaded:true})
    globalActions.updateState({
      cardsSelectedForsuscribe : [],
      migasUp:{ show:false }
    });
  }, []);

  useEffect(()=>{
    if((!globalState.isAnimating && estado.compLoaded) || globalState.transaction.epaycoCustomer){
      if(globalState.poseeSuscripcion){
        ObtenCardsuscritas({
          variables:{
            customerId: globalState.customerMongoId
          }
        })
      }
    }
  },[globalState.isAnimating]);

  // VIGILA LAS TARJETAS SELECCIONADAS PARA ACTIVAR EL BOTÓN PRINCIPAL
  useEffect(()=>{
    if(estado.tarjetasSeleccionadas.length > 0){
      if(!globalState.mainButton.show){
        globalActions.setMainButton({
          show:true,
          text:"Continuar",
          onClick:()=>{
            globalActions.setStep('defineDuesCards');
            globalActions.setMainButton({
              show:false,
              text:"",
              onClick:""
            })
          }
        })
      }
    }else{
      globalActions.setMainButton({
        show:false,
        text:"Continuar",
      })
    }
  },[estado.tarjetasSeleccionadas])



  let ListaMetodos = useMemo(() => metodos, [metodos]);
  // ListaMetodos.map(item =>( item.slug === 'DEBIT'? item.isPrimary = false:null))

  let TarjetasConsuscripciones = (globalState.cardsWithsuscriptions.length > 0 && !(globalState.transaction.epaycoCustomer || globalState.isAdmin)) ? globalState.cardsWithsuscriptions :  false;
  //let TarjetasGuardadas = onCliickCards.length > 0 ? onCliickCards:  false;

  const [TarjetasGuardadas, setTarjetasGuardadas] = useState([])

  useEffect(()=>{
    if(!(globalState.isAdmin || globalState.transaction.epaycoCustomer)){
      setTarjetasGuardadas(false)
    }
    else if(onCliickCards.length > 0 && globalState.cardsWithsuscriptions.length>0){
        let one= onCliickCards
        let debitCards = globalState.cardsWithsuscriptions.map((card)=>{
          if(card.isDebit) return card
          else{
            one.forEach((credit,i) => {
              if(card.mask.slice(-4) === credit.mask.slice(-4)){
                one.splice(i,1)
              }
            })
            return card
          }
        })
        setTarjetasGuardadas([...one, ...debitCards])
    } else if(globalState.cardsWithsuscriptions.length>0){
      setTarjetasGuardadas(globalState.cardsWithsuscriptions)
    }else{
      setTarjetasGuardadas(onCliickCards)
    }
  },[globalState.cardsWithsuscriptions,onCliickCards])

  return (
    <Container className="MediosPago px-3 px-sm-4 ">
      <Titlesection
        text={'Seleccione un método de suscripción'}
        backtext={"Volver"}
      />

      {TarjetasGuardadas &&
      <Dragitems
        items={TarjetasGuardadas}
        collapse={estado.collapse || globalState.cards.length < 1}
        setCollapse={() => ActualizaEstado({ collapse: !estado.collapse })}
        hideToggle={globalState.cards.length < 1}
        DisableReorderCards={()=>null}
      /> }

      {TarjetasConsuscripciones &&
      <Fade show={true}>
        <TitleSmall text={"Tarjetas con suscripciones activas"}/>
        {TarjetasConsuscripciones.map((item,key)=>
          <CardWithSuscription
            key={key}
            mask={item.mask}
            franchise={item.franchise}
            token={item.token}
            domiciliations={item.domiciliations}
            seleccionaCard ={(Tkn,elimina)=> SeteaCard(Tkn,elimina)}
            activa={estado.tarjetasSeleccionadas.filter(ele => ele === item.token)[0]}
          />)}
      </Fade>  }

      <Listmethods
        methods={ListaMetodos.filter(item => item.isPrimary)}
        disabled={estado.collapse || !!estado.tarjetasSeleccionadas[0] }
      />

    </Container>
  );
}

Mediospago.propTypes = {
  prop1: PropTypes.string.isRequired,
  prop2: PropTypes.number.isRequired,
  size: PropTypes.string.isRequired,
};

Mediospago.defaultProps = {
  prop1: 'value',
  prop2: 2000,
  size: 'big',
};

export default React.memo(Mediospago);
