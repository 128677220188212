import React from 'react';
import styled from '@emotion/styled';
import Flex from '../Flex';
import tinycolor2 from "tinycolor2";

const Container = styled(Flex)`
    position:relative;

    .circle{
      width:65px;
      height:65px;
      border-radius:50%;
      border:1px solid #cbcbcb;
      color: ${props=> props.color};

      .material-icons{
        color: ${props=> props.color};
        font-size:32px;
      }
    }

    .lock{
      position:absolute;
      right:5%;
      bottom:5%;
      width:16px;
      height:16px;
      background:white;
      border-radius:50%;

      .material-icons{
        height:16px;
        width:16px;
        font-size:16px;
        color:#cbcbcb;
      }
    }
`;

export const  BigIcon = ({icon,color="#3a3a3a"}) => {
  return (
    <Container color={color}>
      <Flex className="circle" direction={"column"}>
        <i className="material-icons">{icon}</i>
      </Flex>
      <div className="lock">
        <i className="material-icons">lock</i>
      </div>
    </Container>
  )
};

export const TitleSmall = ({text})=>{
  return (
    <div className={"wc mb-2"}>
      <small>{text}</small>
    </div>
  )
}

