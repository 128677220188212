import React, { Fragment, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

/**
 * Styles
 */
import { Container, DefaultCont } from './styles';
import Btnt from '../BtnT';
import Flex from '../Flex';
import { animated, useTransition } from 'react-spring';
import useGlobal from '../../config/global';


const DefaultContent = ({ title = '', icon = '', description = '', noBtn }) => {

  return (
    <DefaultCont>
      {icon !== '' &&
      <div className="icon pt-4 pb-3">
        <img src={`https://multimedia-epayco.s3.amazonaws.com/dashboard/modal_icons/${icon}.png`}
             alt=""/>
      </div>
      }
      {title !== '' &&
      <div className="title col-12 text-center">{title}</div>
      }
      {description !== '' &&
      <div className={` description ${noBtn && 'pb-4'}`}>{description}</div>
      }
    </DefaultCont>
  );
};


function Modalerror() {

  const [show, setShow] = useState(false);
  const [entro, setEntra] = useState(false);
  const [globalState, globalActions] = useGlobal();


  let showAlert = globalState.errorAlert.message.length > 0;
  let texto = globalState.errorAlert.message;
  let btns = globalState.errorAlert.btns;
  let content = globalState.errorAlert.content;
  let btnText = globalState.errorAlert.btnText;
  let btnCallback = globalState.errorAlert.btnCallback;
  let top = globalState.errorAlert.top;

  let quitar = useCallback(() => {

    if (show) {
      setEntra(true);
    }

    if (showAlert && (!show && entro)) {
      globalActions.setErrorAlert({ message: false });
      setEntra(false);
    }

  }, [show]);

  let SombraProps = useTransition(show, k => k, {
    from: {
      clipPath: `circle(0% at 50% 0)`,
      opacity: 0,
    },
    enter: {
      clipPath: 'circle(150% at 50% 0%)',
      opacity: 1,
    },
    leave: {
      clipPath: 'circle(150% at 50% 0%)',
      opacity: 0,
    },
    onDestroyed: () => quitar(),
  });

  let ModalProps = useTransition(show, l => l, {
    config: {
      mass: 1,
      tension: 350,
      friction: 27,
    },
    from: {
      transform: 'translateY(20%) scale(1)',
      opacity: 0,
    },
    enter: {
      transform: 'translateY(0) scale(1)',
      opacity: 1,
    },
    leave: {
      transform: 'translateY(0) scale(0.8)',
      opacity: 0,
    }
  });

  // Stop animation
  useEffect(() => {
    if (showAlert) {
      setShow(true);
    }
  }, [globalState.errorAlert]);

  const cancel = ()=>{
    btnCallback()
    return setShow(false)
  }
  return (
    <Fragment>
      {showAlert &&
      <Container className="modalError" top={top}>
        {SombraProps.map(({ item, props, key }) =>
          item &&
          <animated.div
            key={key}
            style={props}
            className="sombra"
            onTransitionEnd={()=> alert("listo")}
          >
          </animated.div>,
        )}
        {ModalProps.map(({ item, props, key }) =>
          item &&
          <animated.div key={key} style={props} className={'cont-ventana'}>
            <Flex className="ventana " direction={'column'}>
              <Flex
                className={'wc px-4 text-center'}
                flex={'1 0 150px'}
                direction={'column'}
                alg={'flex-start'}
              >
                {content ?
                  content() :
                  <DefaultContent
                    icon={globalState.errorAlert.icon}
                    title={globalState.errorAlert.title}
                    noBtn={globalState.errorAlert.hideButtons}
                    description={
                      texto.map((ele, index) =>
                        <p key={index}>{texto.length > 1 && <span>-</span>} {ele}.</p>,
                      )
                    }
                  />
                }
              </Flex>
              {!globalState.errorAlert.hideButtons &&
              <Flex className={'wc p-3'} flex={'0 0 50px'}>
                {btns.length === 2 ?
                  <Fragment>
                    <Flex flex={'1 0 100%'} className={'mb-2'}>
                      <Btnt
                        text={btns[0].text}
                        onClick={btns[0].onClick}
                        disabled={!show}
                      />
                    </Flex>
                    <Flex flex={'1 0 100%'} className={'mb-2'}>
                      <Btnt
                        type={'b'}
                        text={btns[1].text}
                        onClick={btns[1].onClick}
                        disabled={!show}
                      />
                    </Flex>
                    <Flex flex={'1 0 100%'}>
                      <Btnt
                        type={'c'}
                        text={'Cancelar'}
                        disabled={!show}
                        onClick={() => setShow(false)}
                      />
                    </Flex>
                  </Fragment>
                  : btns.length === 1 ?
                    <Fragment>
                      <Flex flex={'1 0 55%'} className={'mb-2'}>
                        <Btnt
                          text={btns[0].text}
                          onClick={btns[0].onClick}
                          disabled={!show}
                        />
                      </Flex>
                      <Flex flex={'1 0 45%'} className={'mb-2 pr-2'}>
                        <Btnt
                          disabled={!show}
                          type={'c'}
                          text={'Cancelar'}
                          onClick={cancel}
                        />
                      </Flex>
                    </Fragment>
                    :
                    <Btnt
                      text={btnText || 'Continuar'}
                      onClick={() => {
                        if (btnCallback) {
                          btnCallback();
                        }
                        setShow(false);
                      }}
                      disabled={!show}
                    />
                }
              </Flex>
              }
            </Flex>
          </animated.div>,
        )}
      </Container>
      }
    </Fragment>
  );
}

Modalerror.propTypes = {
  prop1: PropTypes.string.isRequired,
  prop2: PropTypes.number.isRequired,
  size: PropTypes.string.isRequired,
};

Modalerror.defaultProps = {
  prop1: 'value',
  prop2: 2000,
  size: 'big',
};

export default React.memo(Modalerror);
