import { gql } from 'apollo-boost';

export const TRANSACTION = gql`
  query transaction($_id: MongoObjectId!) {
    transaction(_id: $_id) {
      publicKey
      session
      commerce
      transaction
    }
  }
`;
