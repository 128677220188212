import { gql } from 'apollo-boost';

export const REORDENAR_METODOS_DOMICILIACION = gql`
  mutation REORDENAR_METODOS_DOMICILIACION(
    $customerId: String!
    $data: [JSON]
  ) {
    reorderCardsDomiciliation(customerId: $customerId, data: $data) {
      status
      message
    }
  }
`;

export const ONECLIK_TO_DOMICILIATION = gql`
  mutation ONECLIK_TO_DOMICILIATION(
    $token: MongoObjectId!
    $idCustomer: MongoObjectId!
    $idDomiciliation: Int!
    $dues: Int!
    $isDebit: Boolean!
  ) {
    oneClickToDomiciliation(
      idCustomer: $idCustomer
      token: $token
      idDomiciliation: $idDomiciliation
      dues: $dues
      isDebit: $isDebit
    ) {
      token
      mask
      franchise
      created
      dues
      priority
      domiciliacionId
      tipo
      debito_automatico_id
    }
  }
`;

export const GET_SUSCRIBLE_CARDS = gql`
  mutation GET_SUSCRIBLE_CARDS(
    $customerId: MongoObjectId!
  ) {
    getSuscribableCards(
      customerId:$customerId
    ){
      status,
      cards
    }
  }
`;


