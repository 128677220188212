import React from 'react';
import PropTypes from 'prop-types';

/**
 * Styles
 */
import { Container } from './styles';
import Flex from '../Flex';

function Lockprocesing({loading}) {
  return (
    <Container
      className={`${loading && "loading"} lockProcesing`}
      flex={"0 0 30px"}
    >
      <Flex className="circle p-2" direction={"column"}>
        <img src="/img/lock_f.svg" alt="" className={"imgr"}/>
      </Flex>
    </Container>
  );
}


export default Lockprocesing;
