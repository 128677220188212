import React from 'react';
import PropTypes from 'prop-types';

/**
 * Styles
 */
import { Container } from './styles';

function Modalcontent({ prop1, prop2, size }) {
  return (
    <Container className="modalcontent">
      {prop1}: $ {prop2}
    </Container>
  );
}

Modalcontent.propTypes = {
  prop1: PropTypes.string.isRequired,
  prop2: PropTypes.number.isRequired,
  size: PropTypes.string.isRequired,
};

Modalcontent.defaultProps = {
  prop1: 'value',
  prop2: 2000,
  size: 'big',
};

export default Modalcontent;
