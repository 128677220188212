import React, {
  useState,
  useEffect,
  Fragment,
  useCallback,
  useMemo,
} from 'react';
import { animated, useTransition } from 'react-spring';

/**
 * Components
 */
import Subscriptions from './subscriptions';
import Payment from './payment';
import History from './history';
import Titlesection from '../../../TitleSection';
import useGlobal from '../../../../config/global';

/**
 * Style
 */
import { ItemAction, Container } from './style';
import { STEP } from '../../index';

function AdminSubscription(props) {
  const [step, setStep] = useState({
    entered: false,
    active: 'initial',
  });

  const [globalState, globalActions] = useGlobal();

  const ReturnSubscription = useCallback(() =>
    actualizaStep({ active: 'initial' })
  );

  const actualizaStep = useCallback((x) =>
    setStep(Object.assign({}, step, x))
  );

  useEffect(() => {
    // console.log('Menu',globalState.transaction.epaycoMenu);
    if (!step.entered) {
      actualizaStep({ entered: true });
    }

    if(globalState.transaction.epaycoMenu && globalState.transaction.epaycoMenu.length===1){
      if(globalState.transaction.epaycoMenu.filter((w)=>w ==='payment').length>0)
      globalActions.setStep(STEP.adminPayment)
      if(globalState.transaction.epaycoMenu.filter((w)=>w ==='suscription').length>0)
      globalActions.setStep(STEP.adminSuscription)
      if(globalState.transaction.epaycoMenu.filter((w)=>w ==='history').length>0)
      globalActions.setStep(STEP.adminHistoryPay)
    }

  }, []);

  let GlobalColor = '#' + globalState.commerce.colorLogo;

  return (
    <Container className={'px-3 px-sm-4 pb-3'} color={GlobalColor}>
      <Titlesection text={'Administración de suscripciones'} />
      <div className="wc">
        {( !globalState.transaction.epaycoMenu || globalState.transaction.epaycoMenu.filter((w)=>w ==='payment').length>0) && 
        <ItemAction
          icon={'credit_card'}
          text={'Medios de pago'}
          onClick={() => globalActions.setStep(STEP.adminPayment)}
        />
        }
        {( !globalState.transaction.epaycoMenu || globalState.transaction.epaycoMenu.filter((w)=>w ==='suscription').length>0) && 
        <ItemAction
          icon={'replay'}
          text={'Suscripciones'}
          onClick={() => globalActions.setStep(STEP.adminSuscription)} 
        />
        }
       {( !globalState.transaction.epaycoMenu || globalState.transaction.epaycoMenu.filter((w)=>w ==='history').length>0) && 
        <ItemAction
          icon={'description'}
          text={'Historial de pagos'}
          onClick={() => globalActions.setStep(STEP.adminHistoryPay)}
        />
        }
      </div>
    </Container>
  );
}

AdminSubscription.propTypes = {};

AdminSubscription.defaultProps = {};

export default React.memo(AdminSubscription);
