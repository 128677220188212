import axios from 'axios';

export const getCountries  = async (callback)=>{
  let obj = {};
  try{
    await axios
      .get('https://secure.payco.co/apprest/paises.json')
      .then( async (response) => {
        if (response.status) {
          obj.indicativos = await  response.data.paises.map((item) =>({ label: item.nombre, value: '+' + item.indicativo }));
        }else{
          obj.result = false
          obj.msg = "Error obteniendo paises."
        }
      })
      .catch((error) => {
        console.log(error)
        obj.result = false
        obj.msg = "Error intentando obtener paises."
      });
  }
  catch (e) {
    console.log(" ERROR intentando obtener paises", e)
  }

  if(callback){
    return callback(obj)
  }else{
    return obj
  }

}
