import React from 'react';
import SimpleContainer from '../../../../UI/simpleContainer';
import Fade from '../../../../Fade';
import IconAlert from '../../../../UI/iconAlert';

const Finalizar = () => {
  return (
    <SimpleContainer>
        <Fade show={"true"}>
          <div className={"py-5 col-12 text-center"}>
            <IconAlert type={"check"}/>
            <strong>Suscripción creada y medios de pago asociados a la suscripción con éxito.</strong>
          </div>
        </Fade>
    </SimpleContainer>
  )
}

export default React.memo(Finalizar);
