import React, { useEffect, useState } from 'react';
import useGlobal from '../../config/global';
import Btnt from '../BtnT';
import { ModalS } from './styles';

function ModalMultipleSuscripciones() {
  const [globalState, globalActions] = useGlobal();
  const [total, setTotal] = useState(0);

  useEffect(() => {
    var subtotal = 0;
    globalState.multipleSubscriptions.map((item) => {
      if(item.accion !== 'suscribir')
      {
        subtotal += item.amount;
      }
    });
    numberWithCommas(subtotal);
    setTotal(subtotal);
  }, []); 

    return(
      <div className={"col-12 px-0"}>
        <button className={'close'} style={{paddingTop: 5}} onClick={() => {
           globalActions.setErrorAlert({message:[]});
        }}>
            <i className={'material-icons'}>close</i>
        </button>
        <ModalS>
            <div className="HeaderM">
              <div className="relleno"> 
                  <p className="mb-0"><strong>Detalle de valor a pagar</strong></p>
                  <span className="total"> ${total} COP</span>
              </div>   
            </div>
            { (total > 0) && ( 
            <div className="RowM">
              <div className="rellenoM">
                  <p className="mb-0"><strong>Facturas por cobrar</strong></p>
                  {globalState.multipleSubscriptions.map((item) => 
                    item.accion !== 'suscribir' && (
                      <div className="title-parent">
                        <div className="title-child"><span className="label">{item.parametersConsult}</span></div>
                        <div className="title-child"><span className="value">${numberWithCommas(item.amount)} COP</span></div>
                      </div>
                  ))}
              </div>
            </div>
            )}
            <div style={{width:'100%'}}>
              <Btnt onClick={()=> {
                globalActions.setErrorAlert({message:[]})
              } } text={"Aceptar"} btnClassname={"mb-2"}/>
            </div>
        </ModalS>
      </div>
    )
}

function numberWithCommas(x) {  
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return parts.join(".");
}


export default React.memo(ModalMultipleSuscripciones);