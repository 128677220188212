export const STEP = {
  login: 'login',
  methods: 'methods',
  creditCard: 'TDC',
  debito: 'debito',
  debitoAcount: 'debitoAcount',
  facturation: 'facturation',
  validateRandomDiscount: "validateRandomDiscount",
  sms: 'sms',
  response: 'response',
  responseDomiciliation: 'domiciliacionresponse',
  validateCode     : 'validateCode',
  admin            : 'adminSubscription',
  adminPayment     : 'adminPayment',
  adminSuscription : 'adminSuscription',
  adminHistoryPay  : 'adminHistoryPay',
  defineDuesCards  : 'defineDuesCards',
};
