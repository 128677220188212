import { gql } from 'apollo-boost';

export const FIND_TRANSACTION = gql`
  mutation FIND_TRANSACTION(
    $idTransaction: String!
  ) {
    findTransaction(
      idTransaction: $idTransaction
    )
    {
      status
      message
      transaction
    }
  }
`;
