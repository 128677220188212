export const SIZE = {
  small: 'small',
  big: 'big',
};

export const KIND = {
  input: 'onlyInput',
};


export const accountTypes = [
  {
    label:"Ahorros",
    value:"ahorros"
  },
  {
    label:"Corriente",
    value:"corriente"
  }
];

export const docTypes =[
  {
    label: 'Cédula de ciudadanía',
    value: 'CC',
  },
  {
    label: 'Pasaporte',
    value: 'PP',
  },
  {
    label: 'Cédula de extranjería',
    value: 'CE',
  },
];
