import React, { useState } from 'react';
import { Container } from './styles';
import Flex from './../Flex';
import { Collapse } from 'react-collapse';
import RounCheck from '../UI/roundCheck';

const CardWithSuscription = ({
                               mask,
                               franchise,
                               token,
                               seleccionaCard,
                               domiciliations,
                               activa
                             }) => {

  const [state, setState] = useState({
    collapse: false,
  });

  return (
    <Container>
      <Flex>
        {/*<Flex flex={'0 0 auto'}>*/}
        {/*  <button className={'p-2'}>*/}
        {/*    <i className="material-icons">delete_outline</i>*/}
        {/*  </button>*/}
        {/*</Flex>*/}
        <Flex flex={'0 0 auto'} className={"pl-3"}>
          <img src={`/img/cards/${franchise}.png`} alt="" width={'45px'} height={'auto'} className={'mr-1'}/>
          {'***' + mask.slice(-4)}
        </Flex>
        <Flex flex={'1 0 auto'}>
          <span className={'ver-detalle'} onClick={() => setState({ ...state, collapse: !state.collapse })}>
            <small>{state.collapse ? 'Ocultar detalles' : 'Ver detalles'}</small>
          </span>
        </Flex>
        <Flex flex={'0 0 40px'} className={'p-1'}>
          <RounCheck
            tooltip={"Seleccionar"}
            activo={activa}
            onClick={()=> {
              seleccionaCard(token,activa)
              if(state.collapse){
                setState({ ...state, collapse: false})
              }
            }}
          />
        </Flex>
      </Flex>
      <div className={'col-12 p-0'}>
        <Collapse isOpened={state.collapse}>
          <div className={'px-2 pb-2 '}>
            <small className={'mb-2'}><strong>Suscripciones asociadas:</strong></small>
            <div className={'col-12 px-0'}>
              {(domiciliations && domiciliations.length > 0) &&
              domiciliations.map((item, key) =>
                <small key={key} className={'d-block wc'}>- Domiciliación {item}</small>,
              )
              }
            </div>
          </div>
        </Collapse>
      </div>
    </Container>
  );
};

export default React.memo(CardWithSuscription);
