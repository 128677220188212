import styled from '@emotion/styled';

export const Container = styled.section`
  background: #f1f1f1;
  position:relative;
  z-index:0;
  width: 100%;
  height: 40px;
  flex:${props=> props.show ? "0 0 40px" : "0 0 0"};
  overflow:hidden;
  font-size:14px;
  line-height:40px;
  color:${props => props.color};

  .action{
    cursor:pointer;
    &:hover{
    .underline{
    text-decoration:underline;
    }
   }
  }`;
