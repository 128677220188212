import styled from '@emotion/styled';

export const Container = styled.button`
  width:100%;
  border:1px solid ${props => props.disabled ? "rgba(0, 0, 0, 0.1)" : "rgba(0, 0, 0, 0.36)"};
  height:50px;
  margin-bottom:-1px;
  cursor:pointer;
  position:relative;
  z-index:0;
  transition: all 500ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
  transform:translateZ(0);
  overflow:hidden;
  background:white;

  &:hover{
    border-left: 5px solid ${props => props.color ? props.color : "rgba(0, 0, 0, 0.36)" };
    z-index:2;

    .name,.material-icons{
     color:${props => props.color};
  }

}

  &:disabled{
    background: #f2f2f4;
    cursor:not-allowed;

    .franchise{
        filter: grayscale(50%);
    }

    &:hover{
      border-left: 1px solid rgba(0, 0, 0, 0.1);

        .name,.material-icons{
           color:#999999;
        }
    }
  }

  &:focus{
    outline:none;
  }

  .name{
    font-size:15px;
    color:#999999;
  }

  .material-icons{
    vertical-align:middle;
    color:#999999;
  }

  .franchise{
    max-height:30px;
    max-width:60px;
    height:auto;
    width:auto;
    display:block;
  }
 `;
