import styled from '@emotion/styled';

export const Container = styled('div')`
  width:40px;
  height:40px;

  button{
    width:100%;
    height:100%;
    background:transparent;
    color:${props =>props.color};
    padding:0;
    margin:0;
    cursor:pointer;
    border-radius:50%;
    font-size:35px;

    .material-icons{
      font-size:30px;
    }

    &:hover{
      background:rgba(0,0,0,.08);
    }

    &:disabled{
      cursor:not-allowed;
    }
  }
`;
