import { useStyletron } from 'baseui';
import React from 'react';
import useGlobal from "../../config/global"
import { Collapse } from 'react-collapse';
import { isString } from 'formik';
import styled from '@emotion/styled';

export function Before({ customIcon,icon,disabled,customColor,error,className }) {

  const [globalState] = useGlobal();

  const globalColor = "#" + globalState.commerce.colorLink;

  const [css] = useStyletron();

  let theColor = error ? "red" : (disabled ? '#cbcbcb' : ( customColor ? customColor: globalColor));

  return (
    <div
      className={`${className} ${css({
        display      : 'flex',
        alignItems   : 'center',
        fontZise     : "15px",
        width        : '30px',
        paddingRight : '8px',
        color        : theColor
      })}`}
    >
      {customIcon ?
        <i className={`icon-${customIcon}`}> </i>:
        <i className={"material-icons"}>{icon}</i>
      }
    </div>
  );
}



export const ShowError = ({error}) => {
  const ErrorColor = styled.small`color:red;`;

  return(
    <Collapse isOpened={isString(error)}>
      <ErrorColor className={'error'}>{error}</ErrorColor>
    </Collapse>
  )
};


