import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';

/**
 * Mutation
 */
import { DISCOUNT } from '../../../../data/mutations/payment';

/**
 * Global state
 */
import useGlobal from '../../../../config/global';

/**
 * Constast
 */
import { STEP } from '../../constants';
import Flex from '../../../Flex';
import Titlesection from '../../../TitleSection';
import Input from '../../../input';
import Termscond from '../../../TermsCond';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import './iconos.scss';
import { useParams } from 'react-router';
import { listConuntries } from '../../../helpers/listCountries';
import { SOLOLETRAS } from '../../../../util/rex';


const facturation_schema = Yup.object().shape({
  docType: Yup.array()
    .compact(function(v) {
      return (
        Object.entries(v).length === 0 && v.constructor === Object
      );
    })
    .required('Debe seleccionar un tipo de doc.'),
  docNumber: Yup.string()
    .min(5, 'Número no válido.')
    .max(10, 'Número no válido.')
    .required('Debe ingresar un número de documento.'),
  country: Yup.array()
    .compact(function(v) {
      return (Object.entries(v).length === 0 && v.constructor === Object);
    })
    .required('Debe seleccionar un páis.'),
  city: Yup.string()
    .required('Debe seleccionar una ciudad.')
    .min(4, 'Ciudad no válida'),
  address: Yup.string().required('Debe ingresar una dirección.'),
  terminos: Yup.bool().oneOf([true], 'Debe aceptar los términos y políticas.'),
});

function Facturation(props) {

  //get id transaction
  const { id } = useParams();

  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal();
  const [nextStep, setNextStep] = useState(null);
  const [response] = useState(null);
  const [documentType, setDocumentType] = useState('');
  const [documentNumber, setDocumentNumber] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');


  const [applyDiscount, { loading }] = useMutation(DISCOUNT, {
    onError: (error) => {
      console.log('ERROR :::::::', error);
      globalActions.setErrorAlert({
        icon: 'error',
        title: '¡Error!',
        message: [
          `Ocurrio un error al realizar el descuento`,
        ],
        content: false,
        hideButtons: false,
        btnText: 'Aceptar',
        btnCallback: () => {
          globalActions.setStep('methods');
          globalActions.setDirection(true);
        },
      });
      globalActions.setMainButton({
        ...globalState.mainButton,
        loading: false,
      });
    },

    onCompleted: ({ discount: rspn }) => {
      // console.log('RESPONSE:::::::', creditCard);

      globalActions.setErrorAlert({
        title: '',
        icon: '',
        message: [],
        content: 'false',
        hideButtons: false,
      });

      globalActions.setMainButton({
        ...globalState.mainButton,
        loading: false,
      });

      if (rspn.status && !rspn.error) {
        if (process.env.NODE_ENV === 'development') {
          console.group('Data discount response');
          console.log(
            `return false status on discount`,
          );
          console.groupEnd();
        }
        if (rspn.responseInfo && rspn.responseInfo.isRandomDiscountEnabled) {
          setNextStep(STEP.validateRandomDiscount);
        } else {
          setNextStep(STEP.sms);
        }
      } else {
        globalActions.setErrorAlert({
          icon: 'error',
          message: [
            rspn.message ||
            'Ocurrio un error al validar el descuento aleatorio',
          ],
          title: '¡Algo salió mal!',
          content: false,
          btnText: 'Aceptar',
          btnCallback: () => {
            globalActions.setStep('methods');
            globalActions.setDirection(true);
          },
        });
      }
    },
  });

  useEffect(() => {
    if (nextStep) {
      globalActions.setDataForm({
        ...globalState.dataForm,
        documentType,
        documentNumber,
        country,
        city,
        address,
      });
      globalActions.setStep(nextStep);
      globalActions.setDataResponse(response);
    }
  }, [
    globalActions,
    nextStep,
    response,
    documentType,
    documentNumber,
    country,
    city,
    address,
  ]);

  useEffect(() => {
    if (loading) {
      globalActions.setLoading(true);
    } else {
      globalActions.setLoading(false);
    }
  }, [loading]);

  let cOptions = new Array();

  const initialValues = {
    docType: '',
    docNumber: '',
    country: '',
    city: '',
    address: '',
    terminos: false,
  };

  if (globalState.isMultipleSubscriptions) {
    initialValues.docType = globalState.transaction.epaycoMultipleSubscriptions[0].typeDoc
      ? globalState.transaction.epaycoMultipleSubscriptions[0].typeDoc
      : '';

    initialValues.docNumber = globalState.transaction.epaycoMultipleSubscriptions[0].doc
      ? globalState.transaction.epaycoMultipleSubscriptions[0].doc
      : '';

    initialValues.country = globalState.transaction.epaycoMultipleSubscriptions[0].country
      ? globalState.transaction.epaycoMultipleSubscriptions[0].country
      : [{ label: 'Colombia', code: 'CO' }];

    initialValues.city = globalState.transaction.epaycoMultipleSubscriptions[0].department
      ? globalState.transaction.epaycoMultipleSubscriptions[0].department
      : '';

    initialValues.address = globalState.transaction.epaycoMultipleSubscriptions[0].address
      ? globalState.transaction.epaycoMultipleSubscriptions[0].address
      : '';
  } else {
    initialValues.docType = globalState.transaction.epaycoSubscription.typeDoc
      ? globalState.transaction.epaycoSubscription.typeDoc
      : '';

    initialValues.docNumber = globalState.transaction.epaycoSubscription.doc
      ? globalState.transaction.epaycoSubscription.doc
      : '';

    initialValues.country = globalState.transaction.epaycoSubscription.country
      ? globalState.transaction.epaycoSubscription.country
      : [{ label: 'Colombia', code: 'CO' }];

    initialValues.city = globalState.transaction.epaycoSubscription.department
      ? globalState.transaction.epaycoSubscription.department
      : '';

    initialValues.address = globalState.transaction.epaycoSubscription.address
      ? globalState.transaction.epaycoSubscription.address
      : '';
  }

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={facturation_schema}
        onSubmit={values => {
          setDocumentType(values.docType[0].value);
          setDocumentNumber(values.docNumber.toString().trim());
          setCountry(values.country[0].code);
          setCity(values.city);
          setAddress(values.address);

          applyDiscount({
            variables: {
              form: {
                token: globalState.dataForm.token,
                customer: {
                  name: globalState.dataForm.name,
                  lastName: globalState.dataForm.lastName,
                  phone: globalState.dataForm.phone,
                  email: globalState.email,
                },
                billing: {
                  documentType: values.docType[0].value,
                  documentNumber: values.docNumber,
                  country: values.country[0].code,
                  city: values.city,
                  address: values.address,
                  dues: globalState.dataForm.dues,
                },
                terminos: values.terminos,
              },
              type: globalState.type,
              idTransaction: id,
            },
          });
        }}
      >
        {({
            errors,
            touched,
            values,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
          <Form
            ref={props.factRef}
            style={{
              height: '100%',
              position: 'relative',
              zIndex: '0',
              display: 'flex',
              flexFlow: 'column wrap',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: '100%',
              flex: '1 0 auto',
            }}
            onSubmit={e => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Flex
              flex={'1 0 auto'}
              direction={'column'}
              jc={'flex-start'}
              className={'wc'}
            >
              <div className='wc px-4'>
                <Titlesection
                  text={'Ingrese los datos de facturación'}
                />
              </div>
              <div className='wc px-4 '>
                <Flex
                  flex={'1 0 100%'}
                  className={'pb-3'}
                  alg={'flex-start'}
                >
                  <div className='col-5 px-0'>
                    <Input
                      tipo={'select'}
                      type='text'
                      onChange={handleChange}
                      value={values.docType}
                      label='Tipo de Doc.'
                      options={'docTypes'}
                      labelOption={'label'}
                      valueOption={'value'}
                      name={'docType'}
                      customLabel={'value'}
                      error={
                        errors.docType &&
                        touched.docType &&
                        errors.docType
                      }
                      mw={true}
                      icon={'account_box'}
                      customIcon={'id-type'}
                    />
                  </div>
                  <div className='col-7 px-0'>
                    <Input
                      className={'pl-4'}
                      tipo={'text'}
                      type='number'
                      onChange={handleChange}
                      value={values.docNumber}
                      label='Número de documento'
                      name={'docNumber'}
                      error={
                        errors.docNumber &&
                        touched.docNumber &&
                        errors.docNumber
                      }
                      customIcon={'id-number'}
                    />
                  </div>
                </Flex>
                <Flex
                  flex={'1 0 100%'}
                  className={'pb-3'}
                  alg={'flex-start'}
                >
                  <div className='col-5 px-0'>
                    <Input
                      tipo={'select'}
                      type='text'
                      onChange={handleChange}
                      value={values.country}
                      label={'País'}
                      name={'country'}
                      error={
                        errors.country &&
                        touched.country &&
                        errors.country
                      }
                      options={listConuntries}
                      labelOption={'name'}
                      valueOption={'code'}
                      customLabel={'code'}
                      mw={true}
                      customIcon={'planet'}
                    />
                  </div>
                  <div className='col-7 px-0'>
                    <Input
                      type='text'
                      onChange={(e) => {
                        if (SOLOLETRAS.test(e.target.value)) {
                          handleChange(e);
                        }
                      }}
                      value={values.city}
                      label={'Ciudad'}
                      name={'city'}
                      error={
                        errors.city && touched.city && errors.city
                      }
                      className={'pl-4'}
                      customIcon={'flag'}
                    />
                  </div>
                </Flex>
                <div className={'pb-3 wc'}>
                  <Input
                    type='text'
                    onChange={handleChange}
                    value={values.address}
                    label={'Dirección'}
                    name={'address'}
                    error={
                      errors.address &&
                      touched.address &&
                      errors.address
                    }
                    customIcon={'building'}
                  />
                </div>
              </div>
            </Flex>
            <Flex className={'wc text-center'} flex={'0 0 auto'}>
              <Termscond
                onChange={e => setFieldValue('terminos', e)}
                checked={values.terminos}
                error={errors.terminos}
              />
            </Flex>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
}

Facturation.propTypes = {};

Facturation.defaultProps = {};

export default Facturation;
