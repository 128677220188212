import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

/**
 * Styles
 */
import { Container } from './styles';

/**
 * Util
 */
import { getCorrectTextColor } from '../getContrastColor';

/**
 * Global
 */
import useGlobal from '../../config/global';
import { animated, useTransition } from 'react-spring';

function Mainbutton() {

  const [globalState] = useGlobal();
  let  muestrate = globalState.mainButton.show;
  let  loading = globalState.mainButton.loading;

  let transition = useTransition(muestrate,k=> k,{
    config :{ mass: 1, tension: 350, friction: 30 },
    from : {transform: 'translateY(200%)' },
    enter: {transform: 'translateY(0)' },
    leave: { transform: 'translateY(100%)' },
  });

  return (
    <Container
      className="MainButton"
      color={'#' + globalState.commerce.colorLogo}
      colortext={getCorrectTextColor('#' +  globalState.commerce.colorLogo)}
      active={  muestrate ? '50px' : 0}
    >
      {transition.map(({item,props,key})=> item &&
        <animated.div key={key} style={{...props,width:"100%",height:"100%"}}>
          <button
            className={'saving'}
            onClick={ globalState.mainButton.onClick ? globalState.mainButton.onClick : undefined }
            disabled={loading }
            type={'submit'}
          >
            {loading ?
              <Fragment>
                Procesando
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </Fragment>:
              globalState.mainButton.text
            }
          </button>
        </animated.div>
      )}
    </Container>
  );
}

Mainbutton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  showBtn: PropTypes.bool,
};

Mainbutton.defaultProps = {
  text: '',
};

export default React.memo(Mainbutton);
