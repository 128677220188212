import styled from '@emotion/styled';

export const Container = styled.div`

  background: linear-gradient(354deg, ${props=> props.gradientB} 0%, ${props=> props.gradientA} 100%);
  position: absolute;
  top:0;
  left:0;
  z-index: 0;
  transform: translateZ(0);
  height:100%;
  width:100%; 
  
  .curvas{
    height:100%;
    width:100%; 
    background: transparent url(/img/curvas.png) no-repeat center;
    background-size:cover;  
    opacity:.8;
    filter:blur(5px)
  }
 
  .fr{
      height: 50vh;
      width: 50vh;
      border-radius: 60%;
      filter: blur(50px);
      background-color: ${props=> props.gradientA} ;
      position: absolute;
    transform-origin: 10% 25%;
    animation: girarMancha 15s linear infinite reverse;
    top:50%;
    left: 50%;

    .light{
      width: 50%;
      height: 50%;
      background-color: rgba(255,255,255,.8);
      animation: crece 10s linear infinite;
    }
  }

  .fr1{
    top:5%;
    left: 15%;
    transform-origin: 10% 55%;
    animation: girarMancha 10s linear infinite;
  }

  .fr2{
    top:45%;
    left: 35%;
    transform-origin: 60% 5%;
    animation: girarMancha 25s linear infinite alternate;
  }

  .fr4{
    top:5%;
    left: 75%;
    transform-origin: 60% 5%;
    animation: girarMancha 12s linear infinite;
  }

  .fr5{
    top:80%;
    left: 85%;
    transform-origin: 60% 5%;
    animation: girarMancha 12s linear infinite alternate;
  }
`;
