import styled from '@emotion/styled';
import Flex from '../../Flex';

export const Container = styled(Flex)`
  width:100%;
  height:100%;
  
  
  .back{
    color:${props => props.color};
    font-weight:700;
    cursor:pointer;
    
    &:hover{
      text-decoration:underline;
    }
  }
`;
