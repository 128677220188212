import useGlobal from '../../config/global';
import Flex from '../Flex';
import React from 'react';

const Back = ({ elimina, cancel }) => {

  const [globalActions] = useGlobal();

  return (
    <Flex className={'wc p-2 confirm'}>
      <Flex flex={'1 0 100px'} jc={'flex-start'}>
        <span className={'texto'}>¿Desea eliminar el medio de pago?</span>
      </Flex>
      <Flex flex={'0 0 134px'}>
        <Flex className={'pl-1'}>
          <button
            onClick={() => {
              cancel();
              // globalActions.lockDrag(false);
            }}
            className={'cancel'}
          >
            Cancelar
          </button>
        </Flex>
        <Flex className={'pl-2'}>
          <button onClick={() => elimina()} className={'elimina'}>
            Eliminar
          </button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default React.memo(Back);
