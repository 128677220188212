import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import {useTransition} from 'react-spring';

function Overlay({ children, isOverlay, background, show}) {
  const [state,setsatte] = useState({
    conteo:0,
    entro:false
  });
  let transitions = useTransition(show, k => k, {
    // config:{mass:1,tension:100,friction:30},
    from: {
      clipPath: 'circle( 0% at 50% 50%)',
      opacity: 0,
    },
    enter: {
      clipPath: 'circle( 100% at 50% 50%)',
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
  });
  useEffect(()=> {
    if(show){
      setsatte({...state,entro:true});
    }
  },[])

  if (!isOverlay) return null;
  if (show === undefined) return null;

  return (
    transitions.map(({item,props,key})=> item &&
      <Container
        key={key}
        style={{ backgroundColor:background,...props}}
      >
        {children}
      </Container>
    )
  );
}

Overlay.propTypes = {
  isOverlay: PropTypes.bool,
  background: PropTypes.string,
  children: PropTypes.node,
};

Overlay.defaultProps = {
  isOverlay: true,
  background: 'transparent',
};

export default React.memo(Overlay);
