import axios from "axios";

// get subscribed reference
export const getRefSubscribed = async (data)=> {

    try{
      const response = await axios
      .post(
        `${process.env.REACT_APP_URL_RECAUDO}/api/suscripciones/domiciliacion/consulta/suscripcion`,
        data, {
        timeout: 60000,
      })
      .then(response => {
        return response;
      })
      .catch(err => {
        return err;
      });

      if (response.data) {
        if (response.data.success && response.data.data !== null) {
          var parametersConsult = JSON.parse(response.data.data.parametros_consulta);
          var parameters = {
            refSubscribed: (typeof(parametersConsult.referenciaSuscrita) !== 'undefined') ? parametersConsult.referenciaSuscrita :'',
            refPayment: (typeof(parametersConsult.identificador) !== 'undefined') ? parametersConsult.identificador : ''
          };
          return parameters;
        }
      }
    }
    catch (e) {
      console.log('Error al intentar obtener la referencia suscrita.');
      return null;
    }

    return null;
}
