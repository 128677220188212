import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';

const cache = new InMemoryCache();
const link = new HttpLink({
  uri: '/graphql',
});

const client = new ApolloClient({
	cache,
	link,
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'network-only',
		},
	}
});

export default client;
