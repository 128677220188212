import React from 'react';
import styled from '@emotion/styled';
import useGlobal from '../../../config/global';
import {
  Checkbox,
} from "baseui/checkbox";

const Container = styled.div`
    position:relative;
    width:100%;
`;

function Check({onChange,checked,error,disabled}) {

  const [globalState] = useGlobal();
  let color = "#"+globalState.commerce.colorLogo;

  return (
    <Container>
      <Checkbox
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        overrides={{
          Checkmark: {
            style: ({ $checked }) => {
              return {
                borderColor: error ? "red" :  disabled ? "#cbcbcb" : color,
                backgroundColor: checked ? (disabled ? "#cbcbcb" : color)  : "transparent",
                color: "white"
              };
            }
          },
        }}
      />
    </Container>
  );
}

export default React.memo(Check);
