import styled from '@emotion/styled';

export const Container = styled.div`
  width:100%;
  height:auto;
  font-weight:bold;

  button{
    width:100%;
    height:auto;
    border-radius:${props=> props.br ? props.br : "4px"};
    text-center:center;
    color:${props => ( props.type === "b" ||  props.type === "c") ? props.color : props.colorText};
    cursor:pointer;
    font-size:15px;
    min-height:40px;
    background:${props =>  props.type === "a" ? props.color :( props.type === "b" ? "white" : "transparent") };
    border: 2px solid ${props =>( props.type === "b" ||  props.type === "a") ? props.color  : "transparent" };
    transform:scale(1);
    transition: all 500ms cubic-bezier(0.190, 1.000, 0.220, 1.000);
    box-shadow: ${props => props.type === "c" ? "none" : "0 2px 3px rgba(0,0,0,.1)"};

    &:hover{
        text-decoration: ${props =>  props.type === "c" ? "underline" : "none"};
        transform:scale(0.98);
    }

    .material-icons{
      vertical-align:middle;
    }

    &:focus{
      outline:none;
    }

    &:disabled{
       background:#e4e4e4;
       border: 2px solid transparent};
    }


  }
`;


export const IconBtn = styled.div`
  width:35px;
  height:35px;
  cursor:pointer;
  color:${props=> props.color};

 button{
  width:35px;
  height:35px;
  border-radius:50%;
  cursor:pointer;
  color:${props=> props.type === "a" ? props.colorText : props.color};
  border:2px solid ${props=> props.type === "a" ? props.color : "#e4e4e4"};
  text-align:center;
  padding:0!important;
  line-height:32px;
  background: ${props => props.noBg ? "transparent" : (props.type === "a" ? props.color : "transparent" ) };


  &:hover{
    background: ${props => props.type ==="a" ? props.color :"rgba(0,0,0,.15)"};
    border-color:${props => props.borderColor};
  }

  &:disabled{
    box-shadow: none;
    cursor:not-allowed;
    background-color:rgba(0,0,0,.1);
    color:#999999;
    border-color:transparent;

    &:hover{
      border-color:transparent;
       background-color:rgba(0,0,0,.1);
    }
  }

  & > *{
   pointer-events: none;
  }


  .material-icons{
      vertical-align:middle;
      height:20ox;
      font-size:20px;
      padding:0;
  }




 }

 @media all and (max-width: 375px){
  width: 25px;
  height:25px;

   button{
    width:25px;
    height:25px;
    line-height:20px;

      .material-icons{
      font-size:14px;
      }
   }
 }
`;

export const Spanlink = styled.button`
  color: ${props => props.disabled ? "#cbcbcb" : props.color};
  cursor:${props => props.disabled ? "not-allowed" : "pointer"};
  background:none;
  padding:0;
  margin:0;

  &:hover{
    text-decoration:${props => props.disabled ? "none" :  "underline"};
  }

  .material-icons{
    font-size:16px;
    height:16px;
    vertical-align:middle;
  }
`;
