import styled from '@emotion/styled';
import {animated} from 'react-spring';

export const Container = styled(animated.div)`
  width: 100%;
  height:100%;
  display:flex;
  flex-flow:column nowrap;
  justify-content:flex-center;
  align-items:center;
  overflow:hidden;
  box-shadow:0 3px 10px rgba(0,0,0,.2);
  z-index:2;
  background:white;
  backface: hidden;
  position:relative;
  will-change:transform;
  
  
  .contScroll{
     position:relative;
     z-index:1;
     transform:translateZ(0);
     overflow:hidden;
     flex: 1 0 150px;
     width:100%;
     background:white;
  }
  
 
  @media all and (max-width:680px) and (max-height:600px) {
    justify-content:flex-start;
    overflow: hidden;
  }
  
   @media all and (min-width:768px){
    max-width:400px;
    height:600px;
    max-height:calc(100vh - 80px);
   }
   
   @media all and (max-height:360px){
   overflow: hidden auto;
    .contScroll{
        flex: 1 0 auto;
      }
   }
 
`;


