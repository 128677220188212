import { gql } from 'apollo-boost';

export const CREDIT_CARD = gql`
  mutation CREDIT_CARD(
    $form: JSON!
    $idTransaction: MongoObjectId!
    $type: String
  ) {
    creditCard(
      form: $form
      idTransaction: $idTransaction
      type: $type
    ) {
      status
      message
      error {
        path
        message
      }
      payment {
        token
      }
    }
  }
`;

export const DISCOUNT = gql`
  mutation DISCOUNT(
    $form: JSON!
    $idTransaction: MongoObjectId!
    $type: String
  ) {
    discount(
      form: $form
      idTransaction: $idTransaction
      type: $type
    ) {
      status
      message
      responseInfo
      error {
        path
        message
      }
    }
  }
`;

export const VALIDATE_RANDOM_DISCOUNT = gql`
  mutation VALIDATE_RANDOM_DISCOUNT(
    $randomDiscountValue: String!
    $token: String!
  ) {
    validateRandomDiscount(
      randomDiscountValue: $randomDiscountValue
      token: $token
    ) {
      status
      message
      responseInfo
      error {
        path
        message
      }
    }
  }
`;

export const FACTURATION = gql`
  mutation FACTURATION(
    $form: JSON!
    $type: String
    $idTransaction: MongoObjectId!
  ) {
    facturation(
      form: $form
      type: $type
      idTransaction: $idTransaction
    ) {
      error {
        path
        message
      }
      payment {
        data
      }
      responseInfo
    }
  }
`;

export const SEND_SMS = gql`
  mutation SEND_SMS(
    $type: String!
    $phone: String!
    $indicative: String!
    $idTransaction: MongoObjectId!
  ) {
    sms(
      type: $type
      phone: $phone
      indicative: $indicative
      idTransaction: $idTransaction
    ) {
      error {
        path
        message
      }
      payment {
        success
      }
    }
  }
`;

export const SMS_VERIFICATION = gql`
  mutation SMS_VERIFICATION(
    $code: String!
    $idTransaction: MongoObjectId!
  ) {
    smsVerification(code: $code, idTransaction: $idTransaction) {
      status
      message
      error {
        path
        message
      }
      payment {
        success
      }
    }
  }
`;

export const SUBSCRIPTION = gql`
  mutation SUBSCRIPTION(
    $form: JSON!
    $idTransaction: MongoObjectId!
    $type: String
  ) {
    subscription(
      form: $form
      idTransaction: $idTransaction
      type: $type
    ) {
      error {
        path
        message
      }
      payment {
        data
      }
    }
  }
`;

export const GET_IMAGES = gql`
  mutation GET_IMAGES($idTransaction: MongoObjectId!, $url: String!) {
    getImages(idTransaction: $idTransaction, url: $url) {
      bufferLogo
      bufferEpayco
    }
  }
`;

export const GET_COLOR = gql`
  mutation GET_COLOR($idTransaction: MongoObjectId!) {
    getColor(idTransaction: $idTransaction) {
      status
      color
    }
  }
`;

export const REMOVE_MP = gql`
  mutation CONFIRM_REMOVE($token: MongoObjectId!) {
    confirmRemoveWithLinkOneClick(token: $token) {
      error {
        message
        path
      }
      response
    }
  }
`;
