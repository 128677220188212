import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';

/**
 * Global
 */
import useGlobal from '../../.././../config/global';

/**
 * Components
 */
import Loginform from '../../../loginForm';

/**
 * Styles
 */
import { WrapperStep } from '../styles';

function Methods({ transaction }) {
  const { id } = useParams();
  const [globalState,globalActions] = useGlobal();

  let ID = useMemo(() => id, []);
  let Transaction = useMemo(() => transaction, [transaction]);

  useEffect(()=>{
    globalActions.closeSession(false)
    globalActions.updateState({
      cardsWithsuscriptions:[]
    })
  },[])
  return (
    <WrapperStep
      className="methods wc px-3 px-sm-4 "
      isAnimating={globalState.isAnimating}
    >
      <Loginform idTransaction={ID} transaction={Transaction} />
    </WrapperStep>
  );
}

Methods.propTypes = {};

Methods.defaultProps = {};

export default React.memo(Methods);
