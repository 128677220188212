import React from 'react';
import PropTypes from 'prop-types';

/**
 * Styles
 */
import { Container } from './styles';
import Flex from '../Flex';
import useGlobal from '../../config/global';
import { Spring } from 'react-spring/renderprops';
import { config } from 'react-spring';
import { STEP } from '../step';

function Migas() {
  const [globalState, globalActions] = useGlobal();

  let { show,icon,iconName } = globalState.migasUp;

  let ControlMiga = () => {
    globalActions.setDirection(true);
    let MG = [...globalState.migas].map((item, index) => {
      return { id: index, name: item };
    });

    let posicion = MG.filter(item => item.name === STEP.methods)[0].id;
    let nuevoArreglo = [...globalState.migas].slice(0, Number(posicion));

    globalActions.setMiga(nuevoArreglo);
    globalActions.setStep('methods');
  };

  return (
    <Container
      className="migas"
      show={show}
      color={globalState.loading ? "#999999":"#40A8E6"}
    >
      <Spring
        config={config.stiff}
        from={{ transform: 'translateY(-100%)' }}
        to={{ transform: show ? 'translateY(0)' : 'translateY(-100%)' }}>
        {props =>
          <Flex
            flex={`0 0 ${show ? '40px' : '0'}`}
            style={{ background: '#f1f1f1', overflow: 'hidden', ...props }}
            className={'wc migas'}
          >
            <Flex
              flex={'1 0 100px'}
              jc={'flex-start'}
              className={'px-3 action'}
              onClick={() => ControlMiga()}
            >
              <i className={'material-icons'}>navigate_before</i>
              <span className={"underline"}>Cambiar medio de pago</span>
            </Flex>
            <Flex flex={'0 0 40px'} className={'px-3'}>
              {icon ? <i className={'material-icons'}> {iconName} </i>:
                <img src={`/img/${iconName}.png`} alt="" height={"20px"} width={"auto"}/>}
            </Flex>
          </Flex>}
      </Spring>
    </Container>
  );
}

//
Migas.propTypes = {
  show: PropTypes.bool.isRequired,
  texto: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
};

Migas.defaultProps = {
  show: false,
  texto: '',
  img: '',
};

export default React.memo(Migas);
