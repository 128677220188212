import React from 'react';
import PropTypes from 'prop-types';
import Flex from '../Flex';

/**
 * Styles
 */
import { Container } from './styles';
import useGlobal from '../../config/global';

function Titlesection({ text, backtext}) {

  const [globalState, globalActions] = useGlobal();
  let GlobalColor = "#" + globalState.commerce.colorLink;




  const back = () =>{
    globalActions.setDirection(true);
    let trg = [...globalState.migas].slice(-2)[0];
    let m = [...globalState.migas];
     m.pop();
     m.pop();
    globalActions.setMiga(m);
    globalActions.setStep(trg);
  };

  return (
    <Container className={"TitleSection"} color={GlobalColor}>
      <Flex flex={"1 0 70%"} jc={"flex-start"}>
        <p className={"mb-0"}>{text}</p>
      </Flex>
      {back &&
      <Flex flex={"0 1 auto"} className={"back"}>
        {<span onClick={()=>back()}>{backtext}</span>}
      </Flex>
      }
    </Container>
  );
}

Titlesection.propTypes = {
  text: PropTypes.string.isRequired,
};

Titlesection.defaultProps = {
  text: '',
};

export default React.memo(Titlesection);
