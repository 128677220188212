import accounting from 'accounting';
import moment from 'moment';

export const FormatMoney = (amount = 0) =>
  accounting.formatMoney(parseInt(amount));

export const Upercase = (string = '') => string.toUpperCase();

export const FormatFranchise = (name = '') => {
  name = name.toLocaleLowerCase();
  switch (name) {
    case 'visa':
      return 'VS';
    case 'mastercard':
      return 'MC';
    case 'diners':
      return 'DC';
    case 'amex':
      return 'AM';
    case 'efecty':
      return 'EF';
    case 'baloto':
      return 'BA';
    case 'gana':
      return 'GA';
    case 'puntored':
      return 'PR';
    case 'redservi':
      return 'RS';
    default:
      return 'N/A';
  }
};

export const FormatDate = (
  date = new date(),
  format = 'MMMM Do YYYY'
) => moment(date).format(format);
