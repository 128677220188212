import { gql } from 'apollo-boost';

export const REMOVE_CARD = gql`
  mutation CONFIRM_REMOVE($token: MongoObjectId!) {
    confirmRemoveWithLinkOneClick(token: $token) {
      error {
        message
        path
      }
      response
    }
  }
`;

export const GET_CREDIT_CARD = gql`
  mutation GET_CREDIT_CARD($id: MongoObjectId!) {
    getCreditCard(_id: $id) {
      metadata {
        mask
      }
    }
  }
`;

export const SET_DEFAULT_CARD = gql`
  mutation SET_DEFAULT_CARD($email: String!, $defaultCard: JSON!) {
    setDefaultCard(email: $email, defaultCard: $defaultCard) {
      error {
        message
        path
      }
      response
    }
  }
`;

export const SEND_LINK_REMOVE = gql`
  mutation LINK_REMOVE(
    $email: String!
    $idCard: MongoObjectId!
    $idTransaction: String!
    $nameComerce: String!
    $emailComerce: String!
    $transactionId: String!
  ) {
    removeWithLinkOneClick(
      email: $email
      idCard: $idCard
      idTransaction: $idTransaction
      nameComerce: $nameComerce
      emailComerce: $emailComerce
      transactionId: $transactionId
    ) {
      error {
        message
        path
      }
      response
    }
  }
`;
