import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { animated, useTransition } from 'react-spring';
import { config } from 'react-spring';
import { useMutation } from '@apollo/react-hooks';

/**
 * Components
 */
import OneClick from './oneClick';

/**
 * Styles
 */
import { Container } from './styles';

/**
 * Global
 */
import useGlobal from '../../config/global';

/**
 * Mutation
 */
import { SEND_LINK_REMOVE } from '../../data/mutations/credit-card';
import { STEP } from '../step';
import Loading from '../loading';
import FrontDrag from './frontDrag';
import Back from './backCard';

function Cardsaved({
  id,
  position,
  selected,
  number,
  franchise,
  dues,
  onChange,
  orden,
  collapse,
  colorT,
  eliminaCard,
  token,
  saveDues,
  lokCollapseToggle,
  accountType,
  isDebit
}) {
  const [globalState, globalActions] = useGlobal();
  const [confirm, setConfirm] = useState(false);
  const [shadow, setShadow] = useState(false);

  const admin = globalState.step === STEP.adminPayment;
  //Mutation hook
  const [sendLinkRemove] = useMutation(SEND_LINK_REMOVE);

  let Transitions = useTransition(confirm, (key) => key, {
    config: config.wobbly,
    from: {
      opacity: 0,
      transform: confirm
        ? 'translate3d(0,0,0) rotate3d(1,0,0,180deg)'
        : 'translate3d(0,0,0) rotate3d(1,0,0,-180deg)',
    },
    enter: {
      opacity: 1,
      transform: 'translate3d(0,0,0) rotate3d(1,0,0,0)',
    },
    leave: {
      opacity: 0,
      position: 'absolute',
      transform: confirm
        ? 'translate3d(0,0,0) rotate3d(1,0,0,-180deg)'
        : 'translate3d(0,0,0) rotate3d(1,0,0,180deg)',
    },

    onDestroyed: () => setShadow(true),
  });

  let sections = useMemo(() => {
    return {
      front: () => {
        if (admin) {
          return (
            <FrontDrag
              token={token}
              selected={selected}
              number={number}
              franchise={franchise}
              dues={dues}
              accountType={accountType}
              confirm={() => {
                setConfirm(true);
                // globalActions.lockDrag(true);
              }}
              onChange={onChange}
              saveDues={saveDues}
              lokCollapseToggle={lokCollapseToggle}
            />
          );
        } else {
          return (
            <OneClick
              lokCollapseToggle={lokCollapseToggle}
              id={id}
              selected={selected}
              number={number}
              franchise={franchise}
              dues={dues}
              accountType={accountType}
              confirm={() => {
                setConfirm(true);
                // globalActions.lockDrag(true);
              }}
              onChange={onChange}
              collapse={collapse}
              isDebit={isDebit}
              token={token}
            />
          );
        }
      },
      back: () => (
        <Back
          elimina={() => {
            globalActions.setErrorAlert({
              message: [''],
              content: () => (
                <Loading texto={'Procesando...'} show={true} />
              ),
              hideButtons: true,
            });

            sendLinkRemove({
              variables: {
                email: globalState.email,
                idCard: token,
                idTransaction: `${globalState.subscription.suscripcionInfo.id}`,
                nameComerce: globalState.commerce.comercio,
                emailComerce: globalState.commerce.email,
                transactionId: globalState.idTransaction,
              },
            })
              .then(() => {
                setConfirm(false);
                eliminaCard();
                globalActions.setIsUpdate(true);
              })
              .catch((error) => console.log('error', error));
          }}
          cancel={() => {
            setConfirm(false);
            lokCollapseToggle(false);
          }}
          number={number}
          dues={dues}
          franchise={franchise}
        />
      ),
    };
  }, [dues,accountType, orden, number, franchise, position, collapse]);

  useEffect(() => {
    setShadow(!shadow);
  }, [confirm]);

  return (
    <Container
      color={'#' + globalState.commerce.colorLogo}
      index={orden}
      opened={collapse}
      position={position}
      colorT={colorT}
      desabilitado={globalState.lockDrag}
      selected={selected ? 'si' : 'no'}
      onclick={globalState.step === STEP.methods}
      shadow={
        shadow
          ? '0 1px 3px rgba(0,0,0,.2)'
          : '0 1px 3px rgba(0,0,0,0)'
      }
    >
      {Transitions.map(({ item, props, key }) => {
        let Section = item ? sections.back : sections.front;
        return (
          <animated.div key={key} style={props}>
            <Section />
          </animated.div>
        );
      })}
    </Container>
  );
}

Cardsaved.propTypes = {
  selected: PropTypes.bool,
  number: PropTypes.string,
  franchise: PropTypes.string,
  accountType: PropTypes.string,
  dues: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Cardsaved.defaultProps = {
  selected: false,
  number: '****8596',
  franchise: 'vs',
  dues: '12',
  accountType: 'corriente'
};

export default React.memo(Cardsaved);
