import Flex from '../Flex';
import React, { Fragment } from 'react';
import { sortableElement } from 'react-sortable-hoc';
import Cardsaved from '../CardSaved';

export const ResponseOrdenDues = ({ type,content }) => {
  return (
    <Flex className={'py-4 wc'} direction={'column'}>
      <img
        src="https://multimedia-epayco.s3.amazonaws.com/dashboard/modal_icons/check.png"
        alt=""
        width={'70px'}
        height={'auto'}
        className={'mb-3'}
      />
      {type === 'orden' ?
        <Fragment>
          <b style={{ fontSize: '18px' }} className={'mb-0 d-block mb-3'}>
            Nuevo orden de pago guardado con éxito
          </b>
          <p className={"mb-0 cg"}>El nuevo orden de pago se ha guardado con éxito, recuerde que el orden de intentos de pagos para los medios de pagos es de arriba hacia abajo.</p>
          <br></br>
          <br></br>
          
        </Fragment>
        :
        <Fragment>
          {content()}
        </Fragment>
      }
    </Flex>
  );
};

export function array_move(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
}

export const SortableItem = sortableElement(props => {
  return (
    <Cardsaved
      {...props}
      index={props.index}
      disabled={props.disabled}
    />
  );
});
