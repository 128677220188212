import styled from '@emotion/styled';
import Color from '@storybook/addon-knobs/dist/components/types/Color';

export const Container = styled.div`
  background:#e1f5fe;
  border:2px solid #81d4fa;
  width: 100%;
  border-radius:5px;
  position:relative;
  z-index:0;
  overflow:hidden;
  transform:translateZ(0);
    
  .description{
    color:black;
    font-size:12px;
    line-height:1.4em;
    display:block;
    padding:3px 0;
  }
  
  .longSide{
    max-width:280px;
  }
  
  .brd-l{ 
     border-radius: 5px 0 0 5px;
     overflow:hidden;
     background:white;
     height:40px;
    }
  .brd-r{ 
     border-radius: 0 5px 5px 0;
     overflow:hidden;
     background:white;
    } 
  
  .cont-phone{
    border-radius:5px;
    overflow:hidden;
  }
  
  .error{
    font-size:13px;
    color:red;
    display:block;
    
    &:last-child{
      margin-bottom:5px;
    }
  }
`;
