import styled from '@emotion/styled';

export const Container = styled.div`
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:2;
  display:flex;
  flex-flow:column nowrap;
  justify-content:center;
  align-items:center;
  transform-style:preserve-3d;
  perspective:1000px;
  
  
`;
