import React, { useEffect, useMemo, useState } from 'react';
import {
  Spring,
} from 'react-spring/renderprops-universal';
import { useMutation } from '@apollo/react-hooks';

/**
 * Components
 */
import Flex from '../Flex';
import { STEP } from '../step';

/**
 * Global
 */
import useGlobal from '../../config/global';

/**
 * Mutation
 */
import { ONECLIK_TO_DOMICILIATION } from '../../data/mutations/customer';
import { BtnIcon } from '../BtnT/BtnT';
import { Tooltip } from 'react-tippy';

const OneClick = ({
  id,
  selected,
  number,
  franchise,
  onChange,
  confirm,
  collapse,
  accountType,
  setSelect,
  continuar,
  guardada,
  lokCollapseToggle,
  isDebit,
  token
}) => {
  const [globalState, globalActions] = useGlobal();
  const [nextStep, setNextStep] = useState(null);
  const [select, SetSelect] = useState({
    showDues: false,
    dues: '0',
  });

  //Mutation hook
  const [oneclickDomiciliation] = useMutation(
    ONECLIK_TO_DOMICILIATION,
    {
      onError: (error) => {
        if (error.message.includes('card exist in domiciliation')) {
          globalActions.setErrorAlert({
            message: '',
            content: () =>
              'Este medio de pago ya está asociado a la domiciliación',
          });
        }
      },
      onCompleted: (response) => {
        globalActions.setCardsSubscription(
          response.oneClickToDomiciliation
        );
        setNextStep(STEP.admin);
      },
    }
  );

  let actualizaSelect = (x) =>
    SetSelect(Object.assign({}, select, x));
  let showD = useMemo(() => select.showDues, [select.showDues]);

  // useEffect(() => {
  //   if (dues) {
  //     actualizaSelect({ dues: dues });
  //   }
  // }, []);

  useEffect(() => {
    if (nextStep) {
      globalActions.setStep(nextStep);
    }
  }, [nextStep]);

  useEffect(()=>{ 
    isDebit && actualizaSelect({ dues: 1})
  },[isDebit])
  
  return (
    <Flex className={'wc forma position-relative'} alg={'stretch'}>
      <Flex flex={'0 0 10%'}>
        {!selected && !isDebit && (
          <Spring
            from={{ opacity: 0 }}
            to={{ opacity: collapse ? 1 : 0 }}
          >
            {(props) => (
              <button
                className={'wc delete text-center'}
                onClick={() => {
                  confirm();
                  lokCollapseToggle(true);
                }}
                style={props}
                disabled={!collapse || globalState.lockDrag}
              >
                <span>
                  <i className="material-icons">delete_outline</i>
                </span>
              </button>
            )}
          </Spring>
        )}
      </Flex>

      <Flex
        flex={'1 0 28%'}
        jc={'flex-start'}
        className={'franchise'}
      >
        <Flex flex={'0 0 30px'} className={'mr-1'}>
          <img src={`/img/cards/${franchise}.png`} alt="" />
        </Flex>
        <p className={'mb-0'}>****{number.slice(-4)}</p>
      </Flex>
      {collapse && showD && !isDebit && (
        <Flex flex={'0 0 80px'} className={'cuotas '}>
          <select
            value={select.dues}
            onChange={(e) =>
              actualizaSelect({ dues: e.target.value })
            }
          >
            <option value="0" disabled defaultValue>
              Cuotas
            </option>
            <option value="1">1 cuota</option>
            <option value="2">2 cuotas</option>
            <option value="3">3 cuotas</option>
            <option value="4">4 cuotas</option>
            <option value="5">5 cuotas</option>
            <option value="6">6 cuotas</option>
            <option value="7">7 cuotas</option>
            <option value="8">8 cuotas</option>
            <option value="9">9 cuotas</option>
            <option value="10">10 cuotas</option>
            <option value="11">11 cuotas</option>
            <option value="12">12 cuotas</option>
            <option value="13">13 cuotas</option>
            <option value="14">14 cuotas</option>
            <option value="15">15 cuotas</option>
            <option value="16">16 cuotas</option>
            <option value="17">17 cuotas</option>
            <option value="18">18 cuotas</option>
            <option value="19">19 cuotas</option>
            <option value="20">20 cuotas</option>
            <option value="21">21 cuotas</option>
            <option value="22">22 cuotas</option>
            <option value="23">23 cuotas</option>
            <option value="24">24 cuotas</option>
            <option value="25">25 cuotas</option>
            <option value="26">26 cuotas</option>
            <option value="27">27 cuotas</option>
            <option value="28">28 cuotas</option>
            <option value="29">29 cuotas</option>
            <option value="30">30 cuotas</option>
            <option value="31">31 cuotas</option>
            <option value="32">32 cuotas</option>
          </select>
        </Flex>
      )}
      <Flex flex={`1 0 ${showD ? '15%' : '10%'}`} className={'pl-2'}>
        {collapse &&
          (showD ? (
            guardada ? (
              <i className={'material-icons'}>check</i>
            ) : (
              <Flex jc={'flex-end'}>
                <BtnIcon
                  noBg
                  icon={'close'}
                  onClick={() => {
                    lokCollapseToggle(false);
                    actualizaSelect({
                      dues: '0',
                      showDues: false,
                    });
                  }}
                />
                <Tooltip
                  title={'Debe seleccionar un número de cuotas'}
                  position={'bottom'}
                  trigger={'mouseenter'}
                  arrow={true}
                  disabled={select.dues !== 0}
                >
                  <BtnIcon
                    disabled={Number(select.dues) < 1}
                    btnType={'a'}
                    className={'ml-1'}
                    icon={'check'}
                    onClick={() => {
                      lokCollapseToggle(false);

                      //llevalo una pantalla hacia atras
                      globalActions.setDirection(true);
                      let trg = [...globalState.migas].slice(-2)[0];
                      let m = [...globalState.migas];
                      m.pop();
                      m.pop();
                      globalActions.setMiga(m);
                      globalActions.setStep(trg);

                      //Confirma que se ha suscrito la tarjeta
                      globalActions.setErrorAlert({
                        message: [
                          <span>
                            El medio de pago{' '}
                            <img
                              src={`/img/cards/${franchise}.png`}
                              height={'25px'}
                              width={'auto'}
                              className={'mr-2'}
                              alt=""
                            />{' '}
                            <b>{number}</b> ha sido agregado a esta
                            suscripción con éxito
                          </span>,
                        ],
                        title: '¡Medio de pago suscrito con éxito!',
                        icon: 'check',
                      });
                      globalActions.setIsUpdate(true)
                      const {
                        transaction: { epaycoSubscription },
                      } = globalState;
                      const {
                        dataSubscription: { clienteInfo },
                      } = globalState;

                      oneclickDomiciliation({
                        variables: {
                          idCustomer: clienteInfo.customer_mongo_id,
                          token: isDebit?token: token?token:id,
                          idDomiciliation: parseInt(
                            epaycoSubscription.suscripcionId
                          ),
                          dues: parseInt(select.dues),
                          isDebit:isDebit?true:false
                        },
                      });
                    }}
                  />
                </Tooltip>
              </Flex>
            )
          ) : selected ? (
            <span className={'seleccionada'}>
              {' '}
              Suscrito <i className={'material-icons'}>lock</i>
            </span>
          ) : (
            <button
              className={'accion'}
              onClick={() => {
                actualizaSelect({ showDues: true });
              }}
              disabled={!collapse || globalState.lockDrag}
            >
              <b>Seleccionar</b>
            </button>
          ))}
      </Flex>
    </Flex>
  );
};

export default React.memo(OneClick);
