import React from 'react';
import {Col} from "react-bootstrap";
import Check from "./../input/checkbox";

/**
 * Styles
 */
import { Container } from './styles';
import Flex from '../Flex';
import {ShowError} from '../input/helpers';

function Termscond({onChange,checked,error}) {
  return (
    <Container className="TermsCond">
        <Flex>
         <Col xs={"1"} className={"px-0 "}>
           <Check
             error={error}
             onChange={()=> {
               onChange(!checked)
             }}
             checked={checked}
           />
         </Col>
         <Col xs={"11"} className={"pl-2"}>
           <p className={"mb-0 text-left"}>Al hacer clic en continuar está aceptando nuestros
             <a href="https://epayco.com/terminos-condiciones-suscripciones" target={"_blank"}> Términos y condiciones</a> y
             <a href="https://epayco.com/tratamiento-de-datos" target={"_blank"}> Políticas de privacidad</a>
           </p>
           <ShowError error={error}/>
         </Col>
        </Flex>
    </Container>
  );
}


export default Termscond;