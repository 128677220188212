import React from 'react';
import PropTypes from 'prop-types';

/**
 * Global state
 */
import useGlobal from '../../config/global';
import Metodopagoitem from '../MetodoPagoItem';
import { config,animated, useTransition } from 'react-spring';
import { TitleSmall } from '../UI';


function Listmethods({methods,disabled}) {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal();


  const transitions = useTransition(methods, item => item.slug, {
    trail:125,
    from:{ transform: 'translate3d(50px,0,0)', opacity:0 ,background:"#e4e4e4" },
    enter:{ transform: 'translate3d(0,0,0)', opacity:1 ,background:"transparent" },
    leave:{ transform: 'translate3d(50px,0,0)', opacity:0 ,background:"#e4e4e4" },
    config:config.stiff
  });

  return (
    <div>
      <TitleSmall text={"Medios de pago"}/>
      {transitions.map(({
        item,
        props,
        key,
      })=>
        <animated.div style={props} key={key}>
          { item.active && <Metodopagoitem
            disabled={disabled}
            key={item}
            img={`/img/cards/${item.slug}.png`}
            name={item.name}
            onClick={() => {
              globalActions.setStep(item.step);
            }}
            material={item.material}
          />}
        </animated.div>
      )}
    </div>
  );
}
Listmethods.propTypes = {
  methods: PropTypes.array.isRequired,
};

Listmethods.defaultProps = {
  methods: [],
};

export default React.memo(Listmethods);
